export default {
  App: {},
  DeviceForm: {
    cancel: 'cancel',
    Aequipments: 'add equipments',
    type: 'device type',
    error: 'error',
    tips: 'Please enter the device number',
    Detailed: 'Detailed Location',
    Clear: 'Clear coordinates',
    number: 'equipment number',
    tips8: 'Please enter appKey',
    tips9: 'Please enter appSecret',
    Intelligent: 'Intelligent device management',
    JifeiS: 'Secret',
    AppKey: 'AppKey',
    JifeiP: 'Passward',
    Camera: 'Camera. - fluorite',
    Asuccessfully: 'Device added successfully',
    coordinate: 'device coordinate',
    JifeiC: 'ClientId',
    tips4: 'Please enter the device coordinates',
    JifeiA: 'account',
    tips5: 'Please enter the device location',
    tips6: 'Please enter the device location details',
    tips7: 'Please enter the device serial number',
    AppSecret: 'AppSecret',
    tips1: 'The device number already exists',
    tips2: 'Please enter the device name',
    establish: 'establish',
    tips3: 'Select the device type',
    confirm: 'confirm',
    tips15: 'The selected location has been cleared',
    tips16: 'Please select a latitude and longitude',
    tips13: 'Please enter clientId',
    Coordinate: 'Coordinate setting',
    serial: 'device serial number',
    tips14: 'please enter',
    tips11: 'Please enter your password',
    tips12: 'Please enter secret',
    succeed: 'succeed',
    name: 'device name',
    tips10: 'Please enter the account number.',
    Camera2: 'Camera. - Jifei',
    information: 'information',
    JfeiEsn: 'Equipment serial number',
    position: 'coordinate position',
    device: 'device management',
    return: 'return',
    Location: 'Location of equipment'
  },
  fertilizeRecordApp: {
    Operator: 'operator',
    classify: 'classify',
    Tostage: 'Topdressing at seedling stage',
    Aphotos: 'Agricultural photos',
    Sfertilizer: 'Select fertilizer',
    Toperiod: 'Top dressing in paging period',
    Nfertilizer: 'Name of fertilizer',
    remark: 'remark',
    delete: 'delete',
    tips: 'Please select the operation method',
    dollar: 'dollar',
    jostage: 'Topdressing at jointing stage',
    tips8: 'Please fill in the fee',
    composition: 'composition',
    tips9: 'Please enter the fee',
    reap: 'reap',
    define: 'define',
    Aufertilizer: 'Add and use fertilizer',
    kilogram: 'kilogram',
    Fmethod: 'Fertilization method',
    area: 'area',
    cost: 'cost',
    Rtime: 'Record time',
    tips4: 'Please enter the area',
    tips5: 'Manufacturer/supplier/channel',
    tips6: 'Please select fertilizer',
    tips7: 'Please enter fertilizer quantity',
    tips1: 'Please select fertilization method',
    tips2: 'Please select the recording time',
    tips3: 'Please fill in the area',
    Pharvest: 'Post harvest management',
    artificial: 'artificial',
    tips11: 'Please fill in remarks',
    acre: 'acre',
    tips10: 'Please select a time',
    Omode: 'operation mode',
    machinery: 'machinery'
  },
  dataDictionary: {
    Ee: 'Entry editing',
    cancel: 'cancel',
    New: 'New dictionary',
    code: 'code',
    remark: 'remark',
    skip: 'Skip to page',
    type: 'Data dictionary type',
    delete: 'delete',
    tips: "'",
    compile: 'edit',
    skip2: 'Press Enter to jump',
    Dd: 'Dictionary deletion',
    De: 'Dictionary editor',
    Added: 'Added',
    successfully: 'successfully delete',
    redact: 'redact',
    lose: 'lose',
    tips4: '？',
    tips5: 'The delete type cannot be deleted because it exists in a dictionary',
    tips1: 'total of records',
    Nodata: 'No data',
    tips2: 'This node has child nodes and cannot be deleted！',
    tips3: 'Are you sure you want to delete it',
    wrong: 'Selection error',
    affirm: 'affirm',
    entry: 'New entry',
    system: 'system management',
    dictionary: 'data dictionary',
    succeed: 'succeed',
    name: 'name',
    attention: 'attention',
    choice: 'Please select',
    operation: 'operation',
    Ed: 'Entry deletion'
  },
  Project2: {
    cancel: 'cancel',
    examine: 'examine',
    ptype: 'project type',
    skip: 'Skip to page',
    error: 'error',
    delete: 'delete',
    tips: 'Please enter a project name',
    compile: 'edit',
    tips8: 'Are you sure you want to delete the selected item',
    tips9: 'The selected item was successfully deleted',
    gleader: 'group leader',
    Nfound: 'No data found',
    coordinate: 'coordinate',
    plocation: 'project location',
    pname: 'project name',
    proposes: 'expert proposes',
    Belonging: 'Belonging user',
    tips4: 'Press Enter to jump',
    tips5: 'ongoing project',
    tips6: 'Please select the items to be archived',
    tips7: 'Select the items you want to delete',
    tips1: 'Please enter the group name',
    tips2: "'",
    tips3: 'pages in total',
    target: 'target',
    affirm: 'affirm',
    Planing: 'Planting Program',
    inumber: 'item number',
    pmember: 'project member',
    succeed: 'succeed',
    pigeonhole: 'pigeonhole',
    operation: 'operation',
    pm: 'project management'
  },
  addTreeNode: {
    cancel: 'cancel',
    classify: 'classify',
    compile: 'edit',
    serial: 'serial number',
    name: 'name',
    save: 'save',
    language: 'language',
    tips1: 'Please enter the number',
    tips: 'Please enter name'
  },
  Project1: {
    leader: 'group leader',
    coordinate: 'coordinate',
    plocation: 'project location',
    examine: 'examine',
    pname: 'project name',
    ptype: 'project type',
    skip: 'Skip to page',
    pnumber: 'project number',
    quarterly: 'quarterly',
    tips1: "'",
    tips: 'Please enter a project name',
    tips2: 'in total',
    target: 'target',
    Archived: 'Archived items',
    pop: 'Project of planting',
    pmember: 'project member',
    else: 'else',
    Archiving: 'Archiving type',
    yearly: 'yearly',
    operation: 'operation',
    pm: 'Project management',
    Nfound: 'No data found',
    Press: 'Press Enter to jump'
  },
  addSuggest: {
    cancel: 'cancel',
    Asuccessfully: 'successfully added',
    submit: 'submit',
    suggestion: 'New suggestion',
    enter: 'please enter',
    tips: 'An empty proposal cannot be submitted'
  },
  WaFRecordApp: {
    Operator: 'operator',
    wconsumption: 'water consumption',
    classify: 'classify',
    Aphotos: 'Agricultural photos',
    Sfertilizer: 'Select fertilizer',
    Nfertilizer: 'Name of fertilizer',
    remark: 'remark',
    delete: 'delete',
    tips: 'Please select the operation method',
    dollar: 'dollar',
    ton: 'ton',
    tips8: 'Please enter the fee',
    composition: 'composition',
    tips9: 'Please fill in remarks',
    define: 'define',
    Manufacturer: 'Manufacturer/supplier/channel',
    kilogram: 'kilogram',
    area: 'area',
    cost: 'cost',
    Rtime: 'Record time',
    tips4: 'Please enter water consumption',
    tips5: 'Please select fertilizer',
    tips6: 'Please enter fertilizer quantity',
    manufactor: 'manufactor',
    Fmanure: 'Farmyard manure',
    tips7: 'Please fill in the fee',
    tips1: 'Please fill in the area',
    tips2: 'Please enter the area',
    tips3: 'Please fill in the water consumption',
    artificial: 'artificial',
    acre: 'acre',
    tips10: 'Please select a time',
    Omode: 'operation mode',
    machinery: 'machinery'
  },
  enclosure: {
    counting: 'Start counting',
    area: 'area of region',
    average: 'annual average temperature',
    rain: 'Annual number of days of rain',
    foggy: 'Annual number of foggy days',
    rainfall: 'Annual rainfall',
    wait: 'wait a moment',
    tornado: 'Annual tornado days',
    save: 'save',
    Senclosure: 'Start enclosure',
    Click: 'Click "Start enclosure" to open the enclosure operation (double click to end the enclosure)',
    hail: 'Annual hail days',
    square: 'square meter',
    search: 'Enter the name and then search',
    Atemperature: 'Annual average minimum temperature',
    snow: 'Annual snow days',
    weather: 'Load the weather data',
    acre: 'acre',
    maximum: 'Annual mean maximum temperature',
    thunderstorm: 'Annual thunderstorm days'
  },
  recordDetailsApp: {
    Lelen: 'Leaf length',
    cuttage: 'cuttage',
    Nsosp: 'Node spacing of single plant',
    Gpr: 'Ground preparation record',
    Opmode: 'operation mode',
    Gp: 'Grassland preparation',
    MeasurNo: 'Measurement No',
    Chp: 'Channel planning',
    Tass: 'Topdressing at seedling stage',
    Gr: 'Growth record',
    Cp: 'Cuttage planting',
    output: 'output',
    number: 'number',
    Trmode: 'Truncation mode',
    Noyl: 'Number of yellow leaves',
    Wafir: 'Water and fertilizer integration record',
    Nonpp: 'Number of nodes per plant',
    Pc: 'Prepare content',
    Sdpp: 'Stem diameter per plant',
    Plmode: 'Planting mode',
    reap: 'reap',
    kilogram: 'kilogram',
    Pm: 'Plant measurement',
    Lpreparation: 'Land preparation',
    Tr: 'Tillering record',
    area: 'area',
    Fsp: 'Flat strip planting',
    Pr: 'Planting records',
    Nogl: 'Number of green leaves',
    ftgws: 'fill the gaps with seedlings',
    Rod: 'Records of disinfestation',
    Tof: 'Type of farming',
    Hr: 'Harvest record',
    lowest: 'lowest',
    Tajs: 'Topdressing at jointing stage',
    highest: 'highest',
    Ec: 'Expert comments',
    machinery: 'machinery',
    Operator: 'operator',
    Ser: 'Seedling emergence record',
    average: 'average',
    altitude: 'altitude',
    Abf: 'Apply base fertilizer',
    Wepp: 'Weight per plant',
    Lewid: 'Leaf width',
    Ir: 'Irrigation record',
    dollar: 'dollar',
    Ar: 'Agricultural records',
    wconsump: 'water consumption',
    ton: 'ton',
    Phm: 'Post harvest management',
    Srat: 'Seedling raising and transplanting',
    Tillage: 'Tillage(rotary tillage)',
    root: 'root',
    Lirrigation: 'Late irrigation',
    Emrate: 'Emergence rate',
    Inph: 'Individual plant height',
    Rs: 'Record selection',
    DDc: 'Digging drainage channels',
    Rt: 'Record time',
    cost: 'cost',
    quantity: 'quantity',
    Rw: 'Remove weeds',
    Fm: 'Fertilization method',
    Jr: 'Jointing record',
    Cweight: 'Clump weight',
    Fp: 'Farming photos',
    Fr: 'Fertilization record',
    irmethod: 'irrigation method',
    Sc: 'Send comments',
    pa: 'planting area',
    Ssp: 'Slope strip planting',
    artificial: 'artificial',
    Firrigation: 'First irrigation',
    Sf: 'Select fertilizer',
    PC: 'Please comment',
    Pmr: 'Production measurement record',
    Si: 'Select insecticide',
    acre: 'acre',
    Tnumber: 'Tiller number',
    Tils: 'Topdressing in leafing stage',
    remarks: 'remarks',
    Stplant: 'Strip planting'
  },
  addLanguageContent: {
    cancel: 'cancel',
    New: 'New entry',
    terms: 'Edit terms',
    name: 'Entry name',
    save: 'save',
    attention: 'attention',
    tips1: 'Please enter the entry content',
    tips: 'Please enter the entry name',
    content: 'Entry content'
  },
  weather: {
    average: 'average temperature',
    rain: 'Total days of rain',
    rainfall: 'total rainfall',
    Load: 'Load the weather data',
    wait: 'wait a moment',
    Monthly: 'Monthly historical weather record',
    visibility: 'Average visibility',
    Maximum: 'Maximum sustainable wind speed',
    medial: 'medial humidity',
    failure: 'Data acquisition failure',
    temperature: 'maximum temperature',
    minimum: 'minimum temperature',
    day: 'day'
  },
  Compare: {
    pt: "'",
    project: 'project',
    skip: 'Skip to page',
    DT: 'device type',
    Ndf: 'No data found',
    Csc: 'Comparison of the same chart',
    Dsd: 'Devices of different types are not comparable. Select another device',
    tiao: 'in total',
    press: 'Press Enter to jump',
    Da: 'data analysis',
    coordinate: 'coordinate',
    Pu: 'pack up',
    Psi: 'Please select item',
    query: 'query',
    EN: 'equipment number',
    Sc: 'Subgraph comparison',
    Dt: 'device type',
    Pop: 'Project of planting',
    Se: 'select error',
    PC: 'please choose',
    reset: 'reset',
    devicename: 'devicename',
    location: 'location',
    page: '‘',
    TQ: 'time quantum',
    PN: 'project name',
    unfold: 'unfold'
  },
  distribuirExport: {
    planning: 'planting planning',
    allocation: 'allocation',
    select: 'select expert',
    specialist: 'Distribution specialist',
    Distribution: 'Distribution success',
    name: 'project name',
    list: 'list',
    tips: 'Please select an expert'
  },
  ProjectForm2: {
    cancel: 'cancel',
    oinformation: 'other information',
    Mfungus: 'Material of fungus',
    Address: 'Detailed Address',
    tips: 'Please select the project number',
    Pvalue: 'PH value',
    desertification: 'desertification',
    Eamount: 'Please enter the correct or complete amount',
    NMaterials: 'New Building Materials',
    Wetland: 'Wetland (Meadow/Marsh)',
    restoration: 'Mine restoration',
    biofuel: 'biofuel',
    nrainfall: 'natural rainfall',
    Land: 'Land preparation condition',
    fodder: 'fodder',
    Pop: 'Project of planting',
    Ecological: 'Ecological governance objectives',
    eitem: 'edit item',
    breeding: 'breeding',
    Esoil: 'Please enter soil properties',
    save: 'save',
    Ppi: 'Planning plot information',
    Eland: 'Please enter the land preparation conditions',
    dollar: 'dollar/acre',
    remediation: 'soil remediation',
    ton: 'ton/acre',
    flat: 'flat',
    terrace: 'terrace',
    tips4: 'Please enter the project coordinates',
    tips5:
      'Tip: The name of province/autonomous region, city, district/county should use abbreviation, such as Inner Mongolia Autonomous Region abbreviation Inner Mongolia, Alxa League abbreviation Alxa',
    tips6: 'Please enter the project location',
    tips7: 'Please enter the location details',
    tips1: 'Please enter a project name',
    Ugrass: 'Use for growing fungi and grass',
    tips2: 'Please select the project type',
    confirm: 'confirm',
    fixation: 'wind prevention and sand fixation',
    bsoil: 'black soil',
    return: 'return',
    bed: 'Pig feed - Fermentation bed',
    Psuccessfully: 'Project added successfully',
    country: 'country',
    penter: 'please enter',
    Fgovernance: 'For ecological governance',
    pobjective: 'project objective',
    irrigation: 'irrigation conditions',
    tips8: 'Please enter the project objective',
    krasnozem: 'krasnozem',
    Vitems: 'View items',
    tips9: 'Please input the yield per acre',
    Econditions: 'Please enter irrigation conditions',
    Ssoil: 'Sandy soil',
    Rmature: 'Reuse the fungus when it is mature',
    sirrigation: 'sprinkling irrigation',
    create: 'create',
    fmaterial: 'fibrous material',
    Less: 'Less than 25 degrees of slope',
    longitude: 'Please select a latitude and longitude',
    area: 'cultivated area',
    coordinate: 'Project coordinate',
    town: 'town',
    pname: 'project name',
    amend: 'amend',
    pnumber: 'project number',
    Province: 'Province/continent/autonomous region',
    dike: 'Protect the slope and fix the dike',
    district: 'district and county',
    effect: 'Experimental effect',
    tips10: 'Please enter planting area',
    position: 'coordinate position',
    pcycle: 'project cycle',
    tirrigation: 'trickle irrigation',
    Pmanagement: 'Production field management',
    cleared: 'The selected location has been cleared',
    no: 'no',
    Iirrigation: 'Immersion irrigation',
    production: 'production',
    scientific: 'scientific research',
    Premarks: 'Please enter remarks',
    Ccoordinates: 'Clear coordinates',
    ptype: 'project type',
    remark: 'remark',
    error: 'error',
    Processing: 'Processing target of fungus after harvest',
    Sproperty: 'soil property',
    Electrical: 'Electrical conductivity (EC)',
    sak: 'saline-alkali soil',
    Smushroom: 'Seed mushroom',
    Csetting: 'Coordinate setting',
    amount: 'Please enter the amount',
    pyield: 'per mu yield',
    wals: 'water and soil loss',
    yes: 'yes',
    saltness: 'saltness',
    message: 'message',
    Roverwintering: 'Root overwintering',
    emonitoring: 'environmental monitoring',
    stony: 'stony desertification',
    succeed: 'succeed',
    oproject: 'ongoing project',
    acre: 'acre',
    Duplicate: 'Duplicate item number',
    location: 'project location',
    PM: 'PM(project management)'
  },
  growRecord: {
    cancel: 'cancel',
    average: 'average',
    LineSpacing: 'LineSpacing',
    roe: 'rate of emergence',
    section: 'section',
    auxograph: 'auxograph',
    measurement: 'Plant measurement',
    tiller: 'tiller number',
    tips: 'Are you sure you want to delete this one',
    Plant: 'Plant spacing',
    diameter: 'Individual stem diameter',
    Number: 'Number of nodes per plant',
    leaves: 'Number of trees with green leaves',
    record: 'record',
    Emergence: 'Emergence record',
    root: 'root',
    Height: 'Height per plant',
    leaves2: 'Number of trees with yellow leaves',
    height: 'height',
    Tillering: 'Tillering record',
    Jointing: 'Jointing record',
    Nr: 'No record',
    individual: 'Pitch of individual plant',
    Recommended: 'Recommended sensor',
    Bundle: 'Bundle weight',
    proposes: 'expert proposes',
    gram: 'gram',
    Agricultural: 'Agricultural record',
    Lw: 'leaf width',
    leaf: 'length of leaf',
    lowest: 'lowest',
    Weight: 'Weight per plant',
    Optimum: 'Optimum planting time',
    confirm: 'confirm',
    Survey: 'Survey number',
    depth: 'Groove depth(hole depth)',
    month: 'month',
    piece: 'piece',
    highest: 'the highest',
    Dtd: 'Delete this data',
    width: 'Groove width(hole diameter)',
    Dsuccessfully: 'successfully delete',
    commentary: 'expert commentary'
  },
  addComment: {
    cancel: 'cancel',
    Asuccessfully: 'successfully added',
    submit: 'submit',
    enter: 'please enter',
    Acomment: 'Add comment',
    tips: 'You cannot submit an empty comment'
  },
  plan: {
    cancel: 'cancel',
    Overall: 'Overall condition',
    Modification: 'Modification expert',
    Ndata: 'no data',
    Fill: 'Fill in suggestions',
    import: 'import project',
    advice: 'Expert advice',
    Imported: 'Imported',
    skip: 'Skip to page',
    delete: 'delete',
    tips: "'",
    irrigation: 'irrigation conditions',
    compile: 'edit',
    Create: 'Create a new plan',
    land: 'land attribute',
    phase: 'project phase',
    all: 'all',
    area: 'Project area',
    completion: 'Expert completion recommendation',
    tips4: '?',
    View: 'View the project',
    tips1: 'total of records',
    tips2: 'Press Enter to jump',
    recommended: 'Not recommended',
    tips3: 'Are you sure you want to delete',
    affirm: 'affirm',
    planning: 'planting planning',
    Sdelete: 'successfully delete',
    Iproject: 'Imported project',
    Distribution: 'Distribution specialist',
    creating: 'creating',
    assigned: 'Specialist to be assigned',
    suggestions: 'View suggestions',
    under: 'under planning',
    Editorial: 'Editorial suggestion'
  },
  ProjectForm1: {
    bed: 'Pig feed - Fermentation bed',
    oinformation: 'other information',
    country: 'country',
    tips: 'Please select the project type',
    pobjective: 'project objective',
    desertification: 'desertification',
    serosion: 'soil erosion',
    sremediation: 'soil remediation',
    krasnozem: 'krasnozem',
    NMaterials: 'New Building Materials',
    Ssoil: 'Sandy soil',
    else: 'else',
    sirrigation: 'sprinkling irrigation',
    fmaterial: 'fibrous material',
    Pcoordinate: 'Project coordinate',
    Less: 'Less than 25 degrees of slope',
    Wetland: 'Wetland (Meadow/Marsh)',
    restoration: 'Mine restoration',
    ecological: 'For ecological governance',
    Root: 'Root overwintering',
    biofuel: 'biofuel',
    pname: 'project name',
    pnumber: 'project number',
    monitoring: 'environmental monitoring',
    fodder: 'fodder',
    iconditions: 'irrigation conditions',
    POP: 'Project of planting',
    Atype: 'Archiving type',
    effect: 'experiment effect',
    Lcondition: 'Land preparation condition',
    Rhizobiaceae: 'Rhizobiaceae',
    pcycle: 'project cycle',
    tirrigation: 'trickle irrigation',
    Pmanagement: 'Production field management',
    wind: 'wind prevention and sand fixation',
    breeding: 'breeding',
    no: 'no',
    sproperty: 'soil property',
    Iirrigation: 'Immersion irrigation',
    production: 'production',
    scientific: 'scientific research',
    range: 'PH range',
    Saline: 'Saline',
    ptype: 'project type',
    ylut: 'ylut',
    remark: 'remark',
    quarterly: 'quarterly',
    Detailed: 'Detailed Location',
    Slope: 'Slope protection and embankment reinforcement',
    dollar: 'dollar/acre',
    carea: 'cultivated area',
    ' yearly': ' yearly',
    flat: 'flat',
    terrace: 'terrace',
    Electrical: 'Electrical conductivity (EC)',
    Smushroom: 'Seed mushroom',
    rainfall: 'natural rainfall',
    plocation: 'project location',
    yes: 'yes',
    saltness: 'saltness',
    tips4: 'Use for growing fungi and grass',
    tips1: 'Please enter the amount',
    tips2: 'Reuse the fungus when it is mature',
    tips3: 'Processing target of fungus after harvest',
    stony: 'stony desertification',
    Aitems: 'Archived items',
    acre: 'acre',
    objectives: 'Ecological governance objectives',
    information: 'Planning plot information',
    bsoil: 'black soil',
    PM: 'project management',
    return: 'return'
  },
  TabHomeApp: {
    Eguidance: 'Expert guidance',
    Np: 'No project',
    wttsfcp: 'Welcome to the smart fungus cloud platform',
    Lari: 'Latest record items',
    Noi: 'Number of items',
    Prdeta: 'Project details',
    Nnm: 'No new message',
    Archived: 'Archived',
    Mitems: 'More items',
    Ar: 'Agricultural records',
    Nrecord: 'No record',
    Loading: 'Loading',
    Nfr: 'No farming records',
    Agrecords: 'Agricultural records',
    Nnews: 'New news'
  },
  assRecord: {
    area: 'area',
    cancel: 'cancel',
    LineSpacing: 'LineSpacing',
    proposes: 'expert proposes',
    Agricultural: 'Agricultural record',
    NoRecord: 'No record',
    delete: 'Delete this data',
    measurement: 'Production measurement record',
    tips: 'Are you sure you want to delete this "measurement record"',
    Optimum: 'Optimum planting time',
    confirm: 'confirm',
    ton: 'ton',
    spacing: 'Plant spacing',
    depth: 'Groove depth (hole depth)',
    month: 'month',
    record: 'record',
    yield: 'yield',
    width: 'Groove width (hole diameter)',
    Dsuccessfully: 'successfully delete',
    acre: 'acre',
    sensor: 'Recommended sensor',
    stand: 'stand',
    commentary: 'expert commentary'
  },
  unnetworkRecordsApp: {
    cancel: 'cancel',
    Ss: 'Sending succeeded',
    Lelen: 'Leaf length',
    joint: 'joint',
    upload: 'upload',
    Nsosp: 'Node spacing of single plant',
    Gpr: 'Ground preparation record',
    Opmode: 'operation mode',
    MeasurNo: 'Measurement No',
    Chp: 'Channel planning',
    Cp: 'Cuttage planting',
    Plmeasure: 'Plant measurement',
    tips: 'The device is offline, please upload data in time after networking',
    Trmode: 'Truncation mode',
    Noyl: 'Number of yellow leaves',
    Wafir: 'Water and fertilizer integration record',
    Nonpp: 'Number of nodes per plant',
    Pc: 'Prepare content',
    price: 'price',
    Sdpp: 'Stem diameter per plant',
    Hrecord: 'Harvest record',
    Lpreparation: 'Land preparation',
    Fetype: 'Fertilizer type',
    area: 'area',
    Fsp: 'Flat strip planting',
    Nogl: 'Number of green leaves',
    Rod: 'Records of disinfestation',
    Plrecord: 'Planting records',
    lowest: 'lowest',
    Tirecord: 'Tillering record',
    highest: 'highest',
    Irecord: 'Irrigation record',
    Agrecords: 'Agricultural records',
    machinery: 'machinery',
    Ser: 'Seedling emergence record',
    average: 'average',
    Tcr: 'Total cache requests',
    Abf: 'Apply base fertilizer',
    Wepp: 'Weight per plant',
    Lewid: 'Leaf width',
    dollar: 'dollar',
    wconsump: 'water consumption',
    ton: 'ton',
    Loading: 'Loading',
    Srat: 'Seedling raising and transplanting',
    Tillage: 'Tillage(rotary tillage)',
    root: 'root',
    yield: 'yield',
    Lirrigation: 'Late irrigation',
    Emrate: 'Emergence rate',
    Inph: 'Individual plant height',
    DDc: 'Digging drainage channels',
    cost: 'cost',
    cf: 'chemical fertilizer',
    Rw: 'Remove weeds',
    Gsp: 'Grass seed preparation',
    Gss: 'Grass seed selection',
    Fm: 'Farmyard manure',
    gram: 'gram',
    tips4: 'Please enter the search item name',
    Arecord: 'All records',
    Gst: 'Grass seed truncation',
    Cweight: 'Clump weight',
    Fr: 'Fertilization record',
    tips1: 'Confirm to upload local records',
    irmethod: 'irrigation method',
    tips2: 'Please enter the project name',
    tips3: 'Network connected, confirm to upload local records',
    Odata: 'offline data',
    Ssp: 'Slope strip planting',
    artificial: 'artificial',
    Firrigation: 'First irrigation',
    Nrecord: 'No record',
    Pmr: 'Production measurement record',
    vernalization: 'vernalization',
    Grecord: 'Growth record',
    acre: 'acre',
    synchronization: 'synchronization',
    Tnumber: 'Tiller number'
  },
  TabsPageApp: {
    cancel: 'cancel',
    exit: 'exit',
    item: 'item',
    record: 'record',
    hp: 'home page',
    my: 'my',
    tips: 'Do you want to exit the Mycobacterium cloud platform?'
  },
  addOrUpdateFer: {
    cancel: 'cancel',
    Add: 'successfully added',
    Added: 'New fertilizer',
    save: 'save',
    type: ' fertilizer type',
    tips1: 'Please enter type',
    tips: 'Please enter name',
    tips2: 'Please enter manufacturer',
    manufacturer: 'Fertilizer manufacturer',
    tips3: 'Please enter the composition',
    modify: 'modify successfully',
    names: 'Fertilizer name',
    compile: 'Edit fertilizer',
    element: 'fertilizer element'
  },
  chatWindow: {
    Ii: 'item information',
    Ar: 'Agricultural record',
    read: 'read',
    specialist: 'specialist',
    unread: 'unread',
    EP: 'expert proposes',
    send: 'send',
    ML: ' Message Lists'
  },
  resetPwdApp: {
    Np: 'New password',
    Cnp: 'Confirm new password',
    Cop: 'Confirm Password',
    Ms: 'Modified successfully',
    tips4: 'The two passwords are inconsistent',
    CP: 'Change Password',
    Cp: 'Current password',
    tips1: 'Please enter a new password (6-16 characters)',
    tips: 'Please enter the current password',
    tips2: 'Please enter a new password',
    tips3: 'Password format error',
    Pe: 'Password error',
    define: 'define',
    Mf: 'Modification failed'
  },
  projectDetailsApp: {
    St: 'Sensor type',
    TW: 'Trench width (hole diameter)',
    Gpr: 'Ground preparation record',
    feg: 'For ecological governance',
    Cm: 'Cultivation methods',
    Lfe: 'Labor force estimate',
    Gr: 'Growth record',
    Td: 'Trench depth',
    Dsrs: 'Deployment sensor recommendations',
    number: 'number',
    Opt: 'Optimum planting time',
    Pd: 'Project details',
    conductivity: 'conductivity',
    Pl: 'Project location',
    Da: 'Detailed address',
    sflp: 'Suggestions for land preparation',
    Pr: 'Planting records',
    Pt: 'Project type',
    NOF: 'Name of fertilizer',
    Ratf: 'Reuse after the fungus is mature',
    Hr: 'Harvest record',
    manufactor: 'manufactor',
    Nos: 'Number of seedlings',
    month: 'month',
    specialist: 'specialist',
    Ic: 'Irrigation conditions',
    Ea: 'Expert advice',
    Lpc: 'Land preparation conditions',
    Ee: 'Experimental effect',
    Pcs: 'Project coordinates',
    no: 'No',
    Mn: 'Machine name',
    Mt: 'Machine type',
    Ar: 'All records',
    Rf: 'Recommended fertilizer',
    Loading: 'Loading',
    ypua: 'yield per unit area',
    SS: 'Select Service',
    Rs: 'Row spacing',
    sas: 'saline-alkali soil',
    yes: 'yes',
    Fm: 'Fertilizer manufacturer',
    saltness: 'saltness',
    Ego: 'Ecological governance objectives',
    Fs: 'Fungus species',
    Pea: 'Person/acre',
    Ft: 'Fertilizer type',
    target: 'target',
    pa: 'planting area',
    Bs: 'Bead spacing',
    pc: 'project cycle',
    Ptar: 'Processing target after receiving the mature fungus',
    Pmr: 'Production measurement record',
    PH: 'PH value',
    Sm: 'Sensor manufacturer',
    Sn: 'Sensor name',
    Sp: 'Soil properties',
    remarks: 'remarks'
  },
  showMap: {
    area: 'area of region',
    acre: 'acre',
    modification: 'modification'
  },
  talkApp: {
    Unread: 'Unread',
    Read: 'Read',
    cancel: 'cancel',
    Eg: 'Expert guidance',
    tap: 'take a picture',
    Loading: 'Loading',
    specialist: 'specialist',
    tbr: 'transmit by radio',
    SP: 'Select Picture',
    tips: 'Please select the upload method'
  },
  plantRecord: {
    cancel: 'cancel',
    Irrigation: 'Irrigation record',
    LineSpacing: 'LineSpacing',
    Deworming: 'Deworming record',
    manure: 'manure',
    cuttage: 'cuttage',
    NoRecord: 'No record',
    pattern: 'planting pattern',
    tips: 'Are you sure you want to delete this one',
    dollar: 'dollar',
    number: 'number',
    ' Spec': ' spec',
    irowaf: 'Integrated records of water and fertilizer',
    Plant: 'Plant spacing',
    ton: 'ton',
    kilo: 'kilo',
    irrigation: 'irrigation regime',
    record: 'record',
    Primary: 'Primary irrigation',
    ftws: 'fill the gaps with seedlings',
    stand: 'stand',
    area: 'area',
    Late: 'Late irrigation',
    cost: 'cost',
    Fertilization: 'Fertilization record',
    pesticide: 'pesticide',
    proposes: 'expert proposes',
    Agricultural: 'Agricultural record',
    water: 'water supply volume',
    seedling: 'seedling transplantation',
    tips3: 'Delete this data',
    Optimum: 'Optimum planting time',
    Strip: 'Strip planting',
    confirm: 'confirm',
    depth: 'Groove depth (hole depth)',
    month: 'month',
    width: 'Groove width (hole diameter)',
    acre: 'acre',
    Dsuccessfully: 'successfully delete',
    sensor: 'Recommended sensor',
    Planting: 'Planting record',
    commentary: 'expert commentary'
  },
  UserSetting: {
    cancel: 'cancel',
    mistake: 'mistake',
    successfully: 'The owning user is set successfully.',
    aname: 'address name',
    skip: 'Skip to page',
    team: 'team',
    speople: 'You did not select people',
    toble1: 'in total',
    Ejump: 'Press Enter to jump',
    tips: 'Please enter the user name',
    crew: 'crew',
    confirm: 'confirm',
    Sleader: 'Set as team leader',
    Pnumber: 'Phone number',
    Tmanager: 'Team manager',
    person: 'person',
    toble: "'",
    succeed: 'succeed',
    Nleader: 'no leader',
    gleader: 'group leader',
    cleader: 'Please choose a group leader',
    Nfound: 'No data found.'
  },
  jfPlayVideo: {
    failure: 'Video playback failure',
    Successful: 'Successful video playback'
  },
  plantRecordApp: {
    Operator: 'operator',
    Aphotos: 'Agricultural photos',
    quantity: 'quantity',
    cost: 'cost',
    cuttage: 'cuttage',
    ftgws: 'fill the gaps with seedlings',
    Rtime: 'Record time',
    tips4: 'Please enter the quantity',
    Pmode: 'Planting mode',
    tips5: 'Please fill in the fee',
    tips6: 'Please enter the fee',
    remark: 'remark',
    tips7: 'Please fill in remarks',
    tips1: 'Please select the planting method',
    tips: 'Please select the operation method',
    tips2: 'Please select the recording time',
    tips3: 'Please fill in the quantity',
    Strip: 'Strip planting',
    artificial: 'artificial',
    tips8: 'Please select a time',
    Srat: 'Seedling raising and transplanting',
    define: 'define',
    Omode: 'operation mode',
    machinery: 'machinery'
  },
  deworming: {
    cancel: 'cancel',
    Asuccess: 'Added success',
    submit: 'submit',
    irrigate: 'irrigate',
    mechanical: 'mechanical engineering',
    insecticide: 'Selective insecticide',
    remark: 'remark',
    delete: 'delete',
    tips: 'Please select a job mode',
    operator: 'operator',
    dollar: 'dollar',
    view: 'view data',
    kilo: 'kilo',
    farming: 'Type of farming',
    operating: 'operating type',
    write: 'write time',
    nonuse: 'nonuse',
    area: 'area',
    grow: 'grow',
    cost: 'cost',
    fertilization: 'fertilization',
    manufacturers: 'manufacturers',
    tips4: 'Please select insecticide',
    tips5: 'Please enter the cost',
    tips1: 'Please select the entry time',
    tips2: 'Please enter area',
    tips3: 'Please enter the number in kilograms',
    Project: 'Project of planting',
    artificial: 'artificial',
    photograph: 'Farm photograph',
    deinsectization: 'deinsectization',
    guidance: 'Agricultural records and guidance',
    acre: 'acre',
    ioaf: 'integration of water and fertilizer',
    Ainsecticide: 'Additive insecticide',
    return: 'return',
    Planting: 'Planting record'
  },
  plant: {
    cancel: 'cancel',
    Asuccess: 'Added success',
    submit: 'submit',
    cuttage: 'cuttage',
    irrigate: 'irrigate',
    mechanical: 'mechanical engineering',
    project: 'Planting project',
    ftgs: 'fill the gaps with seedlings',
    remark: 'remark',
    tips: 'Please select a job mode',
    operator: 'operator',
    dollar: 'dollar',
    view: 'view data',
    farming: 'Type of farming',
    operating: 'operating type',
    write: 'write time',
    grow: 'grow',
    quantity: 'quantity',
    cost: 'cost',
    fertilization: 'fertilization',
    tips4: 'Please enter the cost',
    drill: 'drill culture',
    tips1: 'Please choose a planting method',
    seedling: 'seedling transplantation',
    tips2: 'Please select a recording time',
    tips3: 'Please enter quantity',
    artificial: 'artificial',
    photograph: 'Farm photograph',
    deinsectization: 'deinsectization',
    guidance: 'Agricultural records and guidance',
    planting: 'planting pattern',
    ioaf: 'integration of water and fertilizer',
    Spec: 'spec',
    return: 'return',
    Planting: 'Planting record'
  },
  integration: {
    cancel: 'cancel',
    Asuccess: 'Added success',
    submit: 'submit',
    farmyard: 'farmyard manure',
    irrigate: 'irrigate',
    mechanical: 'mechanical engineering',
    remark: 'remark',
    delete: 'delete',
    tips: 'Please select a job mode',
    operator: 'operator',
    dollar: 'dollar',
    ton: 'ton',
    view: 'view data',
    kilo: 'kilo',
    farming: 'Type of farming',
    operating: 'operating type',
    write: 'write time',
    area: 'area',
    grow: 'grow',
    cost: 'cost',
    fertilization: 'fertilization',
    manufacturers: 'manufacturers',
    tips4: 'Please enter the number in kilograms',
    tips5: 'Please enter the cost',
    tips1: 'Please select the entry time',
    tips2: 'Please enter area',
    fertilizer: 'Select fertilizer',
    tips3: 'Please enter the water consumption',
    volume: 'water supply volume',
    Project: 'Project of planting',
    artificial: 'artificial',
    photograph: 'Farm photograph',
    deinsectization: 'deinsectization',
    guidance: 'Agricultural records and guidance',
    acre: 'acre',
    ioaf: 'integration of water and fertilizer',
    return: 'return',
    Planting: 'Planting record',
    Afertilizer: 'Added fertilizer'
  },
  Split: {
    average: 'average value',
    number: 'number',
    total: 'total',
    download: 'download',
    Pc: 'please choose',
    Nd: 'Node display',
    Lc: 'Loading chart...',
    parameter: 'parameter',
    Tq: 'time quantum',
    Da: 'data analysis',
    all: 'all',
    cfc: 'channel for collection',
    Pt: 'pointer type',
    query: 'query',
    Dn: 'Display node',
    Pop: 'Project of planting',
    Sc: 'Subgraph comparison',
    Sd: 'Summation display',
    ND: 'no data',
    reset: 'reset',
    maximum: 'maximum value',
    time: 'time',
    Ie: 'input error',
    conditions: 'Please enter the conditions completely',
    minimum: 'minimum value',
    return: 'return'
  },
  addOrUpdateInsecticide: {
    Add: 'New pesticides',
    cancel: 'cancel',
    modify: 'modify successfully',
    compile: 'Edit pesticides',
    success: 'successfully added',
    name: 'Fertilizer name',
    save: 'save',
    tips1: 'Please enter manufacturer',
    tips: 'Please enter name',
    manufacturer: 'Fertilizer manufacturer'
  },
  landPrepareApp: {
    Operator: 'operator',
    Ddrainage: 'Digging drainage channels',
    cost: 'cost',
    Fphotos: 'Farming photos',
    tips4: 'Please fill in remarks',
    tips5: 'Please select a time',
    remark: 'remark',
    Operation: 'Operation mode',
    tips1: 'Please select the recording time',
    tips: 'Please select the operation method',
    tips2: 'Please fill in the fee',
    dollar: 'dollar',
    tips3: 'Please enter the fee',
    artificial: 'artificial',
    Cplanning: 'Channel planning',
    Tillage: 'Tillage (rotary tillage)',
    define: 'define',
    Pcontent: 'Prepare content',
    Rweeds: 'Remove weeds',
    write: 'write time',
    machinery: 'machinery'
  },
  addOrUpRole: {
    cancel: 'cancel',
    Amenu: 'Add allocation menu',
    save: 'save'
  },
  One: {
    Ac: 'Acquisition channel',
    Pt: 'pointer type',
    query: 'query',
    Mv: 'minimum value',
    Cr: 'Chart results',
    Pop: 'Project of planting',
    Pecc: 'Please enter the conditions completely',
    download: 'download',
    Pc: 'please choose',
    Av: 'average value',
    Coc: 'Comparison of the same chart',
    Lc: 'Loading chart...',
    parameter: 'parameter',
    reset: 'reset',
    maximum: 'maximum',
    time: 'time',
    Ie: 'input error',
    Tq: 'time quantum',
    Da: 'data analysis',
    return: 'return'
  },
  login: {
    welcomeLogin: 'Welcome to the smart fungus cloud platform, please log in!',
    summary: 'no access',
    determine: 'determine',
    year: 'year',
    pwdTips: 'Please input a password',
    Ulog: 'Unable to log in!',
    loginPlatform: 'Smart fungus cloud platform',
    login: 'login',
    warn: 'warn',
    forgotPassword: 'Forgot password',
    telTips: 'Please enter your mobile number',
    contactAdministrator:
      'The mobile number is not registered or the password is incorrect. Please contact the administrator~',
    usernameTips: 'Enter one user name',
    telWrong: 'Mobile number is empty or format error!',
    detail: 'The token is invalid or expired, so you cannot access this function temporarily. You need to log in again!'
  },
  addOrUpdateTreeNode: {
    cancel: 'cancel',
    successfully: 'Type added successfully!',
    submit: 'submit',
    father: 'father node',
    remark: 'remark',
    tips1: 'Please enter name',
    tips: 'Select the parent node',
    tips2: 'Please enter the number',
    editing: 'editing type',
    modify: 'modify successfully!',
    serial: 'serial number',
    succeed: 'succeed',
    name: 'name',
    Newtype: 'New type'
  },
  jointingRecordApp: {
    Fphotos: 'Farming photos',
    define: 'define',
    tips4: 'Please select a time',
    remark: 'remark',
    write: 'write time',
    tips1: 'Please fill in the height',
    tips: 'Please select the recording time',
    operator: 'operator',
    tips2: 'Please enter height',
    height: 'height',
    tips3: 'Please fill in remarks'
  },
  FarmingRecordApp: {
    Pmrecord: 'Production measurement record',
    cuttage: 'cuttage',
    Nsosp: 'Node spacing of single plant',
    tips: 'Please enter the project name',
    Trmode: 'Truncation mode',
    Noyl: 'Number of yellow leaves',
    Nonpp: 'Number of nodes per plant',
    Lwidth: 'Leaf width',
    price: 'price',
    Sdpp: 'Stem diameter per plant',
    Precords: 'Planting records',
    Plmode: 'Planting mode',
    Semrecord: 'Seedling emergence record',
    Digchan: 'Digging drainage channels',
    Stpl: 'Strip planting',
    Pmeasurement: 'Plant measurement',
    Farmanure: 'Farmyard manure',
    Hrecord: 'Harvest record',
    Lpreparation: 'Land preparation',
    Trees: 'trees',
    area: 'area',
    Iplh: 'Individual plant height',
    Fsp: 'Flat strip planting',
    Nogl: 'Number of green leaves',
    ftgws: 'fill the gaps with seedlings',
    Rod: 'Records of disinfestation',
    Wpplant: 'Weight per plant',
    Ecomment: 'Error in comment',
    lowest: 'lowest',
    Ecomments: 'Expert comments',
    Tirecord: 'Tillering record',
    highest: 'highest',
    Irecord: 'Irrigation record',
    Tinumber: 'Tiller number',
    Pcontent: 'Prepare content',
    Lefe: 'Leaf length',
    Rweeds: 'Remove weeds',
    MeNo: 'Measurement No',
    imethod: 'irrigation method',
    machinery: 'machinery',
    wafir: 'Water and fertilizer integration record',
    Abfertili: 'Apply base fertilizer',
    wconsumption: 'water consumption',
    average: 'average',
    altitude: 'altitude',
    gladd: 'Failed to get local add',
    Jorecord: 'Jointing record',
    Lerror: 'Local error',
    Cuttagep: 'Cuttage planting',
    Plrecords: 'Planting records',
    dollar: 'dollar',
    Arecords: 'All records',
    ton: 'ton',
    Srat: 'Seedling raising and transplanting',
    Tillage: 'Tillage (rotary tillage)',
    yield: 'yield',
    Lirrigation: 'Late irrigation',
    Emrate: 'Emergence rate',
    Grrecord: 'Growth record',
    Ferrecord: 'Fertilization record',
    cost: 'cost',
    quantity: 'quantity',
    Gsp: 'Grass seed preparation',
    Gss: 'Grass seed selection',
    Fertype: 'Fertilizer type',
    gram: 'gram',
    Gst: 'Grass seed truncation',
    Cweight: 'Clump weight',
    Odata: 'Offline data',
    Ssp: 'Slope strip planting',
    artificial: 'artificial',
    Firrigation: 'First irrigation',
    Nrecord: 'No record',
    Pmr: 'Production measurement record',
    Cplanning: 'Channel planning',
    vernalization: 'vernalization',
    Grecord: 'Ground preparation record',
    cfertilizer: 'chemical fertilizer',
    acre: 'acre',
    Harecord: 'Harvest record'
  },
  landMainApp: {
    Tfarming: 'Type of farming',
    Gpreparation: 'Grass seed preparation',
    Lpreparation: 'Land preparation',
    Afertilizer: 'Apply base fertilizer'
  },
  forgotPassword: {
    vctips: 'Please enter the verification code',
    resetsuccesstips: 'Reset password has been sent to your phone',
    sendvc: 'Send verification code',
    sendpwd: 'send password',
    teltips: 'Please enter your mobile number',
    wrongvc: 'Incorrect verification code',
    verificationteltips: 'The mobile number is empty or in wrong format',
    verificationvctips: 'The verification code was sent successfully. Please check',
    loginPlatform: 'Smart fungus cloud platform',
    login: 'I want to sign in',
    welcomelogin: 'Welcome to the smart fungus cloud platform, please log in!',
    tips: 'You may not be a system user, please contact the administrator!'
  },
  grass: {
    cancel: 'cancel',
    Asuccess: 'Added success',
    submit: 'submit',
    remark: 'remark',
    Grass: 'Grass seed preparation',
    accelerating: 'accelerating germination',
    content: 'content preparation',
    tips: 'Please select the preparation content',
    strips: 'The slope is planted in strips',
    operator: 'operator',
    preparation: 'Land preparation',
    Truncation: 'Truncation mode',
    dollar: 'dollar',
    Cutting: 'Cutting planting',
    view: 'view data',
    write: 'write time',
    cost: 'cost',
    proposes: 'expert proposes',
    tips1: 'Please select the truncation mode',
    Base: 'Base fertilizer application',
    seedling: 'seedling transplantation',
    tips2: 'Please select the entry time',
    Land: 'Land preparation record',
    tips3: 'Please enter the cost',
    Type: 'Type of farming',
    photograph: 'Farm photograph',
    selection: 'Grass seed selection',
    guidance: 'Agricultural records and guidance',
    cutting: 'Grass seed cutting',
    Flat: 'Flat strip planting',
    return: 'return',
    Planting: 'Planting project'
  },
  uploadImgApp: {
    cancel: 'cancel',
    tap: 'take a picture',
    SP: 'Select Picture',
    tips: 'Please select the upload method'
  },
  soilpreRecord: {
    cancel: 'cancel',
    LineSpacing: 'LineSpacing',
    manure: 'farm manure',
    NoRecord: 'No record',
    mechanical: 'mechanical engineering',
    Grass: 'Grass seed preparation',
    pregermination: 'pregermination',
    content: 'content preparation',
    strips: 'The slope is planted in strips',
    tips: 'Are you sure you want to delete this one',
    dollar: 'dollar',
    Truncation: 'Truncation mode',
    Cutting: 'Cutting planting',
    spacing: 'Plant spacing',
    kilo: 'kilo',
    price: 'price',
    record: 'record',
    weed: 'weed',
    operating: 'operating type',
    Fertilizer: 'Fertilizer type',
    cost: 'cost',
    Dig: 'Dig drainage channels',
    Recommended: 'Recommended fertilizer',
    proposes: 'expert proposes',
    Channel: 'Channel planning',
    Agricultural: 'Agricultural record',
    seedling: 'seedling transplantation',
    Base: 'Base fertilizer application',
    fertilizer: 'fertilizer',
    Land: 'Land preparation',
    Optimum: 'Optimum planting time',
    confirm: 'confirm',
    manure1: 'manure',
    Tilling: 'Tilling (rotary tilling)',
    artificial: 'artificial',
    depth: 'Groove depth (hole depth)',
    month: 'month',
    selection: 'Grass seed selection',
    Dtd: 'Delete this data',
    width: 'Groove width (hole diameter)',
    acre: 'acre',
    cutting: 'Grass seed cutting',
    Flat: 'Flat strip planting',
    Soil: 'Soil Preparation',
    Dsuccessfully: 'successfully delete',
    catty: 'catty',
    commentary: 'expert commentary'
  },
  recordInfo: {
    cuttage: 'cuttage',
    mechanical: 'mechanical engineering',
    Grass: 'Grass seed preparation',
    type: 'fertilizer type',
    Harvest: 'Harvest record',
    strips: 'The slope is planted in strips',
    Truncation: 'Truncation mode',
    Cutting: 'Cutting planting',
    ' Spec': ' Spec',
    number: 'Survey number',
    irrigation: 'irrigation regime',
    price: 'price',
    Primary: 'Primary irrigation',
    Production: 'Production measurement record',
    traffic: 'traffic planninng',
    height: 'height',
    area: 'area',
    weight: ' plant weight',
    water: 'water supply volume',
    Tilling: 'Tilling (rotary tilling)',
    selection: 'Grass seed selection',
    chemical: 'chemical fertilizer',
    Yellow: 'Yellow leaf number',
    highest: 'the highest',
    Individual: 'Individual stem diameter',
    Flat: 'Flat strip planting',
    Pitch: 'Pitch of individual plant',
    Irrigation: 'Irrigation record',
    average: 'average',
    Deworming: 'Deworming record',
    manure: 'farmyard manure',
    knob: 'knob',
    records: 'Integrated records of water and fertilizer',
    pattern: 'planting pattern',
    pregermination: 'pregermination',
    tiller: 'tiller number',
    dollar: 'dollar',
    ton: 'ton',
    Plant: 'Plant measurement',
    kilo: 'kilo',
    ' Soil': ' Soil Preparation',
    rate: 'emergence rate',
    yield: 'yield',
    Emergence: 'Emergence record',
    root: 'root',
    Height: 'Height per plant',
    operating: 'operating type',
    Tillering: 'Tillering record',
    Late: 'Late irrigation',
    cost: 'cost',
    Dig: 'Dig drainage channels',
    quantity: 'quantity',
    Jointing: 'Jointing record',
    green: ' number of green leaves',
    Fertilization: 'Fertilization record',
    pesticide: 'pesticide',
    Bundle: 'Bundle weight',
    gram: 'gram',
    length: 'length of leaf',
    ' drill': ' drill culture',
    gaps: 'fill the gaps with seedlings',
    seedling: 'seedling transplantation',
    Base: 'Base fertilizer application',
    fertilizer: 'fertilizer',
    artificial: 'artificial',
    nodes: 'Number of nodes per plant',
    Preparation: 'Preparation content',
    cutting: 'Grass seed cutting',
    acre: 'acre',
    width: 'leaf width',
    minimum: 'minimum',
    Planting: 'Planting record'
  },
  allRecRecord: {
    cuttage: 'cuttage',
    mechanical: 'mechanical engineering',
    Grass: 'Grass seed preparation',
    Harvest: 'Harvest record',
    strips: 'The slope is planted in strips',
    Truncation: 'Truncation mode',
    Cutting: 'Cutting planting',
    ' Spec': ' Spec',
    irrigation: 'irrigation regime',
    diameter: 'Individual stem diameter',
    price: 'price',
    Primary: 'Primary irrigation',
    Production: 'Production measurement record',
    Integrated: 'Integrated records of water and fertilizer',
    area: 'area',
    Channel: 'Channel planning',
    weight: ' plant weight',
    Entries: 'Entries',
    water: 'water consumption',
    Tilling: 'Tilling (rotary tilling)',
    Survey: 'Survey number',
    selection: 'Grass seed selection',
    chemical: 'chemical fertilizer',
    Yellow: 'Yellow leaf number',
    highest: 'the highest',
    Flat: 'Flat strip planting',
    Pitch: 'Pitch of individual plant',
    Irrigation: 'Irrigation record',
    altitude: 'altitude',
    average: 'average',
    Deworming: 'Deworming record',
    manure: 'manure',
    knob: 'knob',
    farmyard: 'farmyard manure',
    pattern: 'planting pattern',
    pregermination: 'pregermination',
    content: 'content preparation',
    tiller: 'tiller number',
    dollar: 'dollar',
    ton: 'ton',
    Plant: 'Plant measurement',
    kilo: 'kilo',
    Number: 'Number of nodes per plant',
    ' Soil': ' Soil Preparation',
    rate: 'rate of emergence',
    yield: 'yield',
    Emergence: 'Emergence record',
    root: 'root',
    weed: 'weed',
    Height: 'Height per plant',
    operating: 'operating type',
    Tillering: 'Tillering record',
    Late: 'Late irrigation',
    cost: 'cost',
    Dig: 'Dig drainage channels',
    quantity: 'quantity',
    Jointing: 'Jointing record',
    green: ' number of green leaves',
    Fertilization: 'Fertilization record',
    pesticide: 'pesticide',
    Bundle: 'Bundle weight',
    gram: 'gram',
    length: 'length of leaf',
    ' drill': ' drill culture',
    leaf: 'leaf width',
    gaps: 'fill the gaps with seedlings',
    Nodata: 'no data',
    seedling: 'seedling transplantation',
    Base: 'Base fertilizer application',
    Type: 'Type of fertilizer',
    artificial: 'artificial',
    cutting: 'Grass seed cutting',
    acre: 'acre',
    minimum: 'minimum',
    Planting: 'Planting record'
  },
  map: {
    Login: 'Log in to the system',
    platform: 'Smart fungus cloud platform'
  },
  addOrUpdateDictMain: {
    cancel: 'cancel',
    successfully: 'Type added successfully！',
    submit: 'submit',
    already: 'Number already exists',
    Merriam: 'Merriam Webster',
    remark: 'remark',
    EditDictionary: 'EditDictionary',
    tips1: 'Please enter the number',
    tips: 'Please enter name',
    number: 'Dictionary number',
    modify: 'modify successfully！',
    succeed: 'succeed',
    name: 'Dictionary name'
  },
  tilleringRecordApp: {
    average: 'average',
    Fphotos: 'Farming photos',
    tips4: 'Please fill in remarks',
    tips5: 'Please select a time',
    remark: 'remark',
    tips1: 'Please enter the maximum number of tillers',
    lowest: 'lowest',
    tips: 'Please select the recording time',
    operator: 'operator',
    tips2: 'Please enter the minimum number of tillers',
    tips3: 'Please enter the average number of tillers',
    highest: 'highest',
    root: 'root',
    define: 'define',
    Tnumber: 'Tiller number',
    write: 'write time'
  },
  project_show: {
    cancel: 'cancel',
    skip: 'Skip to page',
    Ejump: 'Press Enter to jump',
    sdelete: 'successfully delete',
    remove: 'remove',
    tips: 'You are sure you want to delete',
    compile: 'edit',
    toble: "'",
    record: 'total of records',
    headline: 'headline',
    elink: 'external link',
    Pp: 'Planting project',
    pname: 'project name',
    introduce: 'introduce',
    redact: 'redact',
    tips1: '"',
    picture: 'picture',
    tips2: '？',
    confirm: 'confirm',
    site: 'site',
    operate: 'operate',
    Dtd: 'Delete this data',
    aitem: 'add item',
    PM: 'PM(project management)',
    Display: 'Display project management'
  },
  emergence: {
    Tillering: 'Tillering record',
    cancel: 'cancel',
    Jointing: 'Jointing record',
    Asuccess: 'Added success',
    submit: 'submit',
    roe: 'rate of emergence',
    remark: 'remark',
    auxograph: 'auxograph',
    tips1: 'Please enter emergence rate',
    tips: 'Please select the entry time',
    operator: 'operator',
    Type: 'Type of farming',
    Plant: 'Plant measurement',
    photograph: 'Farm photograph',
    view: 'view data',
    guidance: 'Agricultural records and guidance',
    Emergence: 'Emergence record',
    planting: 'Project of planting',
    write: 'write time',
    return: 'return'
  },
  yieldsRecordApp: {
    Operator: 'operator',
    Aphotos: 'Agricultural photos',
    Tfarming: 'Type of farming',
    Rtime: 'Record time',
    tips4: 'Please enter the output',
    tips5: 'Please fill in remarks',
    remark: 'remark',
    tips6: 'Please select a time',
    syield: 'survey the yield',
    tips1: 'Please fill in the area',
    tips: 'Please select the recording time',
    tips2: 'Please enter the area',
    tips3: 'Please fill in the output',
    ton: 'ton',
    artificial: 'artificial',
    yield: 'yield',
    define: 'define',
    acre: 'acre',
    Omode: 'operation mode'
  },
  emergenceRecordApp: {
    Erate: 'Emergence rate',
    Fphotos: 'Farming photos',
    define: 'define',
    tips4: 'Please select a time',
    remark: 'remark',
    write: 'write time',
    tips1: 'Please fill in the emergence rate',
    tips: 'Please select the recording time',
    operator: 'operator',
    tips2: 'Please enter the emergence rate',
    tips3: 'Please fill in remarks'
  },
  choiceLanguageApp: {
    define: 'define',
    Sl: 'Select language'
  },
  suggest: {
    LineSpacing: 'LineSpacing',
    Check: 'Check the form information integrity',
    tips: 'Fill in the fungus species',
    December: 'December',
    Otime: 'Optimum planting time',
    draft: 'Save as draft',
    estimate: 'Total cost estimate',
    July: 'July',
    Wetland: 'Wetland (Meadow/Marsh)',
    January: 'January',
    Oasis: 'Oasis No. 1',
    Land: 'Land preparation proposal',
    planning: 'planting planning',
    plant: 'plant',
    processing: 'Suggestions for fungus processing',
    Amachinery: 'Add automatic machinery',
    Person: 'Person/day',
    Pname: 'Name of plan',
    Irrigation: 'Irrigation management cost (manpower for water lines)',
    June: 'June',
    May: 'May',
    Sservice: 'Select Service',
    freight: 'freight',
    Saline: 'Saline',
    March: 'March',
    protection: 'plant protection',
    Labor: 'Labor force estimation',
    Asensor: 'Add suggestion sensor',
    dollar: 'dollar/acre/year',
    ton: 'ton/acre',
    October: 'October',
    kilo: 'kilo',
    variety: 'Fungus variety',
    seedlings: 'Number of seedlings',
    Fillin: 'Fill in the recommendations for fungus processing',
    tips4: 'Please enter the complete land preparation proposal',
    black: 'black soil',
    tips5: 'Please click Cultivation method',
    tips6: 'Please click recommended fertilizer',
    Modify: 'Modify expert advice',
    tips7: 'Please select service',
    suggest: 'submit suggest',
    tips1: 'Please select a fungus variety',
    Whole: 'Whole bead planting',
    tips2: 'Please enter the number of seedlings',
    cultivation: 'Method of cultivation',
    tips3: 'Please choose the best planting time',
    automated: 'automated machine',
    depth: 'Groove depth (hole depth)',
    width: 'Groove width (hole diameter)',
    information: 'view information',
    Yield: 'Yield estimation',
    operation: 'operation',
    return: 'return',
    dimensions: 'Fill in dimensions',
    irrigate: 'irrigate',
    Harvest: 'Harvest cost',
    tips8: 'Please enter the estimated number of people',
    krasnozem: 'krasnozem',
    tips9: 'Please enter the labor force estimate',
    Sname: 'Sensor name',
    Short: 'Short stem cutting',
    digit: 'input digit',
    strain: 'strain',
    individual: 'individual',
    Successful: 'Successful submission is recommended',
    April: 'April',
    August: 'August',
    Suggestions: 'Sensor deployment Suggestions',
    unit: 'unit',
    vadvice: 'view expert advice',
    month: 'Fill in the month',
    tips12: 'Please select the fungus processing suggestions',
    tips10: 'Please enter a production estimate',
    Giant: 'Giant fungus grass',
    classify: 'classify',
    hiring: 'hiring costs',
    Pservice: 'Planting service',
    September: 'September',
    farmyard: 'farmyard manure',
    advice: 'Fill in expert advice',
    delete: 'delete',
    content: 'Please check the output content',
    tips01: 'Please enter the correct yield estimate',
    Bead: 'Bead spacing',
    Fname: 'Fertilizer name',
    February: 'February',
    November: 'November',
    costs: 'Other costs',
    quantity: 'quantity',
    cost: 'Fertilizer management cost (organic fertilizer power manpower)',
    sprout: 'sprout',
    manufacturers: 'manufacturers',
    Quantity: 'Quantity to fill in',
    Cluster: 'Cluster planting',
    Sandy: 'Sandy soil',
    fertilizer: 'Recommended fertilizer',
    seedling: 'seedling transplantation',
    Lservice: 'Land formation service',
    Nmachine: 'Name of machine',
    Hservice: 'Harvest service',
    Lestimation: 'Land estimation',
    acre: 'dollar/acre',
    Afertilizer: 'Add recommended fertilizer',
    Planting: 'Planting services/labor costs',
    Handling: 'Handling & transportation costs (place of origin - place of processing)'
  },
  Tab1PageApp: {},
  machineManage: {
    MM: 'Mechanical management',
    cancel: 'cancel',
    Aywtd: 'Are you sure you want to delete',
    tips4: "'",
    DM: 'Production data management',
    skip: 'Skip to page',
    type: 'type',
    delete: 'delete',
    tips: 'Please enter name search',
    tips2: 'No data found',
    tips3: 'total of  records',
    confirm: 'confirm',
    compile: 'edit',
    ma: '?',
    Dtd: 'Delete this data',
    name: 'name',
    Manufacturer: 'Manufacturer/supplier/channel',
    model: 'model',
    page: "'",
    press: 'Press Enter to jump',
    brand: 'brand',
    operation: 'operation',
    machinery: 'New machinery'
  },
  insecticideManage: {
    Add: 'New entry',
    cancel: 'cancel',
    Aywtd: 'Are you sure you want to delete',
    DM: 'Production data management',
    tips5: "'",
    skip: 'Skip to page',
    delete: 'delete',
    tips: 'Please enter name search',
    tips2: 'No data found',
    tips3: 'total of  records',
    confirm: 'confirm',
    compile: 'edit',
    ma: '?',
    Dtd: 'Delete this data',
    name: 'name',
    Manufacturer: 'Manufacturer/supplier/channel',
    page: "'",
    press: 'Press Enter to jump',
    operation: 'operation',
    PM: 'Pesticide management'
  },
  applyFertilizerApp: {
    classify: 'classify',
    Sfertilizer: 'Select fertilizer',
    Fphotos: 'Farming photos',
    Nfertilizer: 'Name of fertilizer',
    remark: 'remark',
    delete: 'delete',
    tips: 'Please select the operation method',
    operator: 'operator',
    dollar: 'dollar',
    composition: 'composition',
    define: 'define',
    Manufacturer: 'Manufacturer/supplier/channel',
    kilogram: 'kilogram',
    write: 'write time',
    cost: 'cost',
    tips4: 'Please select fertilizer',
    tips5: 'Please enter fertilizer quantity',
    tips6: 'Please fill in remarks',
    manufactor: 'manufactor',
    Fmanure: 'Farmyard manure',
    tips7: 'Please select a time',
    tips1: 'Please select the recording time',
    tips2: 'Please fill in the fee',
    tips3: 'Please enter the fee',
    artificial: 'artificial',
    Omode: 'operation mode',
    machinery: 'machinery'
  },
  harvestRecord: {
    cancel: 'cancel',
    LineSpacing: 'LineSpacing',
    NoRecord: 'No record',
    Harvest: 'Harvest record',
    delete: 'successfully delete',
    tips: 'Are you sure you want to delete this item?',
    dollar: 'dollar',
    ton: 'ton',
    spacing: 'Plant spacing',
    harvesting: 'Recommended harvesting method',
    record: 'record',
    yield: 'yield',
    area: 'area',
    harvest: 'Suggested harvest time',
    cost: 'cost',
    proposes: 'expert proposes',
    Agricultural: 'Agricultural record',
    confirm: 'confirm',
    depth: 'Groove depth (hole depth)',
    month: 'month',
    management: 'Harvest management',
    Dtd: 'Delete this data',
    width: 'Groove width (hole diameter)',
    acre: 'acre',
    commentary: 'expert commentary'
  },
  ass: {
    cancel: 'cancel',
    Asuccess: 'Added success',
    submit: 'submit',
    record1: 'Production measurement record',
    recording: 'Please select the recording time',
    remark: 'remark',
    operator: 'operator',
    output: 'output',
    ton: 'ton',
    view: 'view data',
    farming: 'Type of farming',
    yield: 'yield monitoring',
    operating: 'operating type',
    stand: 'stand',
    area: 'area',
    proposes: 'expert proposes',
    Pea: 'Please enter area',
    photograph: 'Farm photograph',
    artificial: 'artificial',
    guidance: 'Agricultural records and guidance',
    Pio: 'Please input output',
    acre: 'acre',
    planting: 'Project of planting',
    time: 'write time',
    job: 'Please select a job mode',
    return: 'return'
  },
  addOrUpdateMachine: {
    cancel: 'cancel',
    Add: 'successfully added',
    Company: 'Mechanical Company',
    increased: 'New machinery',
    tips4: 'Please enter the model number',
    save: 'save',
    mechanical: 'mechanical',
    type: 'Type of machinery',
    tips1: 'Please enter type',
    tips: 'Please enter name',
    tips2: 'Please enter brand',
    tips3: 'Please enter manufacturer',
    modify: 'modify successfully',
    compile: 'Edit machinery',
    name: 'Machinery name',
    model: 'Mechanical model',
    brand: 'Machinery brand'
  },
  measuring: {
    cancel: 'cancel',
    Asuccess: 'Added success',
    knob: 'knob',
    submit: 'submit',
    remark: 'remark',
    auxograph: 'auxograph',
    operator: 'operator',
    tiller: 'tiller number',
    Plant: 'Plant measurement',
    view: 'view data',
    diameter: 'Individual stem diameter',
    Emergence: 'Emergence record',
    root: 'root',
    Height: 'Height per plant',
    write: 'write time',
    Tillering: 'Tillering record',
    Jointing: 'Jointing record',
    green: ' number of green leaves',
    individual: 'Pitch of individual plant',
    Bundle: 'Bundle weight',
    gram: 'gram',
    length: 'length of leaf',
    Entries: 'Entries',
    Weight: 'Weight per plant',
    Project: 'Project of planting',
    Type: 'Type of farming',
    photograph: 'Farm photograph',
    Survey: 'Survey number',
    nodes: 'Number of nodes per plant',
    Yellow: 'Yellow leaf number',
    guidance: 'Agricultural records and guidance',
    width: 'leaf width',
    return: 'return'
  },
  plantMainApp: {
    deinsectization: 'deinsectization',
    Wafi: 'Water and fertilizer integration',
    Tfarming: 'Type of farming',
    smanure: 'spread manure',
    plant: 'plant',
    irrigate: 'irrigate'
  },
  Dashboard: {
    leader: 'group leader',
    esearch: 'scientific research',
    access: 'No access',
    production: 'production',
    project: 'project',
    loginPlatform: 'Smart fungus cloud platform',
    China: 'China',
    underway: 'underway',
    member: 'project name',
    annual: 'annual',
    camera: 'camera',
    all: 'all',
    filed: 'Closed',
    tips4: 'The device is not associated with any item',
    tips5: 'Sorry, you do not have permission to access this project',
    loading: 'loading...',
    tips1: 'No new data in the last six hours',
    tips2: 'Loading the latest data',
    tips3: 'No project information',
    destroyed: 'destroyed',
    effect: 'experiment effect',
    name: 'project name',
    location: 'location',
    sensor: 'sensor',
    productioning: 'In progress-production',
    Researching: 'In progress-Research'
  },
  expertApp: {
    Eg: 'Expert guidance'
  },
  PosiConfig: {
    cancel: 'cancel',
    confirm: 'confirm',
    Geographic: 'Geographic information was updated successfully',
    coordinate: 'coordinate position',
    succeed: 'succeed',
    information: 'information',
    error: 'error',
    tips1: 'The selected location has been cleared',
    tips: 'please enter',
    tips2: 'Please select a latitude and longitude',
    Clear: 'Clear coordinates'
  },
  disinfesteRecordApp: {
    Pname: 'Pesticide name',
    Fphotos: 'Farming photos',
    Nused: 'Not used',
    remark: 'remark',
    delete: 'delete',
    tips: 'Please select the operation method',
    operator: 'operator',
    Sinsecticide: 'Select insecticide',
    dollar: 'dollar',
    tips8: 'Please fill in remarks',
    tips9: 'Please select a time',
    define: 'define',
    Manufacturer: 'Manufacturer/supplier/channel',
    kilogram: 'kilogram',
    write: 'write time',
    area: 'area',
    cost: 'cost',
    tips4: 'Please select insecticide',
    tips5: 'Please enter the pesticide quantity',
    tips6: 'Please fill in the fee',
    manufactor: 'manufactor',
    tips7: 'Please enter the fee',
    tips1: 'Please select the recording time',
    tips2: 'Please fill in the area',
    tips3: 'Please enter the area',
    artificial: 'artificial',
    acre: 'acre',
    Omode: 'Operation mode',
    machinery: 'machinery'
  },
  Tab4PageApp: {},
  irrigation: {
    cancel: 'cancel',
    Asuccess: 'Added success',
    submit: 'submit',
    irrigate: 'irrigate',
    mechanical: 'mechanical engineering',
    remark: 'remark',
    tips: 'Please select a job mode',
    operator: 'operator',
    dollar: 'dollar',
    ton: 'ton',
    view: 'view data',
    irrigation: 'irrigation regime',
    farming: 'Type of farming',
    Primary: 'Primary irrigation',
    operating: 'operating type',
    write: 'write time',
    area: 'area',
    Late: 'Late irrigation',
    grow: 'grow',
    cost: 'cost',
    fertilization: 'fertilization',
    tips4: 'Please enter the water consumption',
    tips5: 'Please enter the cost',
    tips1: 'Please choose the irrigation method',
    water: 'water supply volume',
    tips2: 'Please select a recording time',
    tips3: 'Please enter area',
    Project: 'Project of planting',
    artificial: 'artificial',
    photograph: 'Farm photograph',
    deinsectization: 'deinsectization',
    guidance: 'Agricultural records and guidance',
    acre: '亩',
    ioaf: 'integration of water and fertilizer',
    return: 'return',
    Planting: 'Planting record'
  },
  plantMeasurementApp: {
    Fphotos: 'Farming photos',
    remark: 'remark',
    tips: 'Please select the recording time',
    operator: 'operator',
    yleaves: 'Number of yellow leaves',
    Nplants: 'Node spacing per plant',
    tips8: 'Please fill in the spacing of individual plants',
    Llength: 'Leaf length',
    tips9: 'Please fill in the leaf length',
    define: 'define',
    Bwidth: 'Blade width',
    write: 'write time',
    Wplant: 'Weight per plant',
    gram: 'gram',
    tips4: 'Please fill in the individual plant height',
    Cweight: 'Clump weight',
    tips5: 'Please fill in the weight of single plant',
    Sdiameter: 'Stem diameter per plant',
    gleaves: 'Number of green leaves',
    tips6: 'Please fill in the diameter of single stem',
    Nplant: 'Number of nodes per plant',
    tips7: 'Please fill in the number of nodes per plant',
    tips2: 'Please fill in clump weight',
    MNo: 'Measurement No',
    tips3: 'Please fill in the number of tillers',
    Iheight: 'Individual plant height',
    tips13: 'Please fill in remarks',
    tips14: 'Please select a time',
    tips11: 'Please fill in the number of green leaves',
    tips12: 'Please fill in the number of yellow leaves',
    Tnumber: 'Tiller number',
    tips10: 'Please fill in the blade width'
  },
  dictItemTable: {
    cancel: 'cancel',
    item: 'Dictionary item name',
    successfully: 'successfully delete',
    language: 'language',
    remark: 'remark',
    skip: 'Skip to page',
    delete: 'delete',
    tips1: 'total of records',
    tips: "'",
    tips2: 'Are you sure you want to delete it?',
    tips3: '?',
    affirm: 'affirm',
    entry: 'New entry',
    compile: 'edit',
    skip2: 'Press Enter to jump',
    succeed: 'succeed',
    NoData: 'No Data',
    value: 'Dictionary entry value',
    operation: 'operation'
  },
  sysconfig: {
    successfully: 'save successfully',
    Hpoint: 'Home page map center point',
    smanagement: 'system management',
    sconfiguration: 'system configuration',
    save: 'save',
    Home: 'Home page map zoom level'
  },
  ProjectConig: {
    cancel: 'cancel',
    confirm: 'confirm',
    affiliation: 'Project affiliation',
    succeed: 'succeed',
    Assign: 'Assign to project',
    assigned: 'Devices have been assigned',
    Non: 'Non-camera device',
    tips1: 'Please select item',
    tips: 'please select',
    tips2: 'AI analysis cannot be set in multiple selection mode',
    tips3: 'Camera added sunlight analysis AI calculation of greenhouse'
  },
  jointing: {
    Tillering: 'Tillering record',
    cancel: 'cancel',
    Jointing: 'Jointing record',
    Asuccess: 'Added success',
    submit: 'submit',
    remark: 'remark',
    auxograph: 'auxograph',
    tips: 'Please select the entry time',
    operator: 'operator',
    tips2: 'Please enter the height',
    Type: 'Type of farming',
    Plant: 'Plant measurement',
    photograph: 'Farm photograph',
    view: 'view data',
    guidance: 'Agricultural records and guidance',
    Emergence: 'Emergence record',
    write: 'write time',
    return: 'return',
    Planting: 'Planting project',
    height: 'height'
  },
  Dashboard1: {
    Ndata: 'No new data in the last six hours',
    production: 'In progress - production',
    year: 'year',
    scientific: 'scientific research',
    project: 'project',
    Research: 'In progress. - Research',
    tips: '/',
    China: 'China',
    underway: 'underway',
    gleader: 'group leader',
    place: 'place',
    camera: 'camera',
    latest: 'Loading the latest data',
    all: 'all',
    pname: 'project name',
    Ninformation: 'No project information',
    loading: 'loading',
    tips1: 'The device is not associated with any item',
    tips2: 'Sorry, you do not have permission to access this project',
    destroyed: 'destroyed',
    Closed: 'Closed',
    pmember: 'project member',
    effect: 'experiment effect',
    sensor: 'sensor',
    Naccess: 'No access'
  },
  addOrUpdatesuggest3: {
    classify: 'classify',
    skip: 'Skip to page',
    soil: 'detection of soil',
    tips1: 'total of records',
    Asensor: 'Add sensor',
    tips2: 'Press Enter to jump',
    tips: "'",
    Fungus: 'Fungus breeding',
    selection: 'Confirm selection',
    wheather: 'wheather detection',
    name: 'Sensor name',
    Manufacturer: 'Manufacturer/supplier',
    model: 'model',
    camera: 'camera',
    brand: 'brand',
    Nfound: 'No data found'
  },
  addOrUpdatesuggest2: {
    all: 'all',
    classify: 'classify',
    harvest: 'harvest',
    skip: 'Skip to page',
    protection: 'Plant protection machine',
    UAV: 'UAV',
    tips1: 'total of records',
    tips2: 'Press Enter to jump',
    tips: "'",
    Name: 'Name of machine',
    irrigation: 'irrigation equipment',
    selection: 'Confirm selection',
    Manufacturer: 'Manufacturer/supplier/channel',
    planting: 'planting machinery',
    Amachinery: 'Add automatic machinery',
    model: 'model',
    brand: 'brand',
    Nfound: 'No data found'
  },
  addOrUpdatesuggest1: {
    classify: 'classify',
    component: 'component',
    selection: 'selection',
    name: 'Fertilizer name',
    Manufacturer: 'Manufacturer/supplier/channel',
    skip: 'Skip to page',
    tips1: 'total of records',
    Afertilizer: 'Add recommended fertilizer',
    tips2: 'Press Enter to jump',
    tips: "'",
    Nfound: 'No data found'
  },
  TabRecordApp: {
    Lelen: 'Leaf length',
    joint: 'joint',
    cuttage: 'cuttage',
    Nsosp: 'Node spacing of single plant',
    Opmode: 'operation mode',
    Excomment: 'Excomments',
    Gpr: 'Ground preparation record',
    MeasurNo: 'Measurement No',
    Gp: 'Grassland preparation',
    Chp: 'Channel planning',
    Cp: 'Cuttage planting',
    Plmeasure: 'Plant measurement',
    tips: 'Please enter the project name',
    Trmode: 'Truncation mode',
    Noyl: 'Number of yellow leaves',
    Wafir: 'Water and fertilizer integration record',
    Nonpp: 'Number of nodes per plant',
    Pc: 'Prepare content',
    price: 'price',
    Sdpp: 'Stem diameter per plant',
    Plmode: 'Planting mode',
    kilogram: 'kilogram',
    Lpreparation: 'Land preparation',
    Fetype: 'Fertilizer type',
    area: 'area',
    Trees: 'tree',
    Fsp: 'Flat strip planting',
    Nogl: 'Number of green leaves',
    Rod: 'Records of disinfestation',
    ftgws: 'fill the gaps with seedlings',
    Plrecord: 'Planting records',
    Ecomment: 'Expert comments',
    lowest: 'lowest',
    Tirecord: 'Tillering record',
    highest: 'highest',
    Irecord: 'Irrigation record',
    Agrecords: 'Agricultural records',
    machinery: 'machinery',
    altitude: 'altitude',
    average: 'average',
    Ser: 'Seedling emergence record',
    Abf: 'Apply base fertilizer',
    Jorecord: 'Jointing record',
    Wepp: 'Weight per plant',
    Lewid: 'Leaf width',
    dollar: 'dollar',
    wconsump: 'water consumption',
    ton: 'ton',
    sf: 'search for',
    Loading: 'Loading',
    Srat: 'Seedling raising and transplanting',
    Tillage: 'Tillage(rotary tillage)',
    root: 'root',
    yield: 'yield',
    Lirrigation: 'Late irrigation',
    Emrate: 'Emergence rate',
    Inph: 'Individual plant height',
    DDc: 'Digging drainage channels',
    cost: 'cost',
    cf: 'chemical fertilizer',
    quantity: 'quantity',
    Gsp: 'Grass seed preparation',
    Rw: 'Remove weeds',
    Gss: 'Grass seed selection',
    pesticide: 'pesticide',
    Fm: 'Farmyard manure',
    gram: 'gram',
    Gst: 'Grass seed truncation',
    Cweight: 'Clump weight',
    Arecord: 'All records',
    Fr: 'Fertilization record',
    irmethod: 'irrigation method',
    fertilizer: 'fertilizer',
    Ssp: 'Slope strip planting',
    artificial: 'artificial',
    Firrigation: 'First irrigation',
    Nrecord: 'No record',
    Pmr: 'Production measurement record',
    vernalization: 'vernalization',
    acre: 'acre',
    Grecord: 'Growth record',
    synchronization: 'synchronization',
    Tnumber: 'Tiller number',
    Harecord: 'Harvest record',
    Stplant: 'Strip planting'
  },
  loginMap: {
    systemLanguage: 'Choice system language',
    summary: 'No access',
    country: 'Chinese',
    link: '[link]',
    loginPlatform: 'Smart fungus cloud platform',
    loading: 'loading...',
    tips: 'The browser is incompatible or the version is too low. Please download the latest version of the browser',
    warn: 'warn',
    download: 'Go to download',
    QXdetail: 'Access beyond authority: no permission to access this interface!',
    loginButton: 'Login system',
    detail:
      'The token is invalid or expired, so you cannot access this function temporarily. You need to log in again!',
    Tips: 'Tips'
  },
  test: {
    technology: 'technology'
  },
  addOrUpdateSensor: {
    cancel: 'cancel',
    Add: 'successfully added',
    increased: 'New sensor',
    tips4: 'Please enter brand',
    save: 'save',
    tips5: 'Please enter the model number',
    type: 'Sensor type',
    tips1: 'Please enter type',
    tips: 'Please enter name',
    manufacturer: 'Sensor manufacturer',
    tips3: 'Please enter manufacturer',
    modify: 'modify successfully',
    compile: 'Edit sensor',
    name: 'Sensor name',
    model: 'Type of sensor',
    brand: 'Sensor brand'
  },
  fetilize: {
    cancel: 'cancel',
    Asuccess: 'Added success',
    submit: 'submit',
    pattern: 'fertilization pattern',
    irrigate: 'irrigate',
    mechanical: 'mechanical engineering',
    remark: 'remark',
    delete: 'delete',
    tips: 'Please select a job mode',
    operator: 'operator',
    dollar: 'dollar',
    pound: 'pound',
    view: 'view data',
    kilo: 'kilo',
    Phm: 'Post-harvest management',
    farming: 'Type of farming',
    operating: 'operating type',
    write: 'write time',
    area: 'area',
    harvest: 'harvest',
    grow: 'grow',
    cost: 'cost',
    fertilization: 'fertilization',
    proposes: 'expert proposes',
    tips4: 'Please enter the number in kilograms',
    Tabc: 'Topdressing at branch stage',
    tips5: 'Please enter the cost',
    tips1: 'Please choose a planting method',
    tips2: 'Please select the entry time',
    fertilizer: 'Select fertilizer',
    tips3: 'Please enter area',
    Tajs: 'Topdressing at jointing stage',
    Project: 'Project of planting',
    artificial: 'artificial',
    photograph: 'Farm photograph',
    deinsectization: 'deinsectization',
    Topdressing: 'Topdressing at seedling stage',
    guidance: 'Agricultural records and guidance',
    acre: 'acre',
    ioaf: 'integration of water and fertilizer',
    return: 'return',
    Planting: 'Planting record',
    Afertilizer: 'Added fertilizer'
  },
  Device: {
    bed: 'Pig feed - Fermentation bed',
    cancel: 'cancel',
    type: 'project type',
    tips: "'",
    number: 'equipment number',
    Idm: 'Intelligent device management',
    Euser: 'edit user',
    tips8: 'Are you sure you want to delete this user?',
    deletion: 'User deletion succeeded',
    tips9: 'Are you sure you want to perform this operation',
    enter: 'please enter',
    Business: 'Business status',
    all: 'all',
    inquire: 'inquire',
    Asuccessfully: 'User added successfully',
    coordinate: 'coordinate',
    Root: 'Root overwintering',
    equipment: 'equipment number',
    nworking: 'non-working',
    particular: 'particular',
    undistributed: 'undistributed',
    pack: 'pack up',
    establish: 'establish',
    affirm: 'affirm',
    field: 'Production field management',
    name: 'project name',
    reset: 'reset',
    Package: 'Package settings',
    device: 'device type',
    Press: 'Press Enter to search',
    wind: 'wind prevention and sand fixation',
    breeding: 'breeding',
    Eallocation: 'Equipment allocation',
    production: 'production',
    scientific: 'scientific research',
    save: 'save',
    scale: 'Coordinate setting',
    choose: 'please choose',
    ptype: 'project type',
    skip: 'Skip to page',
    error: 'error',
    remove: 'remove',
    Ndf: 'No data found',
    working: 'working',
    place: 'place',
    acquisition: 'Device acquisition',
    Mushroom: 'Mushroom planting',
    tips4: '?',
    tips5: 'Please select a device',
    Service: 'Business status setting',
    tips6: 'The selected device contains the coordinates uploaded by the device and cannot be modified',
    tips7: 'Please select at least one device',
    tips1: 'in total',
    tips2: 'Press Enter to jump',
    tips3: 'Are you sure you want to remove ',
    videoEquiment:'Add video device',
    equipments: 'Add device',
    Equipment: 'Equipment Status',
    Ausers: 'add users',
    succeed: 'succeed',
    devicename: 'devicename',
    Assign: 'Assign to project',
    operation: 'operation',
    unfold: 'unfold'
  },
  addOrUpdateRole: {
    cancel: 'cancel',
    prohibit: 'prohibit',
    Nexists: 'Number already exists',
    enable: 'enable',
    Enumber: 'Please enter the number',
    save: 'save',
    snumber: 'serial number',
    Enable: 'Enable or not',
    Rname: 'Role name',
    aroles: 'Add Roles',
    tips: 'Please enter the user name'
  },
  myImageApp: {},
  Tab2PageApp: {},
  selectInsecticide: {
    Ndf: 'No data found',
    Choose: 'Choose',
    name: 'name',
    Manufacturer: 'Manufacturer/supplier/channel',
    tips4: 'Choose at least one',
    insecticide: 'Selective insecticide',
    skip: 'There are records in total',
    tips: 'There are  records in total',
    tips3: 'Press Enter to jump'
  },
  Role: {
    cancel: 'cancel',
    rname: 'role name',
    smanagement: 'system management',
    records: 'total of records',
    skip: 'Skip to',
    farmers: 'farmers',
    delete: 'delete',
    Ejump: 'Press Enter to jump',
    tips: 'Are you sure you want to delete',
    Dmenu: 'Distribution menu',
    Administrator: 'Administrator',
    compile: 'edit',
    skip1: 'page',
    enable: 'enable',
    toble: "'",
    Nfound: 'No data found.',
    forbidden: 'forbidden',
    Udsucceeded: 'Role deleted successfully',
    tips1: '？',
    tips2: '？',
    tips3: '"',
    confirm: 'confirm',
    Dtd: 'Delete this data',
    succeed: 'succeed',
    Aroles: 'Add Roles',
    snumber: 'serial number',
    Erole: 'Please enter a role name',
    rmanagement: 'role management',
    operation: 'operation',
    user: 'user'
  },
  myImage: {},
  ArchiveForm: {
    cancel: 'cancel',
    mistake: 'mistake',
    affirm: 'affirm',
    submit: 'submit',
    success: 'success',
    category: 'Archiving cycle category',
    pigeonhole: 'pigeonhole',
    yearly: 'yearly',
    tips1: 'Are you sure you want to archive the selected items',
    tips: 'Please select an archive category',
    tips2: 'The selected archive is successful'
  },
  fertilizeres: {
    cancel: 'cancel',
    Asuccess: 'Added success',
    submit: 'submit',
    farmyard: 'farmyard manure',
    mechanical: 'mechanical engineering',
    remark: 'remark',
    Grass: 'Grass seed preparation',
    delete: 'delete',
    tips: 'Please select a job mode',
    operator: 'operator',
    preparation: 'Land preparation',
    dollar: 'dollar',
    view: 'view data',
    kilo: 'kilo',
    operating: 'operating type',
    write: 'write time',
    cost: 'cost',
    manufacturers: 'manufacturers',
    proposes: 'expert proposes',
    tips4: 'Please enter the number in kilograms',
    tips1: 'Please select the entry time',
    fertilizer: 'Base fertilizer application',
    tips2: 'Please enter the cost',
    Land: 'Land preparation record',
    tips3: 'Please select fertilizer',
    Type: 'Type of farming',
    artificial: 'artificial',
    photograph: 'Farm photograph',
    guidance: 'Agricultural records and guidance',
    Select: 'Select fertilizer',
    acre: 'acre',
    catty: 'catty',
    return: 'return',
    Afertilizer: 'Added fertilizer',
    Planting: 'Planting project'
  },
  AppTopbar: {
    confirm: 'confirm',
    cancel: 'cancel',
    exit: 'Are you sure you want to exit the system?',
    CPassword: 'Change Password',
    Log: 'Log out'
  },
  Apppad: {
    cancel: 'cancel',
    Ss: 'Sending succeeded',
    Tcr: 'Total cache requests',
    Tbs: 'To be synchronized',
    upload: 'upload',
    synchronization: 'synchronization',
    tips1: 'The network is connected. Are you sure to upload local records?',
    tips: 'The device is offline, please upload data in time after networking!',
    tips2: 'The network is not connected. Please synchronize the data after the network is connected',
    tips3: 'Failed to upload data. Please confirm to connect to the network and upload manually'
  },
  growthMainApp: {
    Trecord: 'Tillering record',
    emergence: 'Seedling emergence record',
    Tfarming: 'Type of farming',
    Jrecord: 'Jointing record',
    Pmeasurement: 'Plant measurement'
  },
  Detail: {
    cancel: 'cancel',
    coordinate: 'coordinate',
    Polar: 'Polar flight code',
    Device: 'Device details',
    Affiliated: 'Affiliated project',
    Enumber: 'Equipment serial number',
    Duration: 'Duration of SIM package',
    AppSecret: 'AppSecret',
    Secret: 'Secret',
    serial: 'serial number',
    Remarks: 'SIM Package Remarks',
    SIM: 'SIM operator',
    AppKey: 'AppKey',
    name: 'name',
    ClientId: 'ClientId',
    model: 'model',
    device: 'device type',
    account: 'account',
    Location: 'Location of deployment'
  },
  ResetPassword: {
    upassword: 'update password',
    cancel: 'cancel',
    successfully: 'Password changed successfully',
    submit: 'submit',
    Psecurity: 'The password contains 8 to 25 letters, digits, and special characters',
    tips1: 'Two passwords do not match',
    tips: 'The password content does not meet the requirements',
    tips2: 'Are you sure you want to change your password?',
    enpassword: 'Please enter a new password',
    affirm: 'affirm',
    succeed: 'succeed',
    npassword: 'new password',
    Enpassword: 'Please enter a new password',
    Epassword: 'Please enter the old password',
    perror: 'The original password is wrong, please check',
    cpassword: 'confirm password',
    opassword: 'original password'
  },
  User: {
    cancel: 'cancel',
    eaddress: 'email address',
    role: 'role',
    smanagement: 'system management',
    Eaddress: 'Please enter the correct email address',
    save: 'save',
    skip: 'skip to',
    error: 'error',
    delete: 'delete',
    Ejump: 'Press Enter to jump',
    tips: 'Please enter your name or mobile phone number',
    Ephone: 'Please enter the correct mobile phone number',
    Pnumber: 'Phone number',
    compile: 'edit',
    skip1: 'page',
    tips8: 'Are you sure you want to delete this user?',
    uright: 'user right',
    msuccessfully: 'modify successfully',
    sroles: 'Please select a role',
    Uasuccessfully: 'User added successfully',
    Nfound: 'No data found.',
    aname: 'address name',
    rpasswords: 'reset passwords',
    tips4: 'The password has been reset successfully. The SMS message has been sent to the mobile phone',
    tips5: 'Are you sure you want to perform this operation',
    tips6: 'The mobile phone number has been used. Please change it',
    ausers: 'add users',
    team: 'team',
    tips7: 'The mailbox has been used, please change it',
    Udsucceeded: 'User deletion succeeded',
    tips1: "'",
    tips2: 'in total',
    establish: 'establish',
    Ename: 'Please enter the user name',
    tips3: 'Are you sure you want to reset your password',
    affirm: 'affirm',
    ulist: 'users list',
    euser: 'edit user',
    succeed: 'succeed',
    Duser: 'Delete User',
    operation: 'operation'
  },
  grassPrepareApp: {
    Operator: 'operator',
    cost: 'cost',
    Fphotos: 'Farming photos',
    Sstrip: 'Slope strip planting',
    tips4: 'Please enter the fee',
    tips5: 'Please fill in remarks',
    Stransplanting: 'Seedling raising and transplanting',
    remark: 'remark',
    tips6: 'Please select a time',
    Cuttage: 'Cuttage planting',
    Fstrip: 'Flat strip planting',
    tips1: 'Please select the truncation method',
    tips: 'Please select the preparation content',
    tips2: 'Please select the recording time',
    Truncation: 'Truncation mode',
    tips3: 'Please fill in the fee',
    dollar: 'dollar',
    Gtruncation: 'Grass seed truncation',
    Gselection: 'Grass seed selection',
    vernalization: 'vernalization',
    define: 'define',
    Pcontent: 'Prepare content',
    write: 'write time'
  },
  selectFetilize: {
    Ndf: 'No data found',
    classify: 'classify',
    component: 'component',
    Choose: 'Choose',
    Select: 'Select fertilizer',
    name: 'Fertilizer name',
    Manufacturer: 'Manufacturer/supplier/channel',
    tips4: 'Press Enter to jump',
    tips5: 'Choose at least one',
    skip: 'Skip to page',
    tips1: 'total of records',
    tips: "'"
  },
  addOrUpdateDictItem: {
    cancel: 'cancel',
    New: 'New subentry',
    Dictionary: 'Dictionary item name',
    successfully: 'Type added successfully',
    submit: 'submit',
    Edit: 'Edit subentry',
    language: 'language',
    remark: 'remark',
    tips1: ' Please enter a value',
    tips: 'Please enter name',
    tips2: 'Please enter language',
    modify: 'modify successfully',
    succeed: 'succeed',
    Chinese: 'Chinese',
    value: 'Dictionary entry value'
  },
  error: {
    Click: 'Click to return to the home page'
  },
  TabPersonApp: {
    exit: 'exit',
    Ccs: 'Contact customer service',
    Lo: 'Log out',
    Hello: 'Hello',
    cancle: 'cancle',
    Vn: 'Version number',
    CP: 'Change Password',
    Vtow: 'Visit the official website',
    tips: 'Are you sure you want to exit the system'
  },
  tiller: {
    Tillering: 'Tillering record',
    cancel: 'cancel',
    average: 'average',
    Jointing: 'Jointing record',
    Asuccess: 'Added success',
    submit: 'submit',
    remark: 'remark',
    auxograph: 'auxograph',
    tips1: 'Please select the entry time',
    operator: 'operator',
    tiller: 'tiller number',
    tips: 'Please enter the complete tiller number',
    Project: 'Project of planting',
    Type: 'Type of farming',
    Plant: 'Plant measurement',
    photograph: 'Farm photograph',
    view: 'view data',
    highest: 'the highest',
    guidance: 'Agricultural records and guidance',
    Emergence: 'Emergence record',
    root: 'root',
    write: 'write time',
    minimum: 'minimum',
    return: 'return'
  },
  mainAddApp: {
    Ar: 'Agricultural records',
    number: 'number',
    pa: 'planting area',
    Rs: 'Record selection',
    Pr: 'Planting records',
    Pmr: 'Production measurement record',
    acre: 'acre',
    Gpr: 'Ground preparation record',
    Hr: 'Harvest record',
    Gr: 'Growth record',
    SR: 'Select Record'
  },
  PicturePlayback: {
    Sample: 'Sample Playback'
  },
  sensorManage: {
    cancel: 'cancel',
    Aywtd: 'Are you sure you want to delete',
    tips4: "'",
    skip: 'Skip to page',
    type: 'type',
    delete: 'delete',
    tips: 'Please enter name search',
    tips2: 'No data found',
    tips3: 'total of  records',
    confirm: 'confirm',
    compile: 'edit',
    ldm: 'Intelligent device management',
    ma: '?',
    Dtd: 'Delete this data',
    name: 'name',
    Manufacturer: 'Manufacturer/supplier/channel',
    sensor: 'New sensor',
    model: 'model',
    SM: 'sensor management',
    page: '‘',
    press: 'Press Enter to jump',
    brand: 'brand',
    operation: 'operation'
  },
  Language: {
    cancel: 'cancel',
    Added: 'Added',
    tips4: 'Are you sure you want to delete ',
    tips5: '?',
    skip: 'Skip to page',
    tips6: 'Data exists on this node and cannot be deleted',
    Multilingual: 'Multilingual management',
    type: 'Language package type',
    delete: 'delete',
    tips1: 'total of records',
    content: 'Entry content',
    tips: "'",
    tips2: 'Press Enter to jump',
    tips3: 'This node has child nodes and cannot be deleted',
    affirm: 'affirm',
    fail: 'fail',
    system: 'system management',
    compile: 'edit',
    Sdelete: 'successfully delete',
    Nd: 'no data',
    succeed: 'succeed',
    name: 'Entry name',
    attention: 'attention',
    operation: 'operation'
  },
  ServiceConfig: {
    cancel: 'cancel',
    select: 'please select',
    submit: 'submit',
    succeed: 'succeed',
    remark: 'remark',
    Package: 'Package setting',
    validity: 'validity',
    tips1: 'Please enter the validity period',
    operator: 'SIM operator',
    tips: 'Enter the SIM carrier',
    tips2: 'Set the package successfully'
  },
  showSuggest: {
    Ndata: 'no data',
    LineSpacing: 'LineSpacing',
    Stype: 'Sensor type',
    type: 'fertilizer type',
    Name: 'Name of plan',
    manufacturer: 'Fertilizer manufacturer',
    pop: 'Project of planting',
    view: 'View the project',
    Number: 'Number of seedlings',
    variety: 'Fungus variety',
    Bead: 'Bead spacing',
    Sname: 'Sensor name',
    proposal: 'Land preparation proposal',
    services: 'Selecting services',
    View: 'View the project',
    cultivation: 'cultivation pattern',
    recommended: 'recommended fertilizer',
    Optimum: 'Optimum planting time',
    Type: 'Type of machine',
    depth: 'Groove depth (hole depth)',
    month: 'month',
    specialist: 'specialist',
    person: 'person/acre',
    machine: 'Name of machine',
    width: 'Groove width (hole diameter)',
    name: 'Fertilizer name',
    force: 'Labor force estimation',
    sensor: 'sensor',
    Smanufacturer: 'Sensor manufacturer',
    pm: ' Project Management ',
    return: 'return'
  },
  addOrUpdateProjectShow: {
    cancel: 'cancel',
    country: 'country',
    address: 'detailed address',
    coordinate: 'coordinate',
    Asuccess: 'Added success',
    town: 'town',
    submit: 'submit',
    Eitem: 'Edit display item',
    pname: 'project name',
    tips: 'Please select coordinates',
    picture: 'picture',
    content: 'content',
    Aitem: 'Add display item',
    modify: 'modify successfully',
    province: 'province',
    China: 'China',
    district: 'district',
    ptitle: 'Project Title',
    location: 'location',
    garden: 'garden',
    elink: 'external link'
  },
  Comesoon: {
    tips: 'The function is temporarily unavailable'
  },
  land: {
    cancel: 'cancel',
    Asuccess: 'Added success',
    submit: 'submit',
    mechanical: 'mechanical engineering',
    remark: 'remark',
    Grass: 'Grass seed preparation',
    content: 'content preparation',
    tips: 'Please select the preparation content',
    operator: 'operator',
    dollar: 'dollar',
    view: 'view data',
    weed: 'weed',
    operating: 'operating type',
    write: 'write time',
    Dig: 'Dig drainage channels',
    cost: 'cost',
    Channel: 'Channel planning',
    tips1: 'Please select a job mode',
    Base: 'Base fertilizer application',
    tips2: 'Please select the entry time',
    Land: 'Land preparation record',
    tips3: 'Please enter the cost',
    Tilling: 'Tilling (rotary tilling)',
    Type: 'Type of farming',
    artificial: 'artificial',
    photograph: 'Farm photograph',
    guidance: 'Agricultural records and guidance',
    Soil: 'Soil Preparation',
    return: 'return',
    Planting: 'Planting project'
  },
  menus: {
    cancel: 'cancel',
    Sindex: 'Sort index',
    smanagement: 'system management',
    skip: 'Skip to',
    type: 'type',
    delete: 'delete',
    toble1: 'total of records',
    Ejump: 'Press Enter to jump',
    tips: 'Are you sure you want to delete',
    Mmanagement: 'menu management',
    button: 'button',
    compile: 'edit',
    skip1: 'page',
    toble: "'",
    Emenu: 'Please enter a menu name',
    Ami: 'Add Menu Items',
    Fname: 'Font icon name',
    Nfound: 'No data found.',
    ' URL': ' URL string',
    forbidden: 'forbidden',
    Mname: 'Menu name',
    menu: 'menu',
    tips1: '"',
    catalogue: 'catalogue',
    tips2: '?',
    tips3: '?',
    confirm: 'confirm',
    invocation: 'invocation',
    Dtd: 'Delete this data',
    operation: 'operation'
  },
  showMap2App: {},
  TabProjectApp: {
    Eguidance: 'Expert guidance',
    Mp: 'My project',
    Nrecord: 'No record',
    Pd: 'Project details',
    Loading: 'Loading',
    Si: 'Search items',
    Agrecords: 'Agricultural records',
    Archived: 'Archived'
  },
  createPlan: {
    Import: 'Import into project',
    pt: 'project type',
    tips: 'Please enter a project name',
    degrees: 'A slope less than 25 degrees',
    objective: 'project objective',
    loss: 'water and soil loss',
    desertification: 'desertification',
    experiment: 'experiment effect',
    enclosure: 'Project enclosure',
    draft: 'Save as draft',
    ' PH': ' PH range',
    state: 'state',
    presence: 'Item name presence',
    mushroom: 'Seed mushroom',
    Wetland: 'Wetland (Meadow/Marsh)',
    item: 'item number',
    natural: 'natural rainfall',
    Root: 'Root overwintering',
    biofuel: 'biofuel',
    Local: 'Local weather',
    loading: 'loading',
    slope: 'Protect the slope and fix the dike',
    monitoring: 'environmental monitoring',
    Longitude: 'Longitude will be calculated automatically after drawing the project plot outline',
    Land: 'Land preparation condition',
    fodder: 'fodder',
    planning: 'planting planning',
    field: 'Production field management',
    Next: 'Next step: Additional information',
    Automatic: 'Automatic calculation after drawing project plot outline',
    breeding: 'breeding',
    other: 'other information',
    Draw: 'Draw the project plot outline',
    soil: 'soil property',
    Research: 'Research',
    cycle: 'project cycle',
    dollar: 'dollar',
    remediation: 'soil remediation',
    flat: 'flat',
    Mine: 'Mine restoration',
    temperature: 'annual average temperature',
    terrace: 'terrace',
    tornado: 'Annual tornado days',
    Use: 'Use for planting fungus',
    tips4: 'Please enter the full address',
    black: 'black soil',
    Dimensions: 'Dimensions are calculated automatically after drawing the project plot outline',
    tips5: 'Please enter the land preparation conditions',
    tips6: 'Please enter irrigation conditions',
    tips7: 'Please enter the amount',
    tips1: 'Please carry out project enclosure',
    tips2:
      'Tip: The name of province/autonomous region, city, district/county should use abbreviation, such as Inner Mongolia Autonomous Region abbreviation Inner Mongolia, Alxa League abbreviation Alxa',
    tips3: 'Please enter the full location',
    maximum: 'Annual mean maximum temperature',
    minimum: 'Annual mean minimum temperature',
    governance: 'For ecological governance',
    bed: 'Pig feed - Fermentation bed',
    Ndata: 'no data',
    saline: 'saline-alkali soil',
    Click: 'Click for third-party weather data',
    Immersion: 'Immersion irrigation',
    red: 'red earth',
    irrigation: 'irrigation conditions',
    tips8: 'Please enter the correct or complete amount',
    tips9: 'Please enter soil properties',
    fibrous: 'fibrous material',
    'Save ': 'Save as draft',
    day: 'day',
    area: 'Project area',
    rain: 'Annual number of days of rain',
    coordinate: 'Project coordinate',
    town: 'town',
    Creation: 'Creation plan',
    created: 'Successfully created',
    Province: 'Province/continent/autonomous region',
    tips15: 'Please enter the project cycle',
    tips16: 'Please enter the province and city information first',
    tips13: 'Please enter the project type',
    tips14: 'Please enter the project objective',
    snow: 'Annual snow days',
    tips11: 'Next step: Plant fungus for use',
    tips12: 'Please enter the project number',
    name: 'project name',
    tips10: 'Please enter pH value',
    fixed: 'Wind fixed sand',
    thunderstorm: 'Annual thunderstorm days',
    amending: 'amending plan',
    District: 'District',
    Formally: 'Formally submit project',
    no: 'no',
    import: 'successful import',
    production: 'production',
    remark: 'remark',
    Processing: 'Processing target of fungus after harvest',
    fungus: 'Reuse the fungus when it is mature',
    plot: 'Planning plot information',
    China: 'China',
    sheet: 'New type sheet',
    Electrical: 'Electrical conductivity (EC)',
    trickle: 'trickle irrigation',
    foggy: 'Annual number of foggy days',
    rainfall: 'Annual rainfall',
    yes: 'yes',
    saltness: 'saltness',
    sprinkling: 'sprinkling irrigation',
    Material: 'Material of fungus',
    Sandy: 'Sandy soil',
    hail: 'Annual hail days',
    stony: 'stony desertification',
    detailed: 'detailed address',
    acre: 'acre',
    location: 'project location',
    objectives: 'Ecological governance objectives'
  },
  showMapApp: {
    Area: 'Area',
    acre: 'acre'
  },
  harvest: {
    cancel: 'cancel',
    Asuccess: 'Added success',
    submit: 'submit',
    ' acre': ' acre',
    mechanical: 'mechanical engineering',
    remark: 'remark',
    Harvest: 'Harvest management',
    tips: 'Please select a job mode',
    operator: 'operator',
    dollar: 'dollar',
    ton: 'ton',
    view: 'view data',
    farming: 'Type of farming',
    reap: 'reap',
    yield: 'yield',
    operating: 'operating type',
    stand: 'stand',
    write: 'write time',
    area: 'area',
    cost: 'cost',
    proposes: 'expert proposes',
    tips4: 'Please enter the cost',
    tips1: 'Please select a recording time',
    tips2: 'Please enter area',
    tips3: 'Please input output',
    Project: 'Project of planting',
    photograph: 'Farm photograph',
    artificial: 'artificial',
    guidance: 'Agricultural records and guidance',
    return: 'return'
  },
  showProject1: {
    no: 'no',
    average: 'annual average temperature',
    saline: 'saline-alkali soil',
    Parea: 'Project area',
    Ppi: 'Project planning information',
    soil: 'soil property',
    irrigation: 'irrigation conditions',
    fungus: 'Reuse the fungus when it is mature',
    enclosure: 'Project enclosure',
    Create: 'Create user name',
    contact: 'Create user contact information',
    Electrical: 'Electrical conductivity (EC)',
    day: 'day',
    rain: 'Annual number of days of rain',
    foggy: 'Annual number of foggy days',
    ecological: 'For ecological governance',
    rainfall: 'Annual rainfall',
    address: 'detailed address',
    tornado: 'Annual tornado days',
    coordinate: 'Project coordinate',
    yes: 'yes',
    saltness: 'saltness',
    hail: 'Annual hail days',
    Project: 'Project planning information',
    planning: 'planting planning',
    condition: 'Land preparation condition',
    Ecological: 'Ecological governance objectives',
    snow: 'Annual snow days',
    name: 'project name',
    PH: 'PH value',
    maximum: 'Annual mean maximum temperature',
    processing: 'The processing target after receiving the mature fungus',
    location: 'project location',
    thunderstorm: 'Annual thunderstorm days',
    minimum: 'Annual mean minimum temperature',
    return: 'return'
  },
  showProject2: {
    no: 'no',
    sproperty: 'soil property',
    ptype: 'project type',
    ylut: 'ylut',
    remark: 'remark',
    Fgovernance: 'For ecological governance',
    carea: 'cultivated area',
    pobjective: 'project objective',
    ton: 'ton/acre',
    Rmature: 'Reuse the fungus when it is mature',
    Pcoordinate: 'Project coordinate',
    Electrical: 'Electrical conductivity (EC)',
    Eobjectives: 'Ecological governance objectives',
    Pp: 'Planting project',
    plocation: 'project location',
    Icost: 'Irrigation cost',
    pname: 'project name',
    yes: 'yes',
    saltness: 'saltness',
    Land: 'Land preparation condition',
    iconditions: 'irrigation conditions',
    Pharvest: 'Processing target of fungus after harvest',
    inumber: 'item number',
    Vproject: 'View project',
    effect: 'experiment effect',
    Penclosure: 'Project enclosure',
    ssoil: 'saline-alkali soil',
    pcycle: 'project cycle',
    PM: 'PM(project management)',
    return: 'return'
  },
  Overview: {
    bed: 'Pig feed - Fermentation bed',
    production: 'production',
    scientific: 'scientific research',
    choose: 'please choose',
    quantum: 'time quantum',
    type: 'project type',
    Modcrn: 'Modcrn Agriculture',
    tips: 'No project information found',
    Dreleased: 'Device released',
    view: 'view data',
    Pfm: 'Production field management',
    underway: 'underway',
    Nwd: 'No weather data',
    enter: 'please enter',
    place: 'place',
    all: 'all',
    inquire: 'inquire',
    Fon: 'File or not',
    Root: 'Root overwintering',
    devices: 'devices',
    monitoring: 'environmental monitoring',
    pack: 'pack up',
    Poverview: 'Project Overview',
    serial: 'serial number',
    Closed: 'Closed',
    name: 'project name',
    reset: 'reset',
    Planting: 'Planting project',
    unfold: 'unfold',
    wind: 'wind prevention and sand fixation',
    breeding: 'breeding'
  },
  Tab3PageApp: {
    Ar: 'Agricultural records',
    Loading: 'Loading'
  },
  ESVideo: {},
  Detail2: {
    cancel: 'cancel',
    coordinate: 'coordinate',
    article: 'article',
    duration: 'SIM package duration',
    password: 'password',
    Equipment: 'Equipment serial number',
    serial: 'serial',
    SIM: 'SIM operator',
    name: 'name',
    model: 'model',
    details: 'device details',
    device: 'Device Type',
    Notes: 'Notes on SIM package',
    account: 'account number',
    Location: 'Deployment location'
  },
  Detail1: {
    Eguidance: 'Expert guidance',
    Hmanagement: 'Harvesting management',
    Ground: 'Ground preparation',
    Growth: 'Growth record',
    Lchart: 'Loading chart',
    hours2: '72 hours',
    hours1: '48 hours',
    Hequipment: 'Historical equipment',
    video: 'Play video',
    Nseedlings: 'Number of seedlings',
    measurement: 'Production measurement record',
    number: 'number',
    download: 'download',
    Ptype: 'Project type',
    yield: 'yield',
    Production: 'Production estimate',
    parameter: 'parameter',
    weather: 'Future weather',
    newest: 'newest',
    value: 'mean value',
    day: 'day',
    hours: '24 hours',
    coordinate: 'coordinate',
    parea: 'planting area',
    more: 'see more',
    Ldevice: 'Loading device information',
    Playback: 'Sample Playback',
    Pproject: 'Planting project',
    Vdata: 'View data',
    View: 'View details',
    target: 'target',
    Eadvice: 'Expert advice',
    Pdisplay: 'Project data display',
    month: 'month',
    species: 'Fungus species',
    Ndevice: 'No device information found',
    tons: 'tons/ ha',
    Cimage: 'Capturing image',
    plant: 'plant',
    effect: 'Experimental effect',
    ' underway': ' under way',
    to: 'to',
    time: 'time',
    pcycle: 'project cycle',
    return: 'return',
    Planting: 'Planting records',
    PO: 'Project Overview'
  },
  fertilizerManage: {
    cancel: 'cancel',
    ingredient: 'ingredient',
    Aywtd: 'Are you sure you want to delete',
    FM: 'Fertilizer management',
    tips4: "'",
    DM: 'Production data management',
    skip: 'Skip to page',
    type: 'type',
    tips1: 'No data found',
    delete: 'delete',
    fertilizer: 'New fertilizer',
    tips: 'Please enter name search',
    tips3: 'total of records',
    confirm: 'confirm',
    compile: 'edit',
    ma: '?',
    Dtd: 'Delete this data',
    name: 'name',
    Manufacturer: 'Manufacturer/supplier/channel',
    page: "'",
    press: 'Press Enter to jump',
    operation: 'operation'
  },
  choicelanguage: {
    confirm: 'Confirm Selection',
    systemlanguage: 'select system language'
  },
  harvestRecordApp: {
    Tfarming: 'Type of farming',
    Fphotos: 'Farming photos',
    remark: 'remark',
    tips: 'Please select the operation method',
    operator: 'operator',
    dollar: 'dollar',
    ton: 'ton',
    tips8: 'Please fill in remarks',
    tips9: 'Please select a time',
    yield: 'yield',
    define: 'define',
    write: 'write time',
    area: 'area',
    harvest: 'harvest',
    cost: 'cost',
    tips4: 'Please fill in the output',
    tips5: 'Please enter the output',
    tips6: 'Please fill in the fee',
    tips7: 'Please enter the fee',
    tips1: 'Please select the recording time',
    tips2: 'Please fill in the area',
    tips3: 'Please enter the area',
    artificial: 'artificial',
    acre: 'acre',
    Omode: 'operation mode',
    machinery: 'machinery'
  },
  addOrUpdateMenus: {
    enumber: 'Please enter the number',
    cancel: 'cancel',
    prohibit: 'prohibit',
    Sindex: 'Sort index',
    asuccess: 'successfully added',
    save: 'save',
    type: 'type',
    Iname: 'Icon name',
    menu: 'menu',
    Pdirectory: 'Parent directory',
    URL: 'URL string',
    tips: 'Please enter a menu name',
    catalogue: 'catalogue',
    Ename: 'Please enter name',
    button: 'button',
    coding: 'coding',
    modify: 'modify successfully',
    enable: 'enable',
    name: 'name',
    eindex: 'Please enter sort index',
    Enable: 'Enable or not',
    afail: 'fail to add',
    Ami: 'Add Menu Items'
  },
  loginMapApp: {
    cipher: 'cipher',
    cancel: 'cancel',
    tips4: 'Your account has been logged in on another device, please log in again',
    tips5: 'Unauthorized access: no permission to access the interface!',
    tips6: 'Do you want to exit the Mycobacterium cloud platform?',
    an: 'account number',
    tips1: 'The password has been saved and cannot be viewed',
    tips: 'Please enter the account number',
    tips2: 'Please input a password',
    tips3: 'Select system language',
    exit: 'exit',
    Si: 'Sign in',
    Rp: 'Remember password'
  },
  irrigateRecordApp: {
    Operator: 'operator',
    wconsumption: 'water consumption',
    Aphotos: 'Agricultural photos',
    remark: 'remark',
    tips: 'Please select the operation method',
    dollar: 'dollar',
    ton: 'ton',
    tips8: 'Please enter the fee',
    tips9: 'Please fill in remarks',
    Lirrigation: 'Late irrigation',
    define: 'define',
    area: 'area',
    cost: 'cost',
    Rtime: 'Record time',
    tips4: 'Please enter the area',
    tips5: 'Please fill in the water consumption',
    tips6: 'Please enter water consumption',
    tips7: 'Please fill in the fee',
    tips1: 'Please select irrigation method',
    tips2: 'Please select the recording time',
    tips3: 'Please fill in the area',
    artificial: 'artificial',
    Firrigation: 'First irrigation',
    acre: 'acre',
    tips10: 'Please select a time',
    Omode: 'operation mode',
    imethod: 'irrigation method',
    machinery: 'machinery'
  },
  Main: {
    break: 'break',
    tips1: 'Connect to server successfully!',
    platform: 'Intelligent fungus cloud platform',
    tips: 'Successfully reconnecting to the server!',
    tips2: 'Disconnect from server!',
    Chistory: 'Change chat history'
  },
  StatusConfig: {
    cancel: 'cancel',
    confirm: 'confirm',
    affirm: 'affirm',
    Not: 'Not working',
    succeed: 'succeed',
    Service: 'Service status setting',
    working: 'working',
    tips1: 'Please select working status',
    tips: 'Select the state you want to set',
    tips2: 'Are you sure you want to change the state?',
    tips3: 'Status setting successful'
  }
}

