function getButton(menuList, codes, i) {
  const list = menuList.filter((item) => item.code === codes[i]);
  if (list.length > 0) {
    if (list[0].children) {
      return getButton(list[0].children, codes, i + 1);
    } else if (list[0].buttons && i + 2 === codes.length) {
      return list[0].buttons.filter((item) => item.code === codes[i + 1]);
    }
  }
  return []
}


export default (el, binding) => {
  const array = binding.value
  if (!(array && array.length > 0)) {
    return
  }
  try {
    const menuList = JSON.parse(localStorage.getItem("menuList"))
    let buttons = getButton(menuList, array, 0);
    if (buttons.length === 0) {
      el.parentNode?.removeChild(el)
    }
  } catch (e) {
    console.error('权限报错：' + e)
    el.parentNode?.removeChild(el)
  }
}
