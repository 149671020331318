import { createRouter, createWebHashHistory } from 'vue-router';

const childrenRouter = [
  {
    path: '/dashboard',
    name: 'dashboard',
    component: () => import('./views/modules/Dashboard'),
  },
  {
    path: '/comesoone',
    name: 'comesoone',
    component: () => import('./views/modules/Comesoon'),
  },
  {
    path: '/role',
    name: 'role',
    component: () => import('./views/modules/role/Role'),
  },
  {
    path: '/user',
    name: 'user',
    component: () => import('./views/modules/user/User'),
  },
  {
    path: '/menus',
    name: 'menus',
    component: () => import('./views/modules/role/menus'),
  },
  {
    path: '/project/processing',
    name: 'project_processing',
    component: () => import('./views/modules/project/processing/Project'),
  },
  {
    path: '/project/form',
    name: 'project_form',
    component: () => import('./views/modules/project/processing/ProjectForm'),
  },
  {
    path: '/project/show',
    component: () => import('./views/modules/project/processing/showProject'),
  },
  {
    path: '/project/archive',
    name: 'archive_processing',
    component: () => import('./views/modules/project/archive/Project'),
  },
  {
    path: '/archive/view',
    name: 'archive_view',
    component: () => import('./views/modules/project/archive/ProjectForm'),
  },
  {
    path: '/device',
    name: 'device',
    component: () => import('./views/modules/device/Device'),
  },
  {
    path: '/device/form',
    name: '/device/form',
    component: () => import('./views/modules/device/DeviceForm'),
  },
  {
    path: '/device/add',
    name: 'addDevice',
    component: () => import('./views/modules/device/AddDevice.vue'),
  },
  {
    path: '/data/overview',
    name: 'data_overview',
    component: () => import('./views/modules/data/overview/Overview'),
  },
  {
    path: '/data/detail/dtzz',
    name: 'data_detail_dtzz',
    component: () => import('./views/modules/data/overview/Detail'),
  },
  {
    path: '/data/compare',
    name: 'data_compare',
    component: () => import('./views/modules/data/compare/Compare'),
  },
  {
    path: '/data/compare/split',
    name: 'data_compare_split',
    component: () => import('./views/modules/data/compare/Split'),
  },
  {
    path: '/data/compare/one',
    name: 'data_compare_one',
    component: () => import('./views/modules/data/compare/One'),
  },
  {
    path: '/dataDictionary',
    component: () => import('./views/modules/dataDictionary/dataDictionary'),
  },
  {
    path: '/plan',
    component: () => import('./views/modules/plan/plan'),
  },
  {
    path: '/createPlan',
    component: () => import('./views/modules/plan/createPlan'),
  },
  {
    path: '/suggest',
    component: () => import('./views/modules/plan/suggest'),
  },
  {
    path: '/showSuggest',
    component: () => import('./views/modules/plan/showSuggest'),
  },
  {
    path: '/test',
    name: 'test',
    component: () => import('./views/test'),
  },
  {
    path: '/distribuirExport',
    name: 'distribuirExport',
    component: () => import('./views/modules/plan/distribuirExport'),
  }, {
    path: '/showProject',
    component: () => import('./views/modules/plan/showProject'),
  },
  {
    path: '/fertilizerManage',
    component: () => import('./views/modules/agriculturaInfo/fertilizerManage'),
  },
  {
    path: '/machineManage',
    component: () => import('./views/modules/agriculturaInfo/machineManage'),
  },
  {
    path: '/insecticideManage',
    component: () => import('./views/modules/agriculturaInfo/insecticideManage'),
  },
  {
    path: '/sensorManage',
    component: () => import('./views/modules/agriculturaInfo/sensorManage'),
  },
  {
    path: '/plant/plant',
    component: () => import('./views/modules/data/overview/plant/plant'),
  },
  {
    path: '/plant/fetilize',
    component: () => import('./views/modules/data/overview/plant/fetilize'),
  },
  {
    path: '/plant/irrigation',
    component: () => import('./views/modules/data/overview/plant/irrigation'),
  },
  {
    path: '/plant/integration',
    component: () => import('./views/modules/data/overview/plant/integration'),
  },
  {
    path: '/plant/deworming',
    component: () => import('./views/modules/data/overview/plant/deworming'),
  },
  {
    path: '/ass/ass',
    component: () => import('./views/modules/data/overview/ass/ass'),
  },
  {
    path: '/harvest/harvest',
    component: () => import('./views/modules/data/overview/harvest/harvest'),
  },
  {
    path: '/soilpre/land',
    component: () => import('./views/modules/data/overview/soilpre/land'),
  },
  {
    path: '/soilpre/grass',
    component: () => import('./views/modules/data/overview/soilpre/grass'),
  },
  {
    path: '/soilpre/fertilizeres',
    component: () => import('./views/modules/data/overview/soilpre/fertilizeres'),
  },
  {
    path: '/grow/emergence',
    component: () => import('./views/modules/data/overview/grow/emergence'),
  },
  {
    path: '/grow/jointing',
    component: () => import('./views/modules/data/overview/grow/jointing'),
  },
  {
    path: '/grow/measuring',
    component: () => import('./views/modules/data/overview/grow/measuring'),
  },
  {
    path: '/grow/tiller',
    component: () => import('./views/modules/data/overview/grow/tiller'),
  }, {
    path: '/project/showProj',
    component: () => import('./views/modules/project/projectShow/project_show'),
  }, {
    path: '/record/info',
    component: () => import('./views/modules/data/overview/recordInfo'),
  },
  {
    path: '/sysconfig',
    component: () => import('./views/modules/sys/sysconfig')
  },
  {
    path: '/language',
    component: () => import('./views/modules/language/Language'),
  },

];
const routes = [

  {
    path: '/dashboard',
    name: 'dashboard',
    component: () => import('./views/modules/Dashboard'),
    children: childrenRouter,
  },
  {
    path: '/workbench',
    name: 'workbench',
    component: () => import('./views/main/Main'),
    children: childrenRouter,
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('./views/modules/login/Login'),
  },
  {
    path: '/forgotPassword',
    name: 'forgotPassword',
    component: () => import('./views/modules/login/ForgotPassword'),
  },
  {
    path: '/loginMap',
    component: () => import('./views/modules/login/LoginMap'),
  },
  {
    path: '/loginMap1',
    component: () => import('./views/modules/login/map'),
  },
  {
    path: '/404',
    component: () => import('./views/modules/404'),
  },

];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});
router.beforeEach((to, from, next) => {
  console.log(to)
  if (to.matched.length ===0) {  //如果未匹配到路由
    next('/404');   //如果上级也未匹配到路由则跳转登录页面，如果上级能匹配到则转上级路由
  } else {
    next();    //如果匹配到正确跳转
  }
});
export default router;
