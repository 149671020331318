import 'primevue/resources/themes/saga-blue/theme.css'
import 'primevue/resources/primevue.min.css'
import 'primeflex/primeflex.css'
import 'primeicons/primeicons.css'
import 'prismjs/themes/prism-coy.css'
import '@fullcalendar/core/main.min.css'
import '@fullcalendar/daygrid/main.min.css'
import '@fullcalendar/timegrid/main.min.css'
import './assets/layout/layout.scss'
import './assets/layout/flags/flags.css'
import './assets/layout/mystyle.css'

import { createApp, reactive } from 'vue'
import router from './router'
import App from './App.vue'
import PrimeVue from 'primevue/config'
import AutoComplete from 'primevue/autocomplete'
import Accordion from 'primevue/accordion'
import AccordionTab from 'primevue/accordiontab'
import Avatar from 'primevue/avatar'
import AvatarGroup from 'primevue/avatargroup'
import Badge from 'primevue/badge'
import BadgeDirective from 'primevue/badgedirective'
import Button from 'primevue/button'
import Breadcrumb from 'primevue/breadcrumb'
import Calendar from 'primevue/calendar'
import Card from 'primevue/card'
import Editor from 'primevue/editor'
import Carousel from 'primevue/carousel'
import Chart from 'primevue/chart'
import Checkbox from 'primevue/checkbox'
import Chip from 'primevue/chip'
import Chips from 'primevue/chips'
import ColorPicker from 'primevue/colorpicker'
import Column from 'primevue/column'
import ColumnGroup from 'primevue/columngroup'
import ConfirmDialog from 'primevue/confirmdialog'
import ConfirmPopup from 'primevue/confirmpopup'
import ContextMenu from 'primevue/contextmenu'
import DataTable from 'primevue/datatable'
import DataView from 'primevue/dataview'
import DataViewLayoutOptions from 'primevue/dataviewlayoutoptions'
import Dialog from 'primevue/dialog'
import Divider from 'primevue/divider'
import Dropdown from 'primevue/dropdown'
import Fieldset from 'primevue/fieldset'
import FileUpload from 'primevue/fileupload'
import FullCalendar from 'primevue/fullcalendar'
import InlineMessage from 'primevue/inlinemessage'
import Inplace from 'primevue/inplace'
import InputMask from 'primevue/inputmask'
import InputNumber from 'primevue/inputnumber'
import InputSwitch from 'primevue/inputswitch'
import InputText from 'primevue/inputtext'
import Knob from 'primevue/knob'
import Galleria from 'primevue/galleria'
import Listbox from 'primevue/listbox'
import MegaMenu from 'primevue/megamenu'
import Menu from 'primevue/menu'
import Menubar from 'primevue/menubar'
import Message from 'primevue/message'
import MultiSelect from 'primevue/multiselect'
import OrderList from 'primevue/orderlist'
import OrganizationChart from 'primevue/organizationchart'
import OverlayPanel from 'primevue/overlaypanel'
import Paginator from 'primevue/paginator'
import Panel from 'primevue/panel'
import PanelMenu from 'primevue/panelmenu'
import Password from 'primevue/password'
import PickList from 'primevue/picklist'
import ProgressBar from 'primevue/progressbar'
import Rating from 'primevue/rating'
import RadioButton from 'primevue/radiobutton'
import Ripple from 'primevue/ripple'
import SelectButton from 'primevue/selectbutton'
import ScrollPanel from 'primevue/scrollpanel'
import ScrollTop from 'primevue/scrolltop'
import Slider from 'primevue/slider'
import Sidebar from 'primevue/sidebar'
import Skeleton from 'primevue/skeleton'
import SplitButton from 'primevue/splitbutton'
import Splitter from 'primevue/splitter'
import SplitterPanel from 'primevue/splitterpanel'
import Steps from 'primevue/steps'
import TabMenu from 'primevue/tabmenu'
import Tag from 'primevue/tag'
import TieredMenu from 'primevue/tieredmenu'
import Textarea from 'primevue/textarea'
import Timeline from 'primevue/timeline'
import Toast from 'primevue/toast'
import ToastService from 'primevue/toastservice'
import Toolbar from 'primevue/toolbar'
import TabView from 'primevue/tabview'
import TabPanel from 'primevue/tabpanel'
import Tooltip from 'primevue/tooltip'
import ToggleButton from 'primevue/togglebutton'
import Tree from 'primevue/tree'
import TreeTable from 'primevue/treetable'
import TriStateCheckbox from 'primevue/tristatecheckbox'
import ConfirmationService from 'primevue/confirmationservice'
import CascadeSelect from 'primevue/cascadeselect'
import ProgressSpinner from 'primevue/progressspinner'
import BlockUI from 'primevue/blockui'
import TreeSelect from 'primevue/treeselect'

import permissionDirective from '@/permissionDirective';
import languageType from '@/assets/language/languageType.js'
import fromCheck from './formCheck'
import axiosHttp from './axios'
import vuexData from './vuexState'
import axios from 'axios';


axios.get('/config.json').then((config) => {
  console.log(JSON.parse(localStorage.getItem("menuList")))
  const service_url = config.data.service_url

  router.beforeEach(function (to, from, next) {
    window.scrollTo(0, 0)
    next()
  })

  const app = createApp(App)
  // app.use(i18n)
  app.config.globalProperties.$config = config.data
  app.config.globalProperties.$minioUrl = config.data.minio_url
  app.config.globalProperties.$appState = reactive({ inputStyle: 'outlined' })
  app.config.globalProperties.$getToken = function () {
    let userinfo = localStorage.getItem('userinfo')
    if (userinfo) {
      return JSON.parse(userinfo).token.token
    }
    return null
  }
  app.config.globalProperties.$getUser = function () {
    let userinfo = localStorage.getItem('userinfo')
    if (userinfo) {
      return JSON.parse(userinfo)
    }
    return null
  }
  app.config.globalProperties.$isAdmin = function () {
    let userinfo = localStorage.getItem('userinfo')
    if (userinfo) {
      return JSON.parse(userinfo).user.role == 'admin'
    }
    return false
  }
  app.use(ToastService)
  app.use(router)
  app.use(ConfirmationService)
  // app.use(setupI18n)
  app.config.globalProperties.$serverUrl = service_url
  app.config.globalProperties.$vuexData = vuexData
  app.config.globalProperties.$fromCheck = fromCheck
  app.config.globalProperties.$axios = axiosHttp
  app.config.globalProperties.$http = function (url, method, params, success, error) {
    let fullparams = {}

    // 添加token参数
    let userinfo = localStorage.getItem('userinfo')
    if (userinfo) {
      fullparams['token'] = JSON.parse(userinfo).token.token
    } else {
      // 如果用户没有登录，则跳转至登录页面
      if (url.indexOf('/login') === -1 && url.indexOf('/sendPassword') === -1 && url.indexOf('/sendSms') === -1) {
        this.$router.push({ path: '/loginMap' })
      }
    }
    Object.assign(fullparams, params)

    let options = {
      method: method,
      // url: this.$serverUrl + url
      url: url
    }
    if (method.toLowerCase() === 'get') {
      options.params = fullparams
    } else if (method.toLowerCase() === 'post') {
      options.data = fullparams
    }
    if (userinfo) {
      options.headers = { token: JSON.parse(userinfo).token.token }
    }

    axiosHttp(options).then(response => {
      success(response)
    }).catch(ev => {
      if (typeof error === 'function') {
        error(ev)
      }
    })

    // window['axios'](options).then(function(response) {
    //   if (response.data.success) {
    //     success(response.data)
    //   } else {
    //     // 如果开发者传入了error函数，则调用
    //     if (error) {
    //       error(response.data)
    //     } else {
    //       if (response.data.code == '387679') {
    //         // location.href = '/#/loginMap?tokenOver=true'
    //         location.href = '/#/login?tokenOver=true';
    //         localStorage.removeItem('userinfo')
    //       } else if (response.data.code == '969696') {
    //         // location.href = '/#/loginMap?jurisdiction=true'
    //         location.href = '/#/login?jurisdiction=true'
    //         localStorage.removeItem('userinfo')
    //       }
    //     }
    //   }
    // })
  }

  app.config.errorHandler = (error, vm) => {
    console.error('[抛出全局异常]')
    console.error(vm)
    console.error(error)
  }


  //console.log("main get language " + (localStorage.getItem('language') || 'zh-cn'))
  // const language = languageType[localStorage.getItem('language') || 'zh-cn']
  const language = languageType['zh-cn']
  app.use(PrimeVue, {
    language: language,
    ripple: true,
    locale: {
      language: language,
      startsWith: 'Starts with',
      contains: 'Contains',
      notContains: 'Not contains',
      endsWith: 'Ends with',
      equals: 'Equals',
      notEquals: 'Not equals',
      noFilter: 'No Filter',
      lt: 'Less than',
      lte: 'Less than or equal to',
      gt: 'Greater than',
      gte: 'Greater than or equal to',
      dateIs: 'Date is',
      dateIsNot: 'Date is not',
      dateBefore: 'Date is before',
      dateAfter: 'Date is after',
      clear: '清空',
      apply: 'Apply',
      matchAll: 'Match All',
      matchAny: 'Match Any',
      addRule: 'Add Rule',
      removeRule: 'Remove Rule',
      accept: 'Yes',
      reject: 'No',
      choose: 'Choose',
      upload: 'Upload',
      cancel: 'Cancel',
      dayNames: ['星期日', '星期一', '星期二', '星期三', '星期四', '星期五', '星期六'],
      dayNamesShort: ['日', '一', '二', '三', '四', '五', '六'],
      dayNamesMin: ['日', '一', '二', '三', '四', '五', '六'],
      monthNames: ['一月', '二月', '三月', '四月', '五月', '六月', '七月', '八月', '九月', '十月', '十一月', '十二月'],
      monthNamesShort: [
        '一月',
        '二月',
        '三月',
        '四月',
        '五月',
        '六月',
        '七月',
        '八月',
        '九月',
        '十月',
        '十一月',
        '十二月'
      ],
      today: '今天',
      weekHeader: 'Wk',
      firstDayOfWeek: 0,
      dateFormat: 'mm/dd/yy',
      weak: '简单的密码',
      medium: '中等难度密码',
      strong: '安全的密码',
      passwordPrompt: '请输入密码',
      emptyFilterMessage: '没有可用结果',
      emptyMessage: '没有可用选项'
    }
  })

  app.directive('permission', permissionDirective)

  app.directive('tooltip', Tooltip)
  app.directive('ripple', Ripple)
  app.directive('badge', BadgeDirective)

  app.component('Accordion', Accordion)
  app.component('AccordionTab', AccordionTab)
  app.component('AutoComplete', AutoComplete)
  app.component('Avatar', Avatar)
  app.component('AvatarGroup', AvatarGroup)
  app.component('Badge', Badge)
  app.component('Breadcrumb', Breadcrumb)
  app.component('Button', Button)
  app.component('Calendar', Calendar)
  app.component('Card', Card)
  app.component('Carousel', Carousel)
  app.component('Chart', Chart)
  app.component('Checkbox', Checkbox)
  app.component('Chip', Chip)
  app.component('Chips', Chips)
  app.component('ColorPicker', ColorPicker)
  app.component('Column', Column)
  app.component('ColumnGroup', ColumnGroup)
  app.component('ConfirmDialog', ConfirmDialog)
  app.component('ConfirmPopup', ConfirmPopup)
  app.component('ContextMenu', ContextMenu)
  app.component('DataTable', DataTable)
  app.component('DataView', DataView)
  app.component('DataViewLayoutOptions', DataViewLayoutOptions)
  app.component('Dialog', Dialog)
  app.component('Divider', Divider)
  app.component('Dropdown', Dropdown)
  app.component('Fieldset', Fieldset)
  app.component('FileUpload', FileUpload)
  app.component('FullCalendar', FullCalendar)
  app.component('InlineMessage', InlineMessage)
  app.component('Inplace', Inplace)
  app.component('InputMask', InputMask)
  app.component('InputNumber', InputNumber)
  app.component('InputSwitch', InputSwitch)
  app.component('InputText', InputText)
  app.component('Galleria', Galleria)
  app.component('Knob', Knob)
  app.component('Listbox', Listbox)
  app.component('MegaMenu', MegaMenu)
  app.component('Menu', Menu)
  app.component('Menubar', Menubar)
  app.component('Message', Message)
  app.component('MultiSelect', MultiSelect)
  app.component('OrderList', OrderList)
  app.component('OrganizationChart', OrganizationChart)
  app.component('OverlayPanel', OverlayPanel)
  app.component('Paginator', Paginator)
  app.component('Panel', Panel)
  app.component('PanelMenu', PanelMenu)
  app.component('Password', Password)
  app.component('PickList', PickList)
  app.component('ProgressBar', ProgressBar)
  app.component('RadioButton', RadioButton)
  app.component('Rating', Rating)
  app.component('SelectButton', SelectButton)
  app.component('ScrollPanel', ScrollPanel)
  app.component('ScrollTop', ScrollTop)
  app.component('Slider', Slider)
  app.component('Sidebar', Sidebar)
  app.component('Skeleton', Skeleton)
  app.component('SplitButton', SplitButton)
  app.component('Splitter', Splitter)
  app.component('SplitterPanel', SplitterPanel)
  app.component('Steps', Steps)
  app.component('TabMenu', TabMenu)
  app.component('TabView', TabView)
  app.component('TabPanel', TabPanel)
  app.component('Tag', Tag)
  app.component('Textarea', Textarea)
  app.component('TieredMenu', TieredMenu)
  app.component('Timeline', Timeline)
  app.component('Toast', Toast)
  app.component('Toolbar', Toolbar)
  app.component('ToggleButton', ToggleButton)
  app.component('Tree', Tree)
  app.component('TreeTable', TreeTable)
  app.component('TriStateCheckbox', TriStateCheckbox)
  app.component('Editor', Editor)
  app.component('CascadeSelect', CascadeSelect)
  app.component('ProgressSpinner', ProgressSpinner)
  app.component('BlockUI', BlockUI)
  app.component('TreeSelect', TreeSelect)
  app.mount('#app')
})

