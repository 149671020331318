<template>
  <div class="block-ui">
    <router-view></router-view>
    <!-- <div class="block-ui-content" v-if="loading">
      <ProgressSpinner class="loading-spinner" />
    </div> -->
  </div>
</template>

<script>
import emitter from './Mitt';

export default {
  data() {
    return {
      loading: false,
    }
  },
  created() {
    emitter.on('showLoading', (model) => {
      this.loading = true
    })
    emitter.on('hideLoading', (model) => {
      this.loading = false
    })
  },
  mounted() {
    let url = location.href;
    if (url.indexOf("loginMap1") == -1) {
      let userinfo = localStorage.getItem("userinfo");
      if (!userinfo || !JSON.parse(userinfo).token.token) {
        localStorage.removeItem('userinfo');
        this.$router.push("/loginMap");

      }
      //test
      // 在非功能页面时自动跳转到登录页面
      if (url.endsWith("/") || url.endsWith("#")) {
        this.$router.push("/loginMap");
      }
    }
  },
};
</script>
<style lang="scss">
html,
body,
#app {
  width: 100%;
  height: 100%;
}

.block-ui {
  position: relative;
  width: 100%;
  height: 100%;

  .block-ui-content {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(1, 1, 1, 0.4);
    z-index: 99999;

    .loading-spinner {
      position: fixed !important;
      left: 50vw;
      top: 50vh;
      transform: translate(-50%, -50%);
    }
  }
}



.p-inputtext:enabled:hover {
  border-color: #12a25d;
}

.p-inputtext:enabled:focus {
  box-shadow: none;
  border-color: #12a25d;
}

.p-button {
  background: #12a25d;
  border-color: #12a25d;
}

.p-button.p-button-outlined {
  border-color: #12a25d;
  color: #12a25d;
}

.p-button:enabled:hover {
  background: #12a25d;
  color: #ffffff;
  border-color: #12a25d;
}

.p-button:enabled:active {
  background: #12a25d;
  color: #ffffff;
  border-color: #12a25d;
}

.p-button:focus {
  box-shadow: none;
  border-color: #12a25d;
}

.p-button.p-button-outlined {
  color: #12a25d;
}

.p-button.p-button-text {
  color: #12a25d;
}

.p-button.p-button-link:enabled:focus {
  box-shadow: none;
  -webkit-box-shadow: none;
  border-color: transparent;
}

.p-inputtext {
  font-size: 14px;
}

.p-dropdown:not(.p-disabled):hover {
  border-color: #12a25d;
}

.p-dropdown:not(.p-disabled).p-focus {
  box-shadow: none;
  border-color: #12a25d;
}

.p-paginator-right-content {
  margin-left: 0 !important;
}

.p-paginator-right-content .p-field {
  margin-bottom: 0;
}

.p-radiobutton .p-radiobutton-box:not(.p-disabled):not(.p-highlight):hover {
  border-color: #12a25d;
}

.p-radiobutton .p-radiobutton-box.p-highlight:not(.p-disabled):hover {
  border-color: #12a25d;
  background: #12a25d;
  color: #ffffff;
}

.p-radiobutton .p-radiobutton-box:not(.p-disabled).p-focus {
  -webkit-box-shadow: none;
  box-shadow: none;
  border-color: #12a25d;
}

.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-focus {
  -webkit-box-shadow: none;
  box-shadow: none;
  border-color: #12a25d;
}

.p-field {
  margin-bottom: 32px;
}

.p-link:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
}

.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-highlight:hover {
  background-color: #12a25d;
  border-color: #12a25d;
}

.p-tree .p-tree-container .p-treenode .p-treenode-content.p-highlight {
  background-color: rgba(18, 162, 93, 0.5);
  border-color: rgba(18, 162, 93, 0.5);
}

.p-tree .p-tree-container .p-treenode .p-treenode-content:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
}

.p-checkbox .p-checkbox-box.p-highlight {
  background-color: #12a25d;
  border-color: #12a25d;
}

.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box:hover {
  border-color: #12a25d;
}

.p-multiselect:not(.p-disabled):hover {
  border-color: #12a25d;
}

.p-multiselect:not(.p-disabled).p-focus {
  box-shadow: none;
}

.p-toast .p-toast-message .p-toast-message-content .p-toast-detail {
  margin: 0 0 0 0.5rem;
}
</style>
