import axios from 'axios';
import emitter from './Mitt';

let http = axios.create({
    timeout: 60 * 1000,
});

axios.get('/config.json').then(config => {
    http.defaults.baseURL = config.data.service_url
})

let requestList = []

function showLoading(request) {
    if (requestList.length === 0) {
        emitter.emit("showLoading", null)
    }
    requestList.push(request.url)
}

function hideLoading(ev) {
    requestList = requestList.filter((item) => item !== ev.config.url)
    if (requestList.length === 0) {
        emitter.emit("hideLoading", null)
    }
}

const whiteUrl = ['login', 'sendPassword', 'sendSms', 'getLanguage']
http.interceptors.request.use((request) => {
    showLoading(request)
    const notToken = whiteUrl.some(item => request.url.includes(item))
    if (!notToken) {
        let userinfo = localStorage.getItem('userinfo');
        let token = userinfo !== undefined && userinfo !== null && userinfo !== 'null' && userinfo.trim() !== '' ? JSON.parse(userinfo).token.token : null;
        request.params = {
            token: token,
            ...request.params,
        };
        request.headers['token'] = token;
    }
    return request;
});


http.interceptors.response.use((response) => {
    hideLoading(response)

    if (response.data.success) {
        return response.data;
    } else {
        if (response.data.code === '387679' || response.data.code === '969696') {
            location.href = response.data.code === '387679' ? '/#/loginMap?tokenOver=true' : '/#/loginMap?jurisdiction=true'
            localStorage.removeItem('userinfo');
        } else {
            //console.log("---:" + response.data);

            return Promise.reject(response.data);
        }
    }
}, (e) => {
    console.log("http error:" + e.message)
    console.log(e.config)
    hideLoading(e)
    return Promise.reject(e);
});
export default http;


