export default {
  App: {},
  DeviceForm: {
    cancel: '取消',
    Aequipments: '添加设备',
    type: '设备类型',
    error: '错误',
    tips: '请输入设备编号',
    Detailed: '详细地点',
    Clear: '清除坐标',
    number: '设备编号',
    tips8: '请输入appKey',
    tips9: '请输入appSecret',
    Intelligent: '智能设备管理',
    JifeiS: 'Secret',
    AppKey: 'AppKey',
    JifeiP: '密码',
    Camera: '摄像头-莹石',
    Asuccessfully: '设备添加成功',
    coordinate: '设备坐标',
    JifeiEsn: '设备序列号',
    JifeiC: 'ClientId',
    tips4: '请输入设备坐标',
    JifeiA: '账号',
    tips5: '请输入设备地点',
    tips6: '请输入设备详细地点',
    tips7: '请输入设备序列号',
    AppSecret: 'AppSecret',
    tips1: '设备编号已存在',
    tips2: '请输入设备名称',
    establish: '创建',
    tips3: '请选择设备类型',
    confirm: '确定',
    tips15: '已清空所选位置',
    tips16: '请选择一个经纬度',
    tips13: '请输入clientId',
    Coordinate: '坐标设置',
    serial: '设备序列号',
    tips14: '请输入',
    tips11: '请输入密码',
    tips12: '请输入secret',
    succeed: '成功',
    name: '设备名称',
    tips10: '请输入账号',
    Camera2: '摄像头-极飞',
    information: '消息',
    position: '坐标位置',
    device: '设备管理',
    return: '返回',
    Location: '设备地点'
  },
  fertilizeRecordApp: {
    Operator: '操作人',
    classify: '分类',
    Tostage: '苗期追肥',
    Aphotos: '农事照片',
    Sfertilizer: '选择肥料',
    Toperiod: '分页期追肥',
    Nfertilizer: '肥料名称',
    remark: '备注',
    delete: '删除',
    tips: '请选择作业方式',
    dollar: '元',
    jostage: '拔节期追肥',
    tips8: '请填写费用',
    composition: '成分',
    tips9: '请输入费用',
    reap: '收割',
    define: '确定',
    Aufertilizer: '添加使用肥料',
    kilogram: '公斤',
    Fmethod: '施肥方式',
    area: '面积',
    cost: '费用',
    Rtime: '记录时间',
    tips4: '请输入面积',
    tips5: '厂家/供应商/渠道',
    tips6: '请选择肥料',
    tips7: '请输入肥料数量',
    tips1: '请选择施肥方式',
    tips2: '请选择记录时间',
    tips3: '请填写面积',
    Pharvest: '收割后管理',
    artificial: '人工',
    tips11: '请填写备注',
    acre: '亩',
    tips10: '请选择时间',
    Omode: '作业方式',
    machinery: '机械'
  },
  dataDictionary: {
    Ee: '条目编辑',
    cancel: '取消',
    New: '新建字典',
    code: '编码',
    remark: '备注',
    skip: '跳至',
    type: '数据字典类型',
    delete: '删除',
    tips: '共',
    compile: '编辑',
    skip1: '页',
    skip2: '按下回车键后跳转',
    Dd: '字典删除',
    De: '字典编辑',
    Added: '新增',
    successfully: '删除成功',
    lose: '失败',
    tips4: '吗',
    tips5: '删除类型存在字典，不能删除',
    tips1: '条记录',
    Nodata: '无数据',
    tips2: '此节点存在子节点不能删除！',
    tips3: '您确定要删除',
    wrong: '选择错误',
    affirm: '确认',
    entry: '新增条目',
    system: '系统管理',
    dictionary: '数据字典',
    succeed: '成功',
    name: '名称',
    attention: '注意',
    choice: '请选择',
    operation: '操作',
    Ed: '条目删除'
  },
  Project2: {
    cancel: '取消',
    examine: '查看',
    ptype: '项目类型',
    skip: '跳至',
    error: '错误',
    delete: '删除',
    tips: '请输入项目名称',
    compile: '编辑',
    skip1: '页',
    tips8: '确认要删除所选的项目吗',
    tips9: '所选项目删除成功',
    gleader: '组长',
    Nfound: '没有发现数据',
    coordinate: '坐标',
    plocation: '项目地点',
    pname: '项目名称',
    proposes: '专家建议',
    Belonging: '所属用户',
    tips4: '按下回车键后跳转',
    tips5: '进行中的项目',
    tips6: '请选择待归档的项目',
    tips7: '请选择待删除的项目',
    tips1: '请输入小组人员名称',
    tips2: '共',
    tips3: '页',
    target: '目标',
    affirm: '确认',
    Planing: '种植项目',
    inumber: '项目编号',
    pmember: '项目成员',
    succeed: '成功',
    pigeonhole: '归档',
    operation: '操作',
    pm: '项目管理'
  },
  addTreeNode: {
    cancel: '取消',
    classify: '分类',
    compile: '编辑',
    serial: '编号',
    name: '名称',
    save: '保存',
    language: '语言',
    tips1: '请输入编号',
    tips: '请输入名称'
  },
  Project1: {
    leader: '组长',
    coordinate: '坐标',
    plocation: '项目地点',
    examine: '查看',
    pname: '项目名称',
    ptype: '项目类型',
    skip: '跳至',
    pnumber: '项目编号',
    quarterly: '按季度',
    tips1: '共',
    tips: '请输入项目名称',
    tips2: '条',
    target: '目标',
    Archived: '归档的项目',
    pop: '种植项目',
    skip1: '页',
    pmember: '项目成员',
    else: '其他',
    Archiving: '归档类型',
    yearly: '按年',
    operation: '操作',
    pm: '项目管理',
    Nfound: '没有发现数据',
    Press: '按下回车键后跳转'
  },
  addSuggest: {
    cancel: '取消',
    Asuccessfully: '添加成功',
    submit: '提交',
    suggestion: '新增建议',
    enter: '请输入',
    tips: '不能提交空建议'
  },
  WaFRecordApp: {
    Operator: '操作人',
    wconsumption: '用水量',
    classify: '分类',
    Aphotos: '农事照片',
    Sfertilizer: '选择肥料',
    Nfertilizer: '肥料名称',
    remark: '备注',
    delete: '删除',
    tips: '请选择作业方式',
    dollar: '元',
    ton: '吨',
    tips8: '请输入费用',
    composition: '成分',
    tips9: '请填写备注',
    define: '确定',
    Manufacturer: '厂家/供应商/渠道',
    kilogram: '公斤',
    area: '面积',
    cost: '费用',
    Rtime: '记录时间',
    tips4: '请输入用水量',
    tips5: '请选择肥料',
    tips6: '请输入肥料数量',
    manufactor: '厂家',
    Fmanure: '农家肥',
    tips7: '请填写费用',
    tips1: '请填写面积',
    tips2: '请输入面积',
    tips3: '请填写用水量',
    artificial: '人工',
    acre: '亩',
    tips10: '请选择时间',
    Omode: '作业方式',
    machinery: '机械'
  },
  enclosure: {
    counting: '开始打点',
    area: '区域面积',
    average: '年平均气温',
    rain: '年度下雨天数',
    foggy: '年度起雾天数',
    rainfall: '年度降雨量',
    wait: '稍等',
    tornado: '年度龙卷风天数',
    save: '保存',
    Senclosure: '开始圈地',
    Click: '点击"开始圈地"后，打开进行圈地操作（双击可结束圈地）',
    hail: '年度冰雹天数',
    square: '平方米',
    search: '输入名称以后搜索',
    Atemperature: '年平均最低气温',
    snow: '年度下雪天数',
    weather: '加载天气数据中',
    acre: '亩',
    maximum: '年平均最高气温',
    thunderstorm: '年度雷阵雨天数'
  },
  recordDetailsApp: {
    Lelen: '叶长',
    cuttage: '扦插',
    Nsosp: '单株节间距',
    Gpr: '整地记录',
    Opmode: '作业方式',
    Gp: '草地准备',
    MeasurNo: '测量编号',
    Chp: '通道规划',
    Tass: '苗期追肥',
    Gr: '生长记录',
    Cp: '扦插种植',
    output: '产量',
    number: '编号',
    Trmode: '截断方式',
    Noyl: '黄叶数',
    Wafir: '水肥一体化记录',
    Nonpp: '单株节数',
    Pc: '准备内容',
    Sdpp: '单株茎直径',
    Plmode: '种植方式',
    reap: '收割',
    kilogram: '公斤',
    Pm: '植株测量',
    Lpreparation: '土地准备',
    Tr: '分蘖记录',
    area: '面积',
    Fsp: '平地条栽',
    Pr: '种植记录',
    Nogl: '绿叶数',
    ftgws: '补苗',
    Rod: '除虫记录',
    Tof: '农事类型',
    Hr: '收割记录',
    lowest: '最低',
    Tajs: '拔节期追肥',
    highest: '最高',
    Ec: '专家评论',
    machinery: '机械',
    Operator: '操作人',
    Ser: '出苗记录',
    average: '平均',
    altitude: '高度',
    Abf: '施用底肥',
    Wepp: '单株重',
    Lewid: '叶宽',
    Ir: '灌溉记录',
    dollar: '元',
    Ar: '农事记录',
    wconsump: '用水量',
    ton: '吨',
    Phm: '收割后管理',
    Srat: '育苗移栽',
    Tillage: '翻耕(旋耕)',
    root: '根',
    Lirrigation: '后期灌溉',
    Emrate: '出苗率',
    Inph: '单株高',
    Rs: '记录选择',
    DDc: '挖排涝渠',
    Rt: '记录时间',
    cost: '费用',
    quantity: '数量',
    Rw: '清除杂草',
    Fm: '施肥方式',
    Jr: '拔节记录',
    Cweight: '丛重',
    Fp: '农事照片',
    Fr: '施肥记录',
    irmethod: '灌溉方式',
    Sc: '发送评论',
    pa: '种植面积',
    Ssp: '坡地条栽',
    artificial: '人工',
    Firrigation: '首次灌溉',
    Sf: '选择肥料',
    PC: '请发表评论',
    Pmr: '测产记录',
    Si: '选择杀虫剂',
    acre: '亩',
    Tnumber: '分蘖数',
    Tils: '分叶期追肥',
    remarks: '备注',
    Stplant: '条栽'
  },
  addLanguageContent: {
    cancel: '取消',
    New: '新增词条',
    terms: '编辑词条',
    name: '词条名称',
    save: '保存',
    attention: '注意',
    tips1: '请输入词条内容',
    tips: '请输入词条名称',
    content: '词条内容'
  },
  weather: {
    average: '平均温度',
    rain: '下雨总天数',
    rainfall: '总降雨量',
    Load: '加载天气数据中',
    wait: '稍等',
    Monthly: '月历史气象记录',
    visibility: '平均可见度',
    Maximum: '最大可持续风速',
    medial: '平均湿度',
    failure: '获取数据失败',
    temperature: '最高温度',
    minimum: '最低温度',
    day: '天'
  },
  Compare: {
    pt: '共',
    project: '项目',
    skip: '跳至',
    DT: '设备类型',
    Ndf: '没有发现数据',
    Csc: '同一图表对比',
    Dsd: '不同类型的设备不具备可比性，请重新选择',
    tiao: '条',
    press: '按下回车键后跳转',
    Da: '数据分析',
    coordinate: '坐标',
    Pu: '收起',
    Psi: '请选择项目',
    query: '查询',
    EN: '设备编号',
    Sc: '分图表对比',
    Dt: '设备类型',
    Pop: '种植项目',
    Se: '选择错误',
    PC: '请选择',
    reset: '重置',
    devicename: '设备名称',
    location: '地点',
    page: '页',
    TQ: '时间段',
    PN: '项目名称',
    unfold: '展开'
  },
  distribuirExport: {
    planning: '种植规划',
    allocation: '分配',
    select: '选择专家',
    specialist: '分配专家',
    Distribution: '分配成功',
    name: '项目名称',
    list: '列表',
    tips: '请选择专家'
  },
  ProjectForm2: {
    cancel: '取消',
    oinformation: '其他信息',
    Mfungus: '菌料',
    Address: '详细地点',
    tips: '请选择项目编号',
    Pvalue: 'PH值',
    desertification: '荒漠化',
    Eamount: '请输入正确或完整的金额',
    NMaterials: '新型板材',
    Wetland: '湿地(草甸/沼泽地)',
    restoration: '矿山修复',
    biofuel: '生物燃料',
    nrainfall: '自然降雨',
    Land: '整地条件',
    fodder: '饲料',
    Pop: '种植项目',
    Ecological: '生态治理目标',
    eitem: '编辑项目',
    breeding: '育种',
    Esoil: '请输入土壤属性',
    save: '保存',
    Ppi: '规划地块信息',
    Eland: '请输入整地条件',
    dollar: '元/亩',
    remediation: '土壤修复',
    ton: '吨/亩',
    flat: '平地',
    terrace: '梯田',
    tips4: '请输入项目坐标',
    tips5: '提示：省/自治区、市、区/县的命名应使用简称，如内蒙古自治区简称内蒙古，阿拉善盟简称阿拉善',
    tips6: '请输入项目地点',
    tips7: '请输入详细地点',
    tips1: '请输入项目名称',
    Ugrass: '种植菌草用途',
    tips2: '请选择项目类型',
    confirm: '确定',
    fixation: '防风固沙',
    bsoil: '黑土',
    return: '返回',
    bed: '猪饲料-发酵床',
    Psuccessfully: '项目添加成功',
    country: '国家',
    penter: '请输入',
    Fgovernance: '用于生态治理',
    pobjective: '项目目标',
    irrigation: '灌溉条件',
    tips8: '请输入项目目标',
    krasnozem: '红壤',
    Vitems: '查看项目',
    tips9: '请输入亩产',
    Econditions: '请输入灌溉条件',
    Ssoil: '沙壤地',
    Rmature: '菌草成熟后再利用',
    sirrigation: '喷灌',
    create: '创建',
    fmaterial: '纤维材料',
    Less: '小于25度坡地',
    longitude: '请选择一个经纬度',
    area: '种植面积',
    coordinate: '项目坐标',
    town: '市',
    pname: '项目名称',
    amend: '修改',
    pnumber: '项目编号',
    Province: '省/洲/自治区',
    dike: '护坡固堤',
    district: '区/县',
    effect: '实验效果',
    tips10: '请输入种植面积',
    position: '坐标位置',
    pcycle: '项目周期',
    tirrigation: '滴灌',
    Pmanagement: '生产田间管理',
    cleared: '已清空所选位置',
    no: '否',
    Iirrigation: '浸灌',
    production: '生产',
    scientific: '科研',
    Premarks: '请输入备注',
    Ccoordinates: '清除坐标',
    ptype: '项目类型',
    remark: '备注',
    error: '错误',
    Processing: '菌草成熟收获后的加工目标',
    Sproperty: '土壤属性',
    Electrical: '电导率(EC)',
    sak: '盐碱地',
    Smushroom: '种菇',
    Csetting: '坐标设置',
    amount: '请输入金额',
    pyield: '亩产',
    wals: '水土流失',
    yes: '是',
    saltness: '含盐量',
    message: '消息',
    Roverwintering: '根系越冬',
    emonitoring: '环境监测',
    stony: '石漠化',
    succeed: '成功',
    oproject: '进行中的项目',
    acre: '亩',
    Duplicate: '项目编号重复',
    location: '项目地点',
    PM: '项目管理'
  },
  growRecord: {
    cancel: '取消',
    average: '平均',
    LineSpacing: '行间距',
    roe: '出苗率',
    section: '节',
    auxograph: '生长记录',
    measurement: '植株测量',
    tiller: '分蘖数',
    tips: '您确定要删除这条',
    Plant: '株间距',
    diameter: '单株茎直径',
    Number: '单株节数',
    leaves: '绿叶数',
    record: '记录',
    Emergence: '出苗记录',
    root: '根',
    Height: '单株高',
    leaves1: '个',
    leaves2: '黄叶数',
    height: '高度',
    Tillering: '分蘖记录',
    Jointing: '拔节记录',
    Nr: '暂无记录',
    individual: '单株节间距',
    Recommended: '推荐传感器',
    Bundle: '丛重',
    proposes: '专家建议',
    gram: '克',
    Agricultural: '农事记录',
    Lw: '叶宽',
    leaf: '叶长',
    lowest: '最低',
    tips1: '吗',
    Weight: '单株重',
    Optimum: '最佳种植时间',
    confirm: '确定',
    Survey: '测量编号',
    depth: '沟深（穴深）',
    month: '月份',
    piece: '台',
    highest: '最高',
    Dtd: '删除本条数据',
    width: '沟宽（穴直径）',
    Dsuccessfully: '删除成功',
    commentary: '专家评论'
  },
  addComment: {
    cancel: '取消',
    Asuccessfully: '添加成功',
    submit: '提交',
    enter: '请输入',
    Acomment: '新增评论',
    tips: '不能提交空评论'
  },
  plan: {
    cancel: '取消',
    Overall: '整体条件',
    Modification: '修改专家',
    Ndata: '暂无数据',
    Fill: '填写建议',
    import: '导入项目',
    advice: '专家建议中',
    Imported: '已导入',
    skip: '跳至',
    delete: '删除',
    tips: '共',
    irrigation: '灌溉条件',
    compile: '编辑',
    skip1: '页',
    Create: '创建新规划',
    land: '土地属性',
    phase: '项目阶段',
    all: '全部',
    area: '项目面积',
    completion: '专家完成建议',
    tips4: '吗',
    View: '查看项目',
    tips1: '条记录',
    tips2: '按下回车键后跳转',
    recommended: '未建议',
    tips3: '确定要删除',
    affirm: '确认',
    planning: '种植规划',
    Sdelete: '删除成功',
    Iproject: '已导入项目',
    Distribution: '分配专家',
    creating: '创建中',
    assigned: '待分配专家',
    suggestions: '查看建议',
    under: '规划中',
    Editorial: '编辑建议'
  },
  ProjectForm1: {
    bed: '猪饲料-发酵床',
    oinformation: '其他信息',
    country: '国家',
    tips: '请选择项目类型',
    pobjective: '项目目标',
    desertification: '荒漠化',
    serosion: '水土流失',
    sremediation: '土壤修复',
    krasnozem: '红壤',
    NMaterials: '新型板材',
    Ssoil: '沙壤地',
    else: '其他',
    sirrigation: '喷灌',
    fmaterial: '纤维材料',
    Pcoordinate: '项目坐标',
    Less: '小于25度坡地',
    Wetland: '湿地(草甸/沼泽地)',
    restoration: '矿山修复',
    ecological: '用于生态治理',
    Root: '根系越冬',
    biofuel: '生物燃料',
    pname: '项目名称',
    pnumber: '项目编号',
    monitoring: '环境监测',
    fodder: '饲料',
    iconditions: '灌溉条件',
    POP: '种植项目',
    Atype: '归档类型',
    effect: '实验效果',
    Lcondition: '整地条件',
    Rhizobiaceae: '菌料',
    pcycle: '项目周期',
    tirrigation: '滴灌',
    Pmanagement: '生产田间管理',
    wind: '防风固沙',
    breeding: '育种',
    no: '否',
    sproperty: '土壤属性',
    Iirrigation: '浸灌',
    production: '生产',
    scientific: '科研',
    range: 'pH值',
    Saline: '盐碱地',
    ptype: '项目类型',
    ylut: '亩产',
    remark: '备注',
    quarterly: '按季度',
    Detailed: '详细地点',
    Slope: '护坡固堤',
    dollar: '元/亩',
    carea: '种植面积',
    ' yearly': '按年',
    flat: '平地',
    terrace: '梯田',
    Electrical: '电导率(EC)',
    Smushroom: '种菇',
    rainfall: '自然降雨',
    plocation: '项目地点',
    yes: '是',
    saltness: '含盐量',
    tips4: '种植菌草用途',
    tips1: '请输入金额',
    tips2: '菌草成熟后再利用',
    tips3: '菌草成熟收获后的加工目标',
    stony: '石漠化',
    Aitems: '归档的项目',
    acre: '亩',
    objectives: '生态治理目标',
    information: '规划地块信息',
    bsoil: '黑土',
    PM: '项目管理',
    return: '返回'
  },
  TabHomeApp: {
    Eguidance: '专家指导',
    Np: '暂无项目',
    wttsfcp: '欢迎来到智慧菌草云平台',
    Lari: '最新记录项目',
    Noi: '项目数量',
    Prdeta: '项目详情',
    Nnm: '暂无新消息',
    Archived: '已归档',
    Mitems: '更多项目',
    Ar: '农事记录',
    Nrecord: '暂无记录',
    Loading: '加载中',
    Nfr: '暂无农事记录',
    Agrecords: '农事记录',
    Nnews: '新消息'
  },
  assRecord: {
    area: '面积',
    cancel: '取消',
    LineSpacing: '行间距',
    proposes: '专家建议',
    Agricultural: '农事记录',
    NoRecord: '暂无记录',
    delete: '删除本条数据',
    measurement: '测产记录',
    tips: '您确定要删除这条"测产记录"吗',
    Optimum: '最佳种植时间',
    confirm: '确定',
    ton: '吨',
    spacing: '株间距',
    depth: '沟深（穴深）',
    month: '月份',
    record: '记录',
    yield: '产量',
    width: '沟宽（穴直径）',
    Dsuccessfully: '删除成功',
    acre: '亩',
    sensor: '推荐传感器',
    stand: '台',
    commentary: '专家评论'
  },
  unnetworkRecordsApp: {
    cancel: '取消',
    Ss: '发送成功',
    Lelen: '叶长',
    joint: '节',
    upload: '上传',
    Nsosp: '单株节间距',
    Gpr: '整地记录',
    Opmode: '作业方式',
    MeasurNo: '测量编号',
    Chp: '通道规划',
    Cp: '扦插种植',
    Plmeasure: '植株测量',
    tips: '设备离线，请联网后及时上传数据',
    Trmode: '截断方式',
    Noyl: '黄叶数',
    Wafir: '水肥一体化记录',
    Nonpp: '单株节数',
    Pc: '准备内容',
    price: '价格',
    Sdpp: '单株茎直径',
    Hrecord: '收割记录',
    Lpreparation: '土地准备',
    Fetype: '肥料类型',
    area: '面积',
    Fsp: '平地条栽',
    Nogl: '绿叶数',
    Rod: '除虫记录',
    Plrecord: '种植记录',
    lowest: '最低',
    Tirecord: '分蘖记录',
    highest: '最高',
    Irecord: '灌溉记录',
    Agrecords: '农事记录',
    machinery: '机械',
    Ser: '出苗记录',
    average: '平均',
    Tcr: '缓存请求共',
    Abf: '施用底肥',
    Wepp: '单株重',
    Lewid: '叶宽',
    dollar: '元',
    wconsump: '用水量',
    ton: '吨',
    Loading: '加载中',
    Srat: '育苗移栽',
    Tillage: '翻耕(旋耕)',
    root: '根',
    yield: '产量',
    Lirrigation: '后期灌溉',
    Emrate: '出苗率',
    Inph: '单株高',
    DDc: '挖排涝渠',
    cost: '费用',
    cf: '化肥',
    Rw: '清除杂草',
    Gsp: '草种准备',
    Gss: '草种选择',
    Fm: '农家肥',
    gram: '克',
    tips4: '请输入搜索项目名称',
    Arecord: '全部记录',
    Gst: '草种截断',
    Cweight: '丛重',
    Fr: '施肥记录',
    tips1: '确认上传本地记录',
    irmethod: '灌溉方式',
    tips2: '请输入项目名称',
    tips3: '网络已连接，确认上传本地记录',
    Odata: '离线数据',
    Ssp: '坡地条栽',
    artificial: '人工',
    Firrigation: '首次灌溉',
    Nrecord: '暂无记录',
    Pmr: '测产记录',
    vernalization: '催芽',
    Grecord: '生长记录',
    acre: '亩',
    synchronization: '同步',
    Tnumber: '分蘖数'
  },
  TabsPageApp: {
    cancel: '取消',
    exit: '退出',
    item: '项目',
    record: '记录',
    hp: '首页',
    my: '我的',
    tips: '是否退出菌草云平台？'
  },
  addOrUpdateFer: {
    cancel: '取消',
    Add: '添加成功',
    Added: '新增肥料',
    save: '保存',
    type: '肥料类型',
    tips1: '请输入类型',
    tips: '请输入名称',
    tips2: '请输入厂商',
    manufacturer: '肥料厂家',
    tips3: '请输入成分',
    modify: '修改成功',
    names: '肥料名称',
    compile: '编辑肥料',
    element: '肥料成分'
  },
  chatWindow: {
    Ii: '项目信息',
    Ar: '农事记录',
    read: '已读',
    specialist: '专家',
    unread: '未读',
    EP: '专家建议',
    send: '发送',
    ML: '消息列表'
  },
  resetPwdApp: {
    Np: '新密码',
    Cnp: '确认新密码',
    Cop: '确认密码',
    Ms: '修改成功',
    tips4: '两次密码不一致',
    CP: '修改密码',
    Cp: '当前密码',
    tips1: '请输入新密码（6-16位字符）',
    tips: '请输入当前密码',
    tips2: '请输入新密码',
    tips3: '密码格式错误',
    Pe: '密码错误',
    define: '确定',
    Mf: '修改失败'
  },
  projectDetailsApp: {
    St: '传感器类型',
    Gpr: '整地记录',
    feg: '用于生态治理',
    Cm: '栽培方法',
    Lfe: '劳动力预估',
    Gr: '生长记录',
    Td: '沟深(穴深)',
    Dsrs: '部署传感器建议',
    number: '编号',
    OPT: '最佳种植时间',
    Pd: '项目详情',
    conductivity: '电导率',
    Pl: '项目地点',
    Da: '详细地址',
    sflp: '整地建议',
    Pr: '种植记录',
    Tw: '沟宽(穴直径)',
    Pt: '项目类型',
    Ratf: '菌草成熟后再利用',
    Hr: '收割记录',
    manufactor: '厂家',
    Nos: '种苗数量',
    month: '月份',
    specialist: '专家',
    Ic: '灌溉条件',
    Ea: '专家建议',
    Lpc: '整地条件',
    Ee: '实验效果',
    Pcs: '项目坐标',
    no: '否',
    Mn: '机器名称',
    NOf: '肥料名称',
    Mt: '机器类型',
    Ar: '全部记录',
    Rf: '推荐肥料',
    Loading: '加载中...',
    ypua: '亩产',
    SS: '选择服务',
    Rs: '行间距',
    sas: '盐碱地',
    yes: '是',
    Fm: '肥料厂家',
    saltness: '含盐量',
    Ego: '生态治理目标',
    Fs: '菌草品种',
    Pea: '人/亩',
    Ft: '肥料类型',
    target: '目标',
    pa: '种植面积',
    Bs: '珠间距',
    pc: '项目周期',
    Ptar: '菌草成熟收货后的加工目标',
    Pmr: '测产记录',
    PH: 'PH值',
    Sm: '传感器厂家',
    Sn: '传感器名称',
    Sp: '土壤属性',
    remarks: '备注'
  },
  showMap: { area: '区域面积', acre: '亩', modification: '修改' },
  talkApp: {
    Unread: '未读',
    Read: '已读',
    cancel: '取消',
    Eg: '专家指导',
    tap: '拍照',
    Loading: '加载中...',
    specialist: '专家',
    tbr: '发送',
    SP: '选择图片',
    tips: '请选择上传方式'
  },
  plantRecord: {
    cancel: '取消',
    Irrigation: '灌溉记录',
    LineSpacing: '行间距',
    Deworming: '除虫记录',
    manure: '肥料',
    cuttage: '扦插',
    NoRecord: '暂无记录',
    pattern: '种植方式',
    tips: '您确定要删除这条',
    dollar: '元',
    number: '数量',
    ' Spec': '棵',
    Plant: '株间距',
    ton: '吨',
    kilo: '公斤',
    irrigation: '灌溉方式',
    record: '记录',
    Primary: '首次灌溉',
    ftws: '补苗',
    stand: '台',
    irofwaf: '水肥一体化记录',
    area: '面积',
    Late: '后期灌溉',
    cost: '费用',
    Fertilization: '施肥记录',
    pesticide: '农药',
    proposes: '专家建议',
    Agricultural: '农事记录',
    water: '用水量',
    tips1: '吗',
    seedling: '育苗移栽',
    tips2: '"',
    tips3: '删除本条数据',
    Optimum: '最佳种植时间',
    Strip: '条栽',
    confirm: '确定',
    depth: '沟深（穴深）',
    month: '月份',
    width: '沟宽（穴直径）',
    acre: '亩',
    Dsuccessfully: '删除成功',
    sensor: '推荐传感器',
    Planting: '种植记录',
    commentary: '专家评论'
  },
  UserSetting: {
    cancel: '取消',
    mistake: '错误',
    successfully: '所属用户设置成功',
    aname: '用户姓名',
    skip: '跳至',
    team: '所属团队',
    speople: '您没有选择人员',
    toble1: '条',
    Ejump: '按下回车键后跳转',
    tips: '请输入用户姓名',
    crew: '组员',
    confirm: '确定',
    Sleader: '设为组长',
    Pnumber: '手机号码',
    Tmanager: '团队管理员',
    skip1: '页',
    person: '人',
    toble: '共',
    succeed: '成功',
    Nleader: '无组长',
    gleader: '组长',
    cleader: '请选择一名组长',
    Nfound: '没有发现数据。'
  },
  jfPlayVideo: { failure: '视频播放失败', Successful: '视频播放成功' },
  plantRecordApp: {
    Operator: '操作人',
    Aphotos: '农事照片',
    quantity: '数量',
    cost: '费用',
    cuttage: '扦插',
    ftgws: '补苗',
    Rtime: '记录时间',
    tips4: '请输入数量',
    Pmode: '种植方式',
    tips5: '请填写费用',
    tips6: '请输入费用',
    remark: '备注',
    tips7: '请填写备注',
    tips1: '请选择种植方式',
    tips: '请选择作业方式',
    tips2: '请选择记录时间',
    tips3: '请填写数量',
    Strip: '条栽',
    artificial: '人工',
    tips8: '请选择时间',
    Srat: '育苗移栽',
    define: '确定',
    Omode: '作业方式',
    machinery: '机械'
  },
  deworming: {
    cancel: '取消',
    Asuccess: '新增成功',
    submit: '提交',
    irrigate: '灌溉',
    mechanical: '机械',
    insecticide: '选择杀虫剂',
    remark: '备注',
    delete: '删除',
    tips: '请选择作业方式',
    operator: '操作人',
    dollar: '元',
    view: '查看数据',
    kilo: '公斤',
    farming: '农事类型',
    operating: '作业方式',
    write: '记录时间',
    nonuse: '不使用',
    area: '面积',
    grow: '种植',
    cost: '费用',
    fertilization: '施肥',
    manufacturers: '厂家',
    tips4: '请选择杀虫剂',
    tips5: '请输入费用',
    tips1: '请选择录入时间',
    tips2: '请输入面积',
    tips3: '请输入公斤数',
    Project: '种植项目',
    artificial: '人工',
    photograph: '农事照片',
    deinsectization: '除虫',
    guidance: '农事记录与指导',
    acre: '亩',
    ioaf: '水肥一体化',
    Ainsecticide: '添加使用杀虫剂',
    return: '返回',
    Planting: '种植记录'
  },
  plant: {
    cancel: '取消',
    Asuccess: '新增成功',
    submit: '提交',
    cuttage: '扦插',
    irrigate: '灌溉',
    mechanical: '机械',
    project: '种植项目',
    ftgs: '补苗',
    remark: '备注',
    tips: '请选择作业方式',
    operator: '操作人',
    dollar: '元',
    view: '查看数据',
    farming: '农事类型',
    operating: '作业方式',
    write: '记录时间',
    grow: '种植',
    quantity: '数量',
    cost: '费用',
    fertilization: '施肥',
    tips4: '请输入费用',
    drill: '条栽',
    tips1: '请选择种植方式',
    seedling: '育苗移栽',
    tips2: '请选择记录时间',
    tips3: '请输入数量',
    artificial: '人工',
    photograph: '农事照片',
    deinsectization: '除虫',
    guidance: '农事记录与指导',
    planting: '种植方式',
    ioaf: '水肥一体化',
    Spec: '棵',
    return: '返回',
    Planting: '种植记录'
  },
  integration: {
    cancel: '取消',
    Asuccess: '新增成功',
    submit: '提交',
    farmyard: '农家肥',
    irrigate: '灌溉',
    mechanical: '机械',
    remark: '备注',
    delete: '删除',
    tips: '请选择作业方式',
    operator: '操作人',
    dollar: '元',
    ton: '吨',
    view: '查看数据',
    kilo: '公斤',
    farming: '农事类型',
    operating: '作业方式',
    write: '记录时间',
    area: '面积',
    grow: '种植',
    cost: '费用',
    fertilization: '施肥',
    manufacturers: '厂家',
    tips4: '请输入公斤数',
    tips5: '请输入费用',
    tips1: '请选择录入时间',
    tips2: '请输入面积',
    fertilizer: '选择肥料',
    tips3: '请输入用水量',
    volume: '用水量',
    Project: '种植项目',
    artificial: '人工',
    photograph: '农事照片',
    deinsectization: '除虫',
    guidance: '农事记录与指导',
    acre: '亩',
    ioaf: '水肥一体化',
    return: '返回',
    Planting: '种植记录',
    Afertilizer: '添加使用肥料'
  },
  Split: {
    average: '平均值',
    number: '编号',
    total: '总和',
    download: '下载',
    Pc: '请选择',
    Nd: '节点展示',
    Lc: '正在加载图表...',
    parameter: '参数',
    Tq: '时间段',
    Da: '数据分析',
    all: '全部',
    cfc: '采集通道',
    Pt: '指标类型',
    query: '查询',
    Dn: '展示节点',
    Pop: '种植项目',
    Sc: '分图表对比',
    Sd: '总和展示',
    ND: '无数据',
    reset: '重置',
    maximum: '最大值',
    time: '时间',
    Ie: '输入错误',
    conditions: '请将条件输入完整',
    minimum: '最小值',
    return: '返回'
  },
  addOrUpdateInsecticide: {
    Add: '新增农药',
    cancel: '取消',
    modify: '修改成功',
    compile: '编辑农药',
    success: '添加成功',
    name: '肥料名称',
    save: '保存',
    tips1: '请输入厂家',
    tips: '请输入名称',
    manufacturer: '肥料厂家'
  },
  landPrepareApp: {
    Operator: '操作人',
    Ddrainage: '挖排涝渠',
    cost: '费用',
    Fphotos: '农事照片',
    tips4: '请填写备注',
    tips5: '请选择时间',
    remark: '备注',
    Operation: '作业方式',
    tips1: '请选择记录时间',
    tips: '请选择作业方式',
    tips2: '请填写费用',
    dollar: '元',
    tips3: '请输入费用',
    artificial: '人工',
    Cplanning: '通道规划',
    Tillage: '翻耕(旋耕)',
    define: '确定',
    Pcontent: '准备内容',
    Rweeds: '清除杂草',
    write: '记录时间',
    machinery: '机械'
  },
  addOrUpRole: { cancel: '取消', Amenu: '添加分配菜单', save: '保存' },
  One: {
    Ac: '采集通道',
    Pt: '指标类型',
    query: '查询',
    Mv: '最小值',
    Cr: '图表结果',
    Pop: '种植项目',
    Pecc: '请将条件输入完整',
    download: '下载',
    Pc: '请选择',
    Av: '平均值',
    Coc: '同一图表对比',
    Lc: '正在加载图表...',
    parameter: '参数',
    reset: '重置',
    maximum: '最大值',
    time: '时间',
    Ie: '输入错误',
    Tq: '时间段',
    Da: '数据分析',
    return: '返回'
  },
  login: {
    welcomeLogin: '欢迎来到智慧菌草云平台，请登录！',
    summary: '无权访问',
    determine: '确定',
    year: '年度',
    pwdTips: '请输入密码',
    Ulog: '无法登录！',
    loginPlatform: '智慧菌草云平台',
    login: '登录',
    warn: '警告',
    forgotPassword: '忘记密码',
    telTips: '请输入手机号',
    contactAdministrator: '手机号未注册或密码错误，请联系管理员~',
    usernameTips: '请输入用户名',
    telWrong: '手机号码为空或格式错误！',
    detail: '令牌无效或已过期，因此暂无法访问该功能，您需要重新登录！'
  },
  addOrUpdateTreeNode: {
    cancel: '取消',
    successfully: '类型添加成功!',
    submit: '提交',
    father: '父节点',
    remark: '备注',
    tips1: '请输入名称',
    tips: '请选择父节点',
    tips2: '请输入编号',
    editing: '编辑类型',
    modify: '修改成功!',
    serial: '编号',
    succeed: '成功',
    name: '名称',
    Newtype: '新增类型'
  },
  jointingRecordApp: {
    Fphotos: '农事照片',
    define: '确定',
    tips4: '请选择时间',
    remark: '备注',
    write: '记录时间',
    tips1: '请填写高度',
    tips: '请选择记录时间',
    operator: '操作人',
    tips2: '请输入高度',
    height: '高度',
    tips3: '请填写备注'
  },
  FarmingRecordApp: {
    Pmrecord: '测产记录',
    cuttage: '扦插',
    Nsosp: '单株节间距',
    tips: '请输入项目名',
    Trmode: '截断方式',
    Noyl: '黄叶数',
    Nonpp: '单株节数',
    Lwidth: '叶宽',
    price: '价格',
    Sdpp: '单株茎直径',
    Precords: '种植记录',
    Plmode: '种植方式',
    Semrecord: '出苗记录',
    Digchan: '挖排涝渠',
    Stpl: '条栽',
    Pmeasurement: '植株测量',
    Farmanure: '农家肥',
    Hrecord: '作业方式',
    Lpreparation: '土地准备',
    Trees: '棵',
    area: '面积',
    Iplh: '单株高',
    Fsp: '平地条栽',
    Nogl: '绿叶数',
    ftgws: '补苗',
    Rod: '除虫记录',
    Wpplant: '单株重',
    Ecomment: '评论出错',
    lowest: '最低',
    Ecomments: '专家评论',
    Tirecord: '分蘖记录',
    highest: '最高',
    Irecord: '灌溉记录',
    Tinumber: '分蘖数',
    Pcontent: '准备内容',
    Lefe: '叶长',
    Rweeds: '清除杂草',
    MeNo: '测量编号',
    imethod: '灌溉方式',
    machinery: '机械',
    wafir: '水肥一体化记录',
    Abfertili: '施用底肥',
    wconsumption: '用水量',
    average: '平均',
    altitude: '高度',
    gladd: '获取本地add失败',
    Jorecord: '拔节记录',
    Lerror: '本地出错',
    Cuttagep: '扦插种植',
    Plrecords: '种植记录',
    dollar: '元',
    Arecords: '全部记录',
    ton: '吨',
    Srat: '育苗移栽',
    Tillage: '翻耕(旋耕)',
    yield: '产量',
    Lirrigation: '后期灌溉',
    Emrate: '出苗率',
    Grrecord: '生长记录',
    Ferrecord: '施肥记录',
    cost: '费用',
    quantity: '数量',
    Gsp: '草种准备',
    Gss: '草种选择',
    Fertype: '肥料类型',
    gram: '克',
    Gst: '草种截断',
    Cweight: '丛重',
    Odata: '离线数据',
    Ssp: '坡地条栽',
    artificial: '人工',
    Firrigation: '首次灌溉',
    Nrecord: '暂无记录',
    Pmr: '测产记录',
    Cplanning: '通道规划',
    vernalization: '催芽',
    Grecord: '整地记录',
    cfertilizer: '化肥',
    acre: '亩',
    Harecord: '收割记录'
  },
  landMainApp: { Tfarming: '农事类型', Gpreparation: '草种准备', Lpreparation: '土地准备', Afertilizer: '施用底肥' },
  forgotPassword: {
    welcomeLogin: '欢迎来到智慧菌草云平台，请登录！',
    vctips: '请输入验证码',
    resetsuccesstips: '重置密码已发送到您的手机！',
    sendvc: '发送验证码',
    sendpwd: '发送密码',
    teltips: '请输入手机号',
    wrongvc: '验证码不正确',
    verificationteltips: '手机号码为空或格式错误',
    verificationvctips: '验证码发送成功，请注意查收',
    loginPlatform: '智慧菌草云平台',
    login: '我要登陆',
    tips: '您可能不是系统用户，请联系管理员！'
  },
  grass: {
    cancel: '取消',
    Asuccess: '新增成功',
    submit: '提交',
    remark: '备注',
    Grass: '草种准备',
    accelerating: '催芽',
    content: '准备内容',
    tips: '请选择准备内容',
    strips: '坡地条栽',
    operator: '操作人',
    preparation: '土地准备',
    Truncation: '截断方式',
    dollar: '元',
    Cutting: '扦插种植',
    view: '查看数据',
    write: '记录时间',
    cost: '费用',
    proposes: '专家建议',
    tips1: '请选择截断方式',
    Base: '施用底肥',
    seedling: '育苗移栽',
    tips2: '请选择录入时间',
    Land: '整地记录',
    tips3: '请输入费用',
    Type: '农事类型',
    photograph: '农事照片',
    selection: '草种选择',
    guidance: '农事记录与指导',
    cutting: '草种截断',
    Flat: '平地条栽',
    return: '返回',
    Planting: '种植项目'
  },
  uploadImgApp: { cancel: '取消', tap: '拍照', SP: '选择图片', tips: '请选择上传方式' },
  soilpreRecord: {
    cancel: '取消',
    LineSpacing: '行间距',
    manure: '农家肥',
    NoRecord: '暂无记录',
    mechanical: '机械',
    Grass: '草种准备',
    pregermination: '催芽',
    content: '准备内容',
    strips: '坡地条栽',
    tips: '您确定要删除这条',
    dollar: '元',
    Truncation: '截断方式',
    Cutting: '扦插种植',
    spacing: '株间距',
    kilo: '公斤',
    price: '价格',
    record: '记录',
    weed: '清除杂草',
    operating: '作业方式',
    Fertilizer: '肥料类型',
    cost: '费用',
    Dig: '挖排涝渠',
    Recommended: '推荐肥料',
    proposes: '专家建议',
    Channel: '通道规划',
    Agricultural: '农事记录',
    tips1: '吗',
    seedling: '育苗移栽',
    Base: '施用底肥',
    fertilizer: '化肥',
    Land: '整地准备',
    Optimum: '最佳种植时间',
    confirm: '确定',
    manure1: '肥料',
    Tilling: '翻耕(旋耕)',
    artificial: '人工',
    depth: '沟深（穴深）',
    month: '月份',
    selection: '草地选择',
    Dtd: '删除本条数据',
    width: '沟宽（穴直径）',
    acre: '亩',
    cutting: '草种截断',
    Flat: '平地条栽',
    Soil: '土地准备',
    Dsuccessfully: '删除成功',
    catty: '斤',
    commentary: '专家评论'
  },
  recordInfo: {
    cuttage: '扦插',
    mechanical: '机械',
    Grass: '草种准备',
    type: '肥料类型',
    Harvest: '收割记录',
    strips: '坡地条栽',
    Truncation: '截断方式',
    Cutting: '扦插种植',
    ' Spec': '棵',
    number: '测量编号',
    irrigation: '灌溉方式',
    price: '价格',
    Primary: '首次灌溉',
    Production: '测产记录',
    traffic: '通道规划',
    height: '高度',
    area: '面积',
    weight: '单株重',
    water: '用水量',
    Tilling: '翻耕(旋耕)',
    selection: '草种选择',
    chemical: '化肥',
    Yellow: '黄叶数',
    highest: '最高',
    Individual: '单株茎直径',
    green1: '个',
    Flat: '平地条栽',
    Pitch: '单株节间距',
    Irrigation: '灌溉记录',
    average: '平均',
    Deworming: '除虫记录',
    manure: '农家肥',
    knob: '节',
    records: '水肥一体化记录',
    pattern: '种植方式',
    pregermination: '催芽',
    tiller: '分蘖数',
    dollar: '元',
    ton: '吨',
    Plant: '植株测量',
    kilo: '公斤',
    ' Soil': '土地准备',
    rate: '出苗率',
    yield: '产量',
    Emergence: '出苗记录',
    root: '根',
    weed: '清除杂草',
    Height: '单株高',
    operating: '作业方式',
    Tillering: '分蘖记录',
    Late: '后期灌溉',
    cost: '费用',
    Dig: '挖排涝渠',
    quantity: '数量',
    Jointing: '拔节记录',
    green: '绿叶数',
    Fertilization: '施肥记录',
    pesticide: '农药',
    Bundle: '丛重',
    gram: '克',
    length: '叶长',
    ' drill': '条栽',
    gaps: '补苗',
    seedling: '育苗移栽',
    Base: '施用底肥',
    fertilizer: '肥料',
    artificial: '人工',
    nodes: '单株节数',
    Preparation: '准备内容',
    cutting: '草种截断',
    acre: '亩',
    width: '叶宽',
    minimum: '最低',
    Planting: '种植记录'
  },
  allRecRecord: {
    cuttage: '扦插',
    ' Nodata': '暂无数据',
    mechanical: '机械',
    Grass: '草种准备',
    Harvest: '收割记录',
    strips: '坡地条栽',
    Truncation: '截断方式',
    Cutting: '扦插种植',
    ' Spec': '棵',
    irrigation: '灌溉方式',
    diameter: '单株茎直径',
    price: '价格',
    Primary: '首次灌溉',
    Production: '测产记录',
    Integrated: '水肥一体化记录',
    area: '面积',
    Channel: '通道规划',
    weight: '单株重',
    Entries: '个',
    water: '用水量',
    Tilling: '翻耕(旋耕)',
    Survey: '测量编号',
    selection: '草种选择',
    chemical: '化肥',
    Yellow: '黄叶数',
    highest: '最高',
    Flat: '平地条栽',
    Pitch: '单株节间距',
    Irrigation: '灌溉记录',
    altitude: '高度',
    average: '平均',
    Deworming: '除虫记录',
    manure: '肥料',
    knob: '节',
    farmyard: '农家肥',
    pattern: '种植方式',
    pregermination: '催芽',
    content: '准备内容',
    tiller: '分蘖数',
    dollar: '元',
    ton: '吨',
    Plant: '植株测量',
    kilo: '公斤',
    Number: '单株节数',
    ' Soil': '土地准备',
    rate: '出苗率',
    yield: '产量',
    Emergence: '出苗记录',
    root: '根',
    weed: '清除杂草',
    Height: '单株高',
    operating: '作业方式',
    Tillering: '分蘖记录',
    Late: '后期灌溉',
    cost: '费用',
    Dig: '挖排涝渠',
    quantity: '数量',
    Jointing: '拔节记录',
    green: '绿叶数',
    Fertilization: '施肥记录',
    pesticide: '农药',
    Bundle: '丛重',
    gram: '克',
    length: '叶长',
    ' drill': '条栽',
    leaf: '叶宽',
    gaps: '补苗',
    seedling: '育苗移栽',
    Base: '施用底肥',
    Type: '肥料类型',
    artificial: '人工',
    cutting: '草种截断',
    acre: '亩',
    minimum: '最低',
    Planting: '种植记录'
  },
  map: { Login: '登入系统', platform: '智慧菌草云平台' },
  addOrUpdateDictMain: {
    cancel: '取消',
    successfully: '类型添加成功！',
    submit: '提交',
    already: '编号已存在',
    Merriam: '新增字典',
    remark: '备注',
    EditDictionary: '编辑字典',
    tips1: '请输入编号',
    tips: '请输入名称',
    number: '字典编号',
    modify: '修改成功！',
    succeed: '成功',
    name: '字典名称'
  },
  tilleringRecordApp: {
    average: '平均',
    Fphotos: '农事照片',
    tips4: '请填写备注',
    tips5: '请选择时间',
    remark: '备注',
    tips1: '请输入最高分蘖数',
    lowest: '最低',
    tips: '请选择记录时间',
    operator: '操作人',
    tips2: '请输入最低分蘖数',
    tips3: '请输入平均分蘖数',
    highest: '最高',
    root: '根',
    define: '确定',
    Tnumber: '分蘖数',
    write: '记录时间'
  },
  project_show: {
    cancel: '取消',
    skip: '跳至',
    Ejump: '按下回车键后跳转',
    sdelete: '删除成功',
    remove: '删除',
    tips: '您确定要删除',
    skip1: '页',
    compile: '编辑',
    toble: '共',
    record: '条记录',
    headline: '标题',
    elink: '外链',
    Pp: '种植项目',
    pname: '项目名称',
    introduce: '介绍',
    redact: '编辑',
    tips1: '"',
    picture: '图片',
    tips2: '吗',
    confirm: '确定',
    site: '地址',
    operate: '操作',
    Dtd: '删除本条数据',
    aitem: '添加项目',
    PM: '项目管理',
    Display: '展示项目管理'
  },
  emergence: {
    Tillering: '分蘖记录',
    cancel: '取消',
    Jointing: '拔节记录',
    submit: '提交',
    roe: '出苗率',
    remark: '备注',
    auxograph: '生长记录',
    tips1: '请输入出苗率',
    ' Asuccess': '新增成功',
    tips: '请选择录入时间',
    operator: '操作人',
    Type: '农事类型',
    Plant: '植株测量',
    photograph: '农事照片',
    view: '查看数据',
    guidance: '农事记录与指导',
    Emergence: '出苗记录',
    planting: '种植项目',
    write: '记录时间',
    return: '返回'
  },
  yieldsRecordApp: {
    Operator: '操作人',
    Aphotos: '农事照片',
    Tfarming: '农事类型',
    Rtime: '记录时间',
    tips4: '请输入产量',
    tips5: '请填写备注',
    remark: '备注',
    tips6: '请选择时间',
    syield: '测产',
    tips1: '请填写面积',
    tips: '请选择记录时间',
    tips2: '请输入面积',
    tips3: '请填写产量',
    ton: '吨',
    artificial: '人工',
    yield: '产量',
    define: '确定',
    acre: '亩',
    Omode: '作业方式'
  },
  emergenceRecordApp: {
    Erate: '出苗率',
    Fphotos: '农事照片',
    define: '确认',
    tips4: '请选择时间',
    remark: '备注',
    write: '记录时间',
    tips1: '请填写出苗率',
    tips: '请选择记录时间',
    operator: '操作人',
    tips2: '请输入出苗率',
    tips3: '请填写备注'
  },
  choiceLanguageApp: { define: '确定', Sl: '选择语言' },
  suggest: {
    LineSpacing: '行间距',
    Check: '请检查表单信息完整性',
    tips: '填写菌草品种',
    December: '十二月',
    Otime: '最佳种植时间',
    draft: '保存为草稿',
    estimate: '总成本预估',
    July: '七月',
    Wetland: '湿地(草甸/沼泽地)',
    January: '一月',
    Oasis: '绿洲一号',
    Land: '整地建议',
    planning: '种植规划',
    plant: '种植',
    processing: '菌草加工建议',
    Amachinery: '添加自动化机械',
    Person: '人/天',
    Pname: '规划名称',
    Irrigation: '灌溉+管理成本(水+管线+人力)',
    June: '六月',
    May: '五月',
    Sservice: '选择服务',
    freight: '运费',
    Saline: '盐碱地',
    March: '三月',
    protection: '植保',
    Labor: '劳动力预估',
    Asensor: '添加建议传感器',
    dollar: '元/亩/年',
    ton: '吨/亩',
    October: '十月',
    kilo: '公斤',
    variety: '菌草品种',
    seedlings: '种苗数量',
    Fillin: '填写菌草加工建议',
    tips4: '请输入完整的整地建议',
    black: '黑土',
    tips5: '请点击栽培方法',
    tips6: '请点击推荐肥料',
    Modify: '修改专家建议',
    tips7: '请选择服务',
    suggest: '提交建议',
    tips1: '请选择菌草品种',
    Whole: '全珠条栽',
    tips2: '请输入种苗数量',
    cultivation: '栽培方法',
    tips3: '请选择最佳种植时间',
    automated: '自动化机械',
    depth: '沟深(穴深)',
    width: '沟宽(穴直径)',
    information: '查看信息',
    Yield: '产量预估',
    operation: '操作',
    return: '返回',
    dimensions: '填写尺寸',
    irrigate: '灌溉',
    Harvest: '收割成本',
    tips8: '请输入预估人数',
    krasnozem: '红壤',
    tips9: '请输入劳动力预估',
    Sname: '传感器名称',
    Short: '短杆扦插',
    digit: '输入数字',
    strain: '株',
    individual: '个',
    Successful: '建议提交成功',
    April: '四月',
    August: '八月',
    Suggestions: '部署传感器建议',
    unit: '辆',
    vadvice: '查看专家建议',
    month: '填写月份',
    tips12: '请选择菌草加工建议',
    tips10: '请输入产量预估',
    Giant: '巨菌草',
    classify: '分类',
    hiring: '租赁成本',
    Pservice: '种植服务',
    September: '九月',
    farmyard: '农家肥',
    advice: '填写专家建议',
    delete: '删除',
    content: '请检产量填写内容',
    tips01: '请输入正确产量预估',
    Bead: '珠间距',
    Fname: '肥料名称',
    February: '二月',
    November: '十一月',
    costs: '其他成本',
    quantity: '数量',
    cost: '肥料+管理成本(有机肥+动力+人力)',
    sprout: '种苗',
    manufacturers: '厂家',
    Quantity: '填写数量',
    Cluster: '丛栽',
    Sandy: '沙壤地',
    fertilizer: '推荐肥料',
    seedling: '育苗移栽',
    Lservice: '土地平整服务',
    Nmachine: '机械名称',
    Hservice: '收割服务',
    Lestimation: '土地预估',
    acre: '元/亩',
    Afertilizer: '添加推荐肥料',
    Planting: '种植服务/人力成本',
    Handling: '搬运&运输成本(产地-加工地)'
  },
  Tab1PageApp: {},
  machineManage: {
    MM: '机械管理',
    cancel: '取消',
    Aywtd: '您确定要删除',
    tips4: '共',
    DM: '生产资料管理',
    skip: '跳至',
    type: '类型',
    delete: '删除',
    tips: '请输入名称检索',
    tips2: '没有发现数据',
    tips3: '条记录',
    confirm: '确定',
    compile: '编辑',
    ma: '吗？',
    Dtd: '删除本条数据',
    name: '名称',
    Manufacturer: '厂家/供应商/渠道',
    model: '型号',
    page: '页',
    press: '按下回车键后跳转',
    brand: '品牌',
    operation: '操作',
    machinery: '新增机械'
  },
  insecticideManage: {
    Add: '新增农药',
    cancel: '取消',
    Aywtd: '您确定要删除',
    DM: '生产资料管理',
    tips5: '共',
    skip: '跳至',
    delete: '删除',
    tips: '请输入名称检索',
    tips2: '没有发现数据',
    tips3: '条记录',
    confirm: '确定',
    compile: '编辑',
    ma: '吗？',
    Dtd: '删除本条数据',
    name: '名称',
    Manufacturer: '厂家/供应商/渠道',
    page: '页',
    press: '按下回车键后跳转',
    operation: '操作',
    PM: '农药管理'
  },
  applyFertilizerApp: {
    classify: '分类',
    Sfertilizer: '选择肥料',
    Fphotos: '农事照片',
    Nfertilizer: '肥料名称',
    remark: '备注',
    delete: '删除',
    tips: '请选择作业方式',
    operator: '操作人',
    dollar: '元',
    composition: '成分',
    define: '确定',
    Manufacturer: '厂家/供应商/渠道',
    kilogram: '公斤',
    write: '记录时间',
    cost: '费用',
    tips4: '请选择肥料',
    tips5: '请输入肥料数量',
    tips6: '请填写备注',
    manufactor: '厂家',
    Fmanure: '农家肥',
    tips7: '请选择时间',
    tips1: '请选择记录时间',
    tips2: '请填写费用',
    tips3: '请输入费用',
    artificial: '人工',
    Omode: '作业方式',
    machinery: '机械'
  },
  harvestRecord: {
    cancel: '取消',
    LineSpacing: '行间距',
    NoRecord: '暂无记录',
    Harvest: '收割记录',
    delete: '删除成功',
    tips: '您确定要删除这条吗?',
    dollar: '元',
    ton: '吨',
    spacing: '株间距',
    harvesting: '推荐收割方式',
    record: '记录',
    yield: '产量',
    area: '面积',
    harvest: '建议收割时间',
    cost: '费用',
    proposes: '专家建议',
    Agricultural: '农事记录',
    confirm: '确定',
    depth: '沟深（穴深）',
    month: '月份',
    management: '收割管理',
    Dtd: '删除本条数据',
    width: '沟宽（穴直径）',
    acre: '亩',
    commentary: '专家评论'
  },
  ass: {
    cancel: '取消',
    Asuccess: '新增成功',
    submit: '提交',
    record1: '测产记录',
    recording: '请选择记录时间',
    remark: '备注',
    operator: '操作人',
    output: '产量',
    ton: '吨',
    view: '查看数据',
    farming: '农事类型',
    yield: '测产',
    operating: '作业方式',
    stand: '台',
    area: '面积',
    proposes: '专家建议',
    Pea: '请输入面积',
    photograph: '农事照片',
    artificial: '人工',
    guidance: '农事记录与指导',
    Pio: '请输入产量',
    acre: '亩',
    planting: '种植项目',
    time: '记录时间',
    job: '请选择作业方式',
    return: '返回'
  },
  addOrUpdateMachine: {
    cancel: '取消',
    Add: '添加成功',
    Company: '机械厂家',
    increased: '新增机械',
    tips4: '请输入型号',
    save: '保存',
    type: '机械类型',
    tips1: '请输入类型',
    tips: '请输入名称',
    tips2: '请输入品牌',
    tips3: '请输入厂家',
    modify: '修改成功',
    compile: '编辑机械',
    name: '机械名称',
    model: '机械型号',
    brand: '机械品牌'
  },
  measuring: {
    cancel: '取消',
    Asuccess: '新增成功',
    knob: '节',
    submit: '提交',
    remark: '备注',
    auxograph: '生长记录',
    operator: '操作人',
    tiller: '分蘖数',
    Plant: '植株测量',
    view: '查看数据',
    diameter: '单株茎直径',
    Emergence: '出苗记录',
    root: '根',
    Height: '单株高',
    write: '记录时间',
    Tillering: '分蘖记录',
    Jointing: '拔节记录',
    green: '绿叶数',
    individual: '单株节间距',
    Bundle: '丛重',
    gram: '克',
    length: '叶长',
    Entries: '个',
    Weight: '单株重',
    Project: '种植项目',
    Type: '农事类型',
    photograph: '农事照片',
    Survey: '测量编号',
    nodes: '单株节数',
    Yellow: '黄叶数',
    guidance: '农事记录与指导',
    width: '叶宽',
    return: '返回'
  },
  plantMainApp: {
    deinsectization: '除虫',
    Wafi: '水肥一体化',
    Tfarming: '农事类型',
    smanure: '施肥',
    plant: '种植',
    irrigate: '灌溉'
  },
  Dashboard: {
    leader: '组长',
    esearch: '科研',
    access: '无访问权限',
    production: '生产',
    project: '项目',
    loginPlatform: ' 智慧菌草云平台',
    China: '中国',
    underway: '进行中',
    member: '项目成员',
    annual: '年度',
    camera: '摄像头',
    all: '全部',
    filed: '已归档',
    tips4: '该设备暂未关联任何项目',
    tips5: '抱歉，您暂无访问此项目的权限',
    loading: '加载中...',
    tips1: '最近6小时内无新数据',
    tips2: '正在加载最新数据',
    tips3: '无项目信息',
    destroyed: '销毁了',
    effect: '实验效果',
    name: '项目名称',
    location: '地点',
    sensor: '传感器',
    productioning: '进行中-生产',
    Researching: '进行中-科研'
  },
  expertApp: { Eg: '专家指导' },
  PosiConfig: {
    cancel: '取消',
    confirm: '确定',
    Geographic: '地理信息更新成功',
    coordinate: '坐标位置',
    succeed: '成功',
    information: '消息',
    error: '错误',
    tips1: '已清空所选位置',
    tips: '请输入',
    tips2: '请选择一个经纬度',
    Clear: '清除坐标'
  },
  disinfesteRecordApp: {
    Pname: '杀虫剂名称',
    Fphotos: '农事照片',
    Nused: '不使用',
    remark: '备注',
    delete: '删除',
    tips: '请选择作业方式',
    operator: '操作人',
    Sinsecticide: '选择杀虫剂',
    dollar: '元',
    tips8: '请填写备注',
    tips9: '请选择时间',
    define: '确定',
    Manufacturer: '厂家/供应商/渠道',
    kilogram: '公斤',
    write: '记录时间',
    area: '面积',
    cost: '费用',
    tips4: '请选择杀虫剂',
    tips5: '请输入杀虫剂数量',
    tips6: '请填写费用',
    manufactor: '厂家',
    tips7: '请输入费用',
    tips1: '请选择记录时间',
    tips2: '请填写面积',
    tips3: '请输入面积',
    artificial: '人工',
    acre: '亩',
    Omode: '作业方式',
    machinery: '机械'
  },
  Tab4PageApp: {},
  irrigation: {
    cancel: '取消',
    Asuccess: '新增成功',
    submit: '提交',
    irrigate: '灌溉',
    mechanical: '机械',
    remark: '备注',
    tips: '请选择作业方式',
    operator: '操作人',
    dollar: '元',
    ton: '吨',
    view: '查看数据',
    irrigation: '灌溉方式',
    farming: '农事类型',
    Primary: '首次灌溉',
    operating: '作业方式',
    write: '记录时间',
    area: '面积',
    Late: '后期灌溉',
    grow: '种植',
    cost: '费用',
    fertilization: '施肥',
    tips4: '请输入用水量',
    tips5: '请输入费用',
    tips1: '请选择灌溉方式',
    water: '用水量',
    tips2: '请选择记录时间',
    tips3: '请输入面积',
    Project: '种植项目',
    artificial: '人工',
    photograph: '农事照片',
    deinsectization: '除虫',
    guidance: '农事记录与指导',
    acre: '亩',
    ioaf: '水肥一体化',
    return: '返回',
    Planting: '种植记录'
  },
  plantMeasurementApp: {
    Fphotos: '农事照片',
    remark: '备注',
    tips: '请选择记录时间',
    operator: '操作人',
    yleaves: '黄叶数',
    Nplants: '单株节间距',
    tips8: '请填写单株节间距',
    Llength: '叶长',
    tips9: '请填写叶长',
    define: '确定',
    Bwidth: '叶宽',
    write: '记录时间',
    Wplant: '单株重',
    gram: '克',
    tips4: '请填写单株高',
    Cweight: '丛重',
    tips5: '请填写单株重',
    Sdiameter: '单株茎直径',
    gleaves: '绿叶数',
    tips6: '请填写单株茎直径',
    Nplant: '单株节数',
    tips7: '请填写单株节数',
    tips2: '请填写丛重',
    MNo: '测量编号',
    tips3: '请填写分蘖数',
    Iheight: '单株高',
    tips13: '请填写备注',
    tips14: '请选择时间',
    tips11: '请填写绿叶数',
    tips12: '请填写黄叶数',
    Tnumber: '分蘖数',
    tips10: '请填写叶宽'
  },
  dictItemTable: {
    cancel: '取消',
    item: '字典项名称',
    successfully: '删除成功',
    language: '语言',
    remark: '备注',
    skip: '跳至',
    delete: '删除',
    tips1: '条记录',
    tips: '共',
    tips2: '您确定要删除',
    tips3: '吗',
    affirm: '确认',
    entry: '新增条目',
    compile: '编辑',
    skip1: '页',
    skip2: '按下回车键后跳转',
    succeed: '成功',
    NoData: '无数据',
    value: '字典项值',
    operation: '操作'
  },
  sysconfig: {
    successfully: '保存成功',
    Hpoint: '首页地图中心点',
    smanagement: '系统管理',
    sconfiguration: '系统配置',
    save: '保存',
    Home: '首页地图缩放级别'
  },
  ProjectConig: {
    cancel: '取消',
    confirm: '确定',
    affiliation: '所属项目',
    succeed: '成功',
    Assign: '分配到项目',
    assigned: '已完成设备分配',
    Non: '非摄像头设备',
    tips1: '请选择项目',
    tips: '请选择',
    tips2: '多选模式下无法设置AI分析',
    tips3: '摄像头加入大棚日照分析AI计算'
  },
  jointing: {
    Tillering: '分蘖记录',
    cancel: '取消',
    Jointing: '拔节记录',
    Asuccess: '新增成功',
    submit: '提交',
    remark: '备注',
    auxograph: '生长记录',
    tips: '请选择录入时间',
    operator: '操作人',
    tips2: '请输入高度',
    Type: '农事类型',
    Plant: '植株测量',
    photograph: '农事照片',
    view: '查看数据',
    guidance: '农事记录与指导',
    Emergence: '出苗记录',
    write: '记录时间',
    return: '返回',
    Planting: '种植项目',
    height: '高度'
  },
  Dashboard1: {
    Ndata: '最近6小时内无新数据',
    production: '进行中-生产',
    year: '年度',
    scientific: '科研',
    project: '项目',
    Research: '进行中-科研',
    tips: '/',
    China: '中国',
    underway: '进行中',
    gleader: '组长',
    place: '地点',
    camera: '摄像头',
    latest: '正在加载最新数据',
    all: '全部',
    pname: '项目名称',
    Ninformation: '无项目信息',
    loading: '加载中',
    tips1: '该设备暂未关联任何项目',
    tips2: '抱歉，您暂无访问此项目的权限',
    destroyed: '销毁了',
    Closed: '已归档',
    pmember: '项目成员',
    effect: '实验效果',
    sensor: '传感器',
    Naccess: '无访问权限'
  },
  addOrUpdatesuggest3: {
    classify: '分类',
    skip: '跳至',
    soil: '土壤检测',
    tips1: '条记录',
    Asensor: '添加传感器',
    tips: '共',
    tips2: '按下回车键后跳转',
    Fungus: '菌草育种',
    skip1: '页',
    selection: '确认选择',
    wheather: '气象检测',
    name: '传感器名称',
    Manufacturer: '厂家/供应商',
    model: '型号',
    camera: '摄像头',
    brand: '品牌',
    Nfound: '没有发现数据'
  },
  addOrUpdatesuggest2: {
    all: '全部',
    classify: '分类',
    harvest: '收割',
    skip: '跳至',
    protection: '植物保护机',
    UAV: '无人机',
    tips1: '条记录',
    tips: '共',
    tips2: '按下回车键后跳转',
    Name: '机械名称',
    irrigation: '灌溉设备',
    skip1: '页',
    selection: '确认选择',
    Manufacturer: '厂家/供应商/渠道',
    planting: '种植机械',
    Amachinery: '添加自动化机械',
    model: '型号',
    brand: '品牌',
    Nfound: '没有发现数据'
  },
  addOrUpdatesuggest1: {
    classify: '分类',
    component: '成份',
    skip1: '页',
    selection: '确认选择',
    name: '肥料名称',
    Manufacturer: '厂家/供应商/渠道',
    skip: '跳至',
    tips1: '条记录',
    Afertilizer: '添加推荐肥料',
    tips: '共',
    tips2: '按下回车键后跳转',
    Nfound: '没有发现数据'
  },
  TabRecordApp: {
    Lelen: '叶长',
    joint: '节',
    cuttage: '扦插',
    Nsosp: '单株节间距',
    Opmode: '作业方式',
    Excomment: '专家评论',
    Gpr: '整地记录',
    MeasurNo: '测量编号',
    Gp: '草地准备',
    Chp: '通道规划',
    Cp: '扦插种植',
    Plmeasure: '植株测量',
    tips: '请输入项目名称',
    Trmode: '截断方式',
    Noyl: '黄叶数',
    Wafir: '水肥一体化记录',
    Nonpp: '单株节数',
    Pc: '准备内容',
    price: '价格',
    Sdpp: '单株茎直径',
    Plmode: '种植方式',
    kilogram: '公斤',
    Lpreparation: '土地准备',
    Fetype: '肥料类型',
    area: '面积',
    Trees: '棵',
    Fsp: '平地条栽',
    Nogl: '绿叶数',
    Rod: '除虫记录',
    ftgws: '补苗',
    Plrecord: '种植记录',
    Ecomment: '专家评论',
    lowest: '最低',
    Tirecord: '分蘖记录',
    highest: '最高',
    Irecord: '灌溉记录',
    Agrecords: '农事记录',
    machinery: '机械',
    altitude: '高度',
    average: '平均',
    Ser: '出苗记录',
    Abf: '施用底肥',
    Jorecord: '拔节记录',
    Wepp: '单株重',
    Lewid: '叶宽',
    dollar: '元',
    wconsump: '用水量',
    ton: '吨',
    sf: '搜索',
    Loading: '加载中',
    Srat: '育苗移栽',
    Tillage: '翻耕(旋耕)',
    root: '根',
    yield: '产量',
    Lirrigation: '后期灌溉',
    Emrate: '出苗率',
    Inph: '单株高',
    DDc: '挖排涝渠',
    cost: '费用',
    cf: '化肥',
    quantity: '数量',
    Gsp: '草种准备',
    Rw: '清除杂草',
    Gss: '草种选择',
    pesticide: '农药',
    Fm: '农家肥',
    gram: '克',
    Gst: '草种截断',
    Cweight: '丛重',
    Arecord: '全部记录',
    Fr: '施肥记录',
    irmethod: '灌溉方式',
    fertilizer: '肥料',
    Ssp: '坡地条栽',
    artificial: '人工',
    Firrigation: '首次灌溉',
    Nrecord: '暂无记录',
    Pmr: '测产记录',
    vernalization: '催芽',
    acre: '亩',
    Grecord: '生长记录',
    synchronization: '同步',
    Tnumber: '分蘖数',
    Harecord: '收割记录',
    Stplant: '条栽'
  },
  loginMap: {
    systemLanguage: '选择系统语言',
    summary: '无权访问',
    link: '[链接]',
    loginPlatform: '智慧菌草云平台',
    loading: '加载中...',
    tips: '浏览器不兼容或版本过低，请下载最新版本的浏览器',
    tips2: '"',
    warn: '警告',
    download: '前往下载',
    skip1: '页',
    QXdetail: '越权访问：没有访问该接口的权限！',
    loginButton: '登入系统',
    detail: '令牌无效或已过期，因此暂无法访问该功能，您需要重新登录！',
    Tips: '提示'
  },
  test: { technology: '技术' },
  addOrUpdateSensor: {
    cancel: '取消',
    Add: '添加成功',
    increased: '新增传感器',
    tips4: '请输入品牌',
    save: '保存',
    tips5: '请输入型号',
    type: '传感器类型',
    tips1: '请输入类型',
    tips: '请输入名称',
    manufacturer: '传感器厂家',
    tips3: '请输入厂家',
    modify: '修改成功',
    compile: '编辑传感器',
    name: '传感器名称',
    model: '传感器型号',
    brand: '传感器品牌'
  },
  fetilize: {
    cancel: '取消',
    Asuccess: '新增成功',
    submit: '提交',
    pattern: '施肥方式',
    irrigate: '灌溉',
    mechanical: '机械',
    remark: '备注',
    delete: '删除',
    tips: '请选择作业方式',
    operator: '操作人',
    dollar: '元',
    pound: '斤/亩',
    view: '查看数据',
    kilo: '公斤',
    Phm: '收割后管理',
    farming: '农事类型',
    operating: '作业方式',
    write: '记录时间',
    area: '面积',
    harvest: '收割',
    grow: '种植',
    cost: '费用',
    fertilization: '施肥',
    proposes: '专家建议',
    tips4: '请输入公斤数',
    Tabc: '分叶期追肥',
    tips5: '请输入费用',
    tips1: '请选择种植方式',
    tips2: '请选择录入时间',
    fertilizer: '选择肥料',
    tips3: '请输入面积',
    Tajs: '拔节期追肥',
    Project: '种植项目',
    artificial: '人工',
    photograph: '农事照片',
    deinsectization: '除虫',
    Topdressing: '苗期追肥',
    guidance: '农事记录与指导',
    acre: '亩',
    ioaf: '水肥一体化',
    return: '返回',
    Planting: '种植记录',
    Afertilizer: '添加使用肥料'
  },
  Device: {
    bed: '猪饲料-发酵床',
    cancel: '取消',
    type: '项目类型',
    tips: '共',
    number: '设备编号',
    Idm: '智能设备管理',
    Euser: '编辑用户',
    skip1: '页',
    tips8: '确定要删除此用户吗?',
    deletion: '用户删除成功',
    tips9: '确认要执行此操作吗',
    enter: '请输入',
    Business: '业务状态',
    all: '全部',
    inquire: '查询',
    Asuccessfully: '用户添加成功',
    coordinate: '坐标',
    Root: '根系越冬',
    nworking: '非工作',
    particular: '详细',
    undistributed: '未分配',
    pack: '收起',
    establish: '创建',
    affirm: '确认',
    field: '生产田间管理',
    name: '项目名称',
    reset: '重置',
    Package: '套餐设置',
    device: '设备类型',
    Press: '按下回车键搜索',
    wind: '防风固沙',
    breeding: '育种',
    Eallocation: '设备分配',
    production: '生产',
    scientific: '科研',
    save: '保存',
    scale: '坐标设置',
    choose: '请选择',
    ptype: '项目类型',
    skip: '跳至',
    error: '错误',
    remove: '移除',
    Ndf: '没有发现数据',
    working: '工作中',
    place: '地点',
    acquisition: '设备获取',
    Mushroom: '种菇',
    tips4: '吗',
    tips5: '请选择一个设备',
    Service: '业务状态设置',
    tips6: '所选设备中包含设备上传的坐标，不可修改',
    tips7: '请至少选择一个设备',
    tips1: '条',
    tips2: '按下回车键后跳转',
    tips3: '确定要移除',
    videoEquiment:'添加视频设备',
    equipments: '添加设备',
    Equipment: '设备状态',
    Ausers: '添加用户',
    succeed: '成功',
    devicename: '设备名称',
    Assign: '分配到项目',
    operation: '操作',
    unfold: '展开'
  },
  addOrUpdateRole: {
    cancel: '取消',
    prohibit: '禁止',
    Nexists: '编号已存在',
    enable: '启用',
    Enumber: '请输入编号',
    save: '保存',
    snumber: '编号',
    Enable: '是否启用',
    Rname: '角色姓名',
    aroles: '添加角色',
    tips: '请输入用户姓名'
  },
  myImageApp: {},
  Tab2PageApp: {},
  selectInsecticide: {
    Ndf: '没有发现数据',
    skip1: '页',
    Choose: '确认选择',
    name: '名称',
    Manufacturer: '厂家/供应商/渠道',
    tips4: '至少选择一项',
    insecticide: '选择杀虫剂',
    skip: '跳至',
    tips1: '条记录',
    tips: '共',
    tips2: '"',
    tips3: '按下回车键后跳转'
  },
  Role: {
    cancel: '取消',
    rname: '角色名称',
    smanagement: '系统管理',
    records: '条记录',
    skip: '跳至',
    farmers: '农场主',
    delete: '删除',
    Ejump: '按下回车键后跳转',
    tips: '您确定要删除',
    Dmenu: '分配菜单',
    Administrator: '管理员',
    compile: '编辑',
    skip1: '页',
    enable: '启用',
    toble: '共',
    Nfound: ' 没有发现数据。',
    forbidden: '禁用',
    Udsucceeded: '角色删除成功',
    tips1: '？',
    tips2: '吗',
    tips3: '"',
    confirm: '确定',
    Dtd: '删除本条数据',
    succeed: '成功',
    Aroles: '添加角色',
    snumber: '编号',
    Erole: '请输入角色名称',
    rmanagement: '角色管理',
    operation: '操作',
    user: '用户'
  },
  myImage: {},
  ArchiveForm: {
    cancel: '取消',
    mistake: '错误',
    affirm: '确认',
    submit: '提交',
    success: '成功',
    category: '归档周期类别',
    pigeonhole: '归档',
    yearly: '按年',
    tips1: '确认要归档所选的项目吗',
    tips: '请选择归档类别',
    tips2: '所选归档成功'
  },
  fertilizeres: {
    cancel: '取消',
    Asuccess: '新增成功',
    submit: '提交',
    farmyard: '农家肥',
    mechanical: '机械',
    remark: '备注',
    Grass: '草种准备',
    delete: '删除',
    tips: '请选择作业方式',
    operator: '操作人',
    preparation: '土地准备',
    dollar: '元',
    view: '查看数据',
    kilo: '公斤',
    operating: '作业方式',
    write: '记录时间',
    cost: '费用',
    manufacturers: '厂家',
    proposes: '专家建议',
    tips4: '请输入公斤数',
    tips1: '请选择录入时间',
    fertilizer: '施用底肥',
    tips2: '请输入费用',
    Land: '整地记录',
    tips3: '请选择肥料',
    Type: '农事类型',
    artificial: '人工',
    photograph: '农事照片',
    guidance: '农事记录与指导',
    Select: '选择肥料',
    acre: '亩',
    catty: '斤',
    return: '返回',
    Afertilizer: '添加使用肥料',
    Planting: '种植项目'
  },
  AppTopbar: { confirm: '确认', cancel: '取消', exit: '确认要退出系统吗？', CPassword: '修改密码', Log: '退出登录' },
  Apppad: {
    cancel: '取消',
    Ss: '发送成功',
    Tcr: '缓存请求共',
    Tbs: '待同步',
    upload: '上传',
    synchronization: '同步',
    tips1: '网络已连接，确认上传本地记录？',
    tips: '设备离线，请联网后及时上传数据！',
    tips2: '网络未连接，请在网络连接后同步数据',
    tips3: '上传数据失败，请确认连接网络后手动上传'
  },
  growthMainApp: {
    Trecord: '分蘖记录',
    emergence: '出苗记录',
    Tfarming: '农事类型',
    Jrecord: '拔节记录',
    Pmeasurement: '植株测量'
  },
  Detail: {
    cancel: '取消',
    coordinate: '坐标',
    Polar: '密码',
    Device: '设备详情',
    Affiliated: '所属项目',
    Enumber: '设备序列号',
    Duration: 'SIM套餐期限',
    AppSecret: 'AppSecret',
    Secret: 'Secret',
    serial: '编号',
    Remarks: 'SIM套餐备注',
    SIM: 'SIM运营商',
    AppKey: 'AppKey',
    name: '名称',
    ClientId: 'ClientId',
    model: '型号',
    device: '设备类型',
    account: '账号',
    Location: '部署地点'
  },
  ResetPassword: {
    upassword: '修改密码',
    cancel: '取消',
    successfully: '密码修改成功',
    submit: '提交',
    Psecurity: '密码为8-25位字母、数字和特殊字符组合',
    tips1: '两次密码不一致',
    tips: '不符合密码内容要求',
    tips2: '确认要修改密码吗?',
    enpassword: '请输入新密码',
    affirm: '确认',
    succeed: '成功',
    npassword: '新密码',
    Enpassword: '请输入新密码',
    Epassword: '请输入原密码',
    perror: '原密码错误，请检查',
    cpassword: '确认密码',
    opassword: '原密码'
  },
  User: {
    cancel: '取消',
    eaddress: '电子邮箱',
    role: '角色',
    smanagement: '系统管理',
    Eaddress: '请输入正确的邮箱地址',
    save: '保存',
    skip: '跳至',
    error: '错误',
    delete: '删除',
    Ejump: '按下回车键后跳转',
    tips: '请输入姓名或者手机号码',
    Ephone: '请输入正确的手机号码',
    Pnumber: '手机号码',
    compile: '编辑',
    skip1: '页',
    tips8: '确定要删除此用户吗？',
    uright: '用户权限',
    msuccessfully: '修改成功',
    sroles: '请选择角色',
    Uasuccessfully: '用户添加成功',
    Nfound: '没有发现数据。',
    aname: '用户姓名',
    rpasswords: '重置密码',
    tips4: '密码重置成功，短信已发送到该手机',
    tips5: '确认要执行此操作吗',
    tips6: '手机号码已被使用，请更换',
    ausers: '添加用户',
    team: '所属团队',
    tips7: '邮箱已被使用，请更换',
    Udsucceeded: '用户删除成功',
    tips1: '共',
    tips2: '条',
    establish: '创建',
    Ename: '请输入用户姓名',
    tips3: '确认要重置密码吗',
    affirm: '确认',
    ulist: '用户列表',
    euser: '编辑用户',
    succeed: '成功',
    Duser: '删除用户',
    operation: '操作'
  },
  grassPrepareApp: {
    Operator: '操作人',
    cost: '费用',
    Fphotos: '农事照片',
    Sstrip: '坡地条栽',
    tips4: '请输入费用',
    tips5: '请填写备注',
    Stransplanting: '育苗移栽',
    remark: '备注',
    tips6: '请选择时间',
    Cuttage: '扦插种植',
    Fstrip: '平地条栽',
    tips1: '请选择截断方式',
    tips: '请选择准备内容',
    tips2: '请选择记录时间',
    Truncation: '截断方式',
    tips3: '请填写费用',
    dollar: '元',
    Gtruncation: '草种截断',
    Gselection: '草种选择',
    vernalization: '催芽',
    define: '确定',
    Pcontent: '准备内容',
    write: '记录时间'
  },
  selectFetilize: {
    classify: '分类',
    tips4: '按下回车键后跳转',
    tips5: '至少选择一项',
    skip: '跳至',
    tips1: '条记录',
    tips: '共',
    tips3: '"',
    Ndf: '没有发现数据',
    component: '成份',
    skip3: '"',
    skip2: '页',
    Choose: '确认选择',
    Select: '选择肥料',
    name: '肥料名称',
    Manufacturer: '厂家/供应商/渠道'
  },
  addOrUpdateDictItem: {
    cancel: '取消',
    New: '新增子条目',
    Dictionary: '字典项名称',
    successfully: '类型添加成功',
    submit: '提交',
    Edit: '编辑子条目',
    language: '语言',
    remark: '备注',
    tips1: '请输入值',
    tips: '请输入名称',
    tips2: '请输入语言',
    modify: '修改成功',
    succeed: '成功',
    Chinese: '中文',
    value: '字典项值'
  },
  error: { Click: '点击返回首页' },
  TabPersonApp: {
    exit: '退出',
    Ccs: '联系客服',
    Lo: '退出登录',
    Hello: '你好',
    cancle: '取消',
    Vn: '版本号',
    CP: '修改密码',
    Vtow: '访问官网',
    tips: '确定要退出系统吗'
  },
  tiller: {
    Tillering: '分蘖记录',
    cancel: '取消',
    average: '平均',
    Jointing: '拔节记录',
    Asuccess: '新增成功',
    submit: '提交',
    remark: '备注',
    auxograph: '生长记录',
    tips1: '请选择录入时间',
    operator: '操作人',
    tiller: '分蘖数',
    tips: '请输入完整的分蘖数',
    Project: '种植项目',
    Type: '农事类型',
    Plant: '植株测量',
    photograph: '农事照片',
    view: '查看数据',
    highest: '最高',
    guidance: '农事记录与指导',
    Emergence: '出苗记录',
    root: '根',
    write: '记录时间',
    minimum: '最低',
    return: '返回'
  },
  mainAddApp: {
    Ar: '农事记录',
    number: '编号',
    pa: '种植面积',
    Rs: '记录选择',
    Pr: '种植记录',
    Pmr: '测产记录',
    acre: '亩',
    Gpr: '整地记录',
    Hr: '收割记录',
    Gr: '生长记录',
    SR: '选择记录'
  },
  PicturePlayback: { Sample: '采样回放' },
  sensorManage: {
    cancel: '取消',
    Aywtd: '您确定要删除',
    tips4: '共',
    skip: '跳至',
    type: '类型',
    delete: '删除',
    tips: '请输入名称检索',
    tips2: '没有发现数据',
    tips3: '条记录',
    confirm: '确定',
    compile: '编辑',
    ldm: '智能设备管理',
    ma: '吗？',
    Dtd: '删除本条数据',
    name: '名称',
    Manufacturer: '厂家/供应商/渠道',
    sensor: '新增传感器',
    model: '型号',
    SM: '传感器管理',
    page: '页',
    press: '按下回车键后跳转',
    brand: '品牌',
    operation: '操作'
  },
  Language: {
    cancel: '取消',
    skip: '跳至',
    type: '语言包类型',
    delete: '删除',
    content: '词条内容',
    tips: '共',
    compile: '编辑',
    skip1: '页',
    Nd: '暂无数据',
    Added: '新增',
    tips4: '您确定要删除"',
    tips5: '"吗',
    tips6: '此节点下存在数据，不能删除',
    Multilingual: '多语言管理',
    tips1: '条记录',
    tips2: '按下回车键后跳转',
    tips3: '此节点下存在子节点，不能删除',
    affirm: '确认',
    fail: '失败',
    system: '系统管理',
    Sdelete: '删除成功',
    succeed: '成功',
    name: '词条名称',
    attention: '注意',
    operation: '操作'
  },
  ServiceConfig: {
    cancel: '取消',
    select: '请选择',
    submit: '提交',
    succeed: '成功',
    remark: '备注',
    Package: '套餐设置',
    validity: '有效期',
    tips1: '请输入有效期',
    operator: 'SIM运营商',
    tips: '请输入SIM运营商',
    tips2: '套餐设置成功'
  },
  showSuggest: {
    Ndata: '暂无数据',
    LineSpacing: '行间距',
    Stype: '传感器类型',
    type: '肥料类型',
    Name: '规划名称',
    manufacturer: '肥料厂家',
    pop: '种植项目',
    view: '查看项目',
    Number: '种苗数量',
    variety: '菌草品种',
    Bead: '珠间距',
    Sname: '传感器名称',
    proposal: '整地建议',
    services: '选择服务',
    View: '查看项目',
    cultivation: '栽培方式',
    recommended: '推荐肥料',
    Optimum: '最佳种植时间',
    Type: '机械类型',
    depth: '沟深(穴深)',
    month: '月份',
    specialist: '专家',
    person: '人/亩',
    machine: '机械名称',
    width: '沟宽(穴直径)',
    name: '肥料名称',
    force: '劳力预估',
    sensor: '传感器',
    Smanufacturer: '传感器厂家',
    pm: '项目管理',
    return: '返回'
  },
  addOrUpdateProjectShow: {
    cancel: '取消',
    country: '国家',
    address: '详细地址',
    coordinate: '坐标',
    Asuccess: '新增成功',
    town: '市',
    submit: '提交',
    Eitem: '编辑展示项目',
    pname: '项目名称',
    tips: '请选择坐标',
    picture: '照片',
    content: '内容',
    Aitem: '添加展示项目',
    modify: '修改成功',
    province: '省',
    China: '中国',
    district: '区',
    ptitle: '项目标题',
    location: '地址',
    garden: '园区',
    elink: '外链'
  },
  Comesoon: { tips: '功能暂不可用' },
  land: {
    cancel: '取消',
    Asuccess: '新增成功',
    submit: '提交',
    mechanical: '机械',
    remark: '备注',
    Grass: '草种准备',
    content: '准备内容',
    tips: '请选择准备内容',
    operator: '操作人',
    dollar: '元',
    view: '查看数据',
    weed: '清除杂草',
    operating: '作业方式',
    write: '记录时间',
    Dig: '挖排涝渠',
    cost: '费用',
    Channel: '通道规划',
    tips1: '请选择作业方式',
    Base: '施用底肥',
    tips2: '请选择录入时间',
    Land: '整地记录',
    tips3: '请输入费用',
    Tilling: '翻耕(旋耕)',
    Type: '农事类型',
    artificial: '人工',
    photograph: '农事照片',
    guidance: '农事记录与指导',
    Soil: '土地准备',
    return: '返回',
    Planting: '种植项目'
  },
  menus: {
    cancel: '取消',
    Sindex: '排序索引',
    smanagement: '系统管理',
    skip: '跳至',
    type: '类型',
    delete: '删除',
    toble1: '条记录',
    Ejump: '按下回车键后跳转',
    tips: '您确定要删除',
    button: '按钮',
    Mmanagement: '菜单管理',
    compile: '编辑',
    skip1: '页',
    toble: '共',
    Emenu: '请输入菜单名称',
    Ami: '添加菜单',
    Fname: '字体图标名称',
    Nfound: '没有发现数据。',
    ' URL': 'url地址',
    forbidden: '禁用',
    Mname: '菜单名称',
    menu: '菜单',
    tips1: '"',
    catalogue: '目录',
    tips2: '?',
    tips3: '吗',
    confirm: '确定',
    invocation: '启用',
    Dtd: '删除本条数据',
    operation: '操作'
  },
  showMap2App: {},
  TabProjectApp: {
    Eguidance: '专家指导',
    Mp: '我的项目',
    Nrecord: '暂无记录',
    Pd: '项目详情',
    Loading: '加载中',
    Si: '搜索项目',
    Agrecords: '农事记录',
    Archived: '已归档'
  },
  createPlan: {
    Import: '导入到项目',
    pt: '项目类型',
    tips: '请输入项目名称',
    degrees: '小于25度坡地',
    objective: '项目目标',
    loss: '水土流失',
    desertification: '荒漠化',
    experiment: '实验效果',
    enclosure: '项目圈地',
    draft: '保存为草稿',
    ' PH': 'pH值',
    state: '国家',
    presence: '项目名存在',
    mushroom: '种菇',
    Wetland: '湿地(草甸/沼泽地)',
    item: '项目编号',
    natural: '自然降雨',
    Root: '根系越冬',
    biofuel: '生物燃料',
    Local: '当地天气',
    loading: '加载中',
    slope: '护坡固堤',
    monitoring: '环境监测',
    Longitude: '绘制项目地块轮廓后自动计算经度',
    Land: '整地条件',
    fodder: '饲料',
    planning: '种植规划',
    field: '生产田间管理',
    Next: '下一步：其他信息',
    Automatic: '绘制项目地块轮廓后自动计算',
    breeding: '育种',
    other: '其他信息',
    Draw: '绘制项目地块轮廓',
    soil: '土壤属性',
    Research: '科研',
    cycle: '项目周期',
    dollar: '元',
    remediation: '土壤修复',
    flat: '平地',
    Mine: '矿山修复',
    temperature: '年平均气温',
    terrace: '梯田',
    tornado: '年度龙卷风天数',
    Use: '种植菌草用途',
    tips4: '请输入详细地址',
    black: '黑土',
    Dimensions: '绘制项目地块轮廓后自动计算维度',
    tips5: '请输入整地条件',
    tips6: '请输入灌溉条件',
    tips7: '请输入金额',
    tips1: '请进行项目圈地',
    tips2: '提示：省/自治区、市、区/县的命名应使用简称，如内蒙古自治区简称内蒙古，阿拉善盟简称阿拉善',
    tips3: '请输入完整的地点',
    maximum: '年平均最高气温',
    minimum: '年平均最低气温',
    governance: '用于生态治理',
    bed: '猪饲料-发酵床',
    Ndata: '暂无数据',
    saline: '盐碱地',
    Click: '点击获取第三方天气数据',
    Immersion: '浸灌',
    red: '红壤',
    irrigation: '灌溉条件',
    tips8: '请输入正确或完整的金额',
    tips9: '请输入土壤属性',
    fibrous: '纤维材料',
    day: '天',
    area: '项目面积',
    rain: '年度下雨天数',
    coordinate: '项目坐标',
    town: '市',
    Creation: '创建规划',
    created: '创建成功',
    Province: '省/洲/自治区',
    tips15: '请输入项目周期',
    tips16: '请先输入省份城市信息',
    tips13: '请输入项目类型',
    tips14: '请输入项目目标',
    snow: '年度下雪天数',
    tips11: '下一步：种植菌草用途',
    tips12: '请输入项目编号',
    name: '项目名称',
    tips10: '请输入pH值',
    Save: '保存为草稿',
    fixed: '防风固沙',
    thunderstorm: '年度雷阵雨天数',
    amending: '修改规划',
    District: '区/县',
    Formally: '正式提交项目',
    no: '否',
    import: '导入成功',
    production: '生产',
    remark: '备注',
    Processing: '菌草成熟收获后的加工目标',
    fungus: '菌草成熟后再利用',
    plot: '规划地块信息',
    China: '中国',
    sheet: '新型板材',
    Electrical: '电导率(EC)',
    trickle: '滴灌',
    foggy: '年度起雾天数',
    rainfall: '年度降雨量',
    yes: '是',
    saltness: '含盐量',
    sprinkling: '喷灌',
    Material: '菌料',
    Sandy: '沙壤地',
    hail: '年度冰雹天数',
    stony: '石漠化',
    detailed: '详细地址',
    acre: '亩',
    location: '项目地点',
    objectives: '生态治理目标'
  },
  showMapApp: { Area: '区域面积', acre: '亩' },
  harvest: {
    cancel: '取消',
    Asuccess: '新增成功',
    submit: '提交',
    ' acre': '亩',
    mechanical: '机械',
    remark: '备注',
    Harvest: '收割管理',
    tips: '请选择作业方式',
    operator: '操作人',
    dollar: '元',
    ton: '吨',
    view: '查看数据',
    farming: '农事类型',
    reap: '收割',
    yield: '产量',
    operating: '作业方式',
    stand: '台',
    write: '记录时间',
    area: '面积',
    cost: '费用',
    proposes: '专家建议',
    tips4: '请输入费用',
    tips1: '请选择记录时间',
    tips2: '请输入面积',
    tips3: '请输入产量',
    Project: '种植项目',
    photograph: '农事照片',
    artificial: '人工',
    guidance: '农事记录与指导',
    return: '返回'
  },
  showProject1: {
    no: '否',
    average: '年平均气温',
    saline: '盐碱地',
    Parea: '项目面积',
    Ppi: '项目规划信息',
    soil: '土壤属性',
    irrigation: '灌溉条件',
    fungus: '菌草成熟后再利用',
    enclosure: '项目圈地',
    Create: '创建用户姓名',
    contact: '创建用户联系方式',
    Electrical: '电导率（EC）',
    day: '天',
    rain: '年度下雨天数',
    foggy: '年度起雾天数',
    ecological: '用于生态治理',
    rainfall: '年度降雨量',
    address: '详细地址',
    tornado: '年度龙卷风天数',
    coordinate: '项目坐标',
    yes: '是',
    saltness: '含盐量',
    hail: '年度冰雹天数',
    Project: '项目规划信息',
    planning: '种植规划',
    condition: '整地条件',
    Ecological: '生态治理目标',
    snow: '年度下雪天数',
    name: '项目名称',
    PH: 'PH值',
    maximum: '年平均最高气温',
    processing: '菌草成熟收货后的加工目标',
    location: '项目地点',
    thunderstorm: '年度雷阵雨天数',
    minimum: '年平均最低气温',
    return: '返回'
  },
  showProject2: {
    no: '否',
    sproperty: '土壤属性',
    ptype: '项目类型',
    ylut: '亩产',
    remark: '备注',
    Fgovernance: '用于生态治理',
    carea: '种植面积',
    pobjective: '项目目标',
    ton: '吨/亩',
    Rmature: '菌草成熟后再利用',
    Pcoordinate: '项目坐标',
    Electrical: '电导率(EC)',
    Eobjectives: '生态治理目标',
    Pp: '种植项目',
    plocation: '项目地点',
    Icost: '灌溉费用',
    pname: '项目名称',
    yes: '是',
    saltness: '含盐量',
    Land: '整地条件',
    iconditions: '灌溉条件',
    Pharvest: '菌草成熟收获后的加工目标',
    inumber: '项目编号',
    Vproject: '查看项目',
    effect: '实验效果',
    Penclosure: '项目圈地',
    ssoil: '盐碱地',
    pcycle: '项目周期',
    PM: '项目管理',
    return: '返回'
  },
  Overview: {
    bed: '猪饲料-发酵床',
    production: '生产',
    scientific: '科研',
    choose: '请选择',
    quantum: '时间段',
    type: '项目类型',
    Modcrn: '种菇',
    tips: '没有找到项目信息',
    Dreleased: '设备已释放',
    view: '查看数据',
    Pfm: '生产田间管理',
    underway: '进行中',
    Nwd: '无天气数据',
    enter: '请输入',
    place: '地点',
    all: '全部',
    inquire: '查询',
    Fon: '是否归档',
    Root: '根系越冬',
    devices: '个设备',
    monitoring: '环境监测',
    pack: '收起',
    Poverview: '项目概览',
    serial: '编号',
    Closed: '已归档',
    name: '项目名称',
    reset: '重置',
    Planting: '种植项目',
    unfold: '展开',
    wind: '防风固沙',
    breeding: '育种'
  },
  Tab3PageApp: { Ar: '农事记录', Loading: '加载中...' },
  ESVideo: {},
  Detail2: {
    cancel: '取消',
    coordinate: '坐标',
    article: '所属项目',
    duration: 'SIM套餐期限',
    password: '密码',
    Equipment: '设备序列号',
    serial: '编号',
    SIM: 'SIM运营商',
    name: '名称',
    model: '型号',
    details: '设备详情',
    device: '设备类型',
    Notes: 'SIM套餐备注',
    account: '账号',
    Location: '部署地点'
  },
  Detail1: {
    Eguidance: '专家指导',
    Hmanagement: '收割管理',
    Ground: '整地准备',
    Growth: '生长记录',
    Lchart: '正在加载图表',
    hours2: '72小时',
    hours1: '48小时',
    Hequipment: '历史设备',
    video: '播放视频',
    Nseedlings: '种苗数量',
    measurement: '测产记录',
    number: '编号',
    download: '下载',
    Ptype: '项目类型',
    yield: '亩产',
    Production: '产量预估',
    parameter: '参数',
    weather: '未来天气',
    newest: '最新',
    value: '均值',
    day: '日',
    hours: '24小时',
    coordinate: '坐标',
    parea: '种植面积',
    more: '查看更多',
    Ldevice: '正在加载设备信息',
    Playback: '采样回放',
    Pproject: '种植项目',
    Vdata: '查看数据',
    View: '查看详情',
    target: '目标',
    Eadvice: '专家建议',
    Pdisplay: '项目数据展示',
    month: '月',
    species: '菌草品种',
    Ndevice: '没有找到设备信息',
    tons: '吨/亩',
    Cimage: '正在抓取图像',
    plant: '株',
    effect: '实验效果',
    ' underway': '进行中',
    to: '至',
    time: '时间',
    pcycle: '项目周期',
    return: '返回',
    Planting: '种植记录',
    PO: '项目概览'
  },
  fertilizerManage: {
    cancel: '取消',
    ingredient: '成分',
    Aywtd: '您确定要删除',
    FM: '肥料管理',
    tips4: '共',
    DM: '生产资料管理',
    skip: '跳至',
    type: '类型',
    tips1: '没有发现数据',
    delete: '删除',
    fertilizer: '新增肥料',
    tips: '请输入名称检索',
    tips3: '条记录',
    confirm: '确定',
    compile: '编辑',
    ma: '吗？',
    Dtd: '删除本条数据',
    name: '名称',
    Manufacturer: '厂家/供应商/渠道',
    page: '页',
    press: '按下回车键后跳转',
    operation: '操作'
  },
  choicelanguage: { confirm: '确认选择', systemlanguage: '选择系统语言' },
  harvestRecordApp: {
    Tfarming: '农事类型',
    Fphotos: '农事照片',
    remark: '备注',
    tips: '请选择作业方式',
    operator: '操作人',
    dollar: '元',
    ton: '吨',
    tips8: '请填写备注',
    tips9: '请选择时间',
    yield: '产量',
    define: '确定',
    write: '记录时间',
    area: '面积',
    harvest: '收割',
    cost: '费用',
    tips4: '请填写产量',
    tips5: '请输入产量',
    tips6: '请填写费用',
    tips7: '请输入费用',
    tips1: '请选择记录时间',
    tips2: '请填写面积',
    tips3: '请输入面积',
    artificial: '人工',
    acre: '亩',
    Omode: '作业方式',
    machinery: '机械'
  },
  addOrUpdateMenus: {
    enumber: '请输入数字',
    cancel: '取消',
    prohibit: '禁止',
    Sindex: '排序索引',
    asuccess: '添加成功',
    save: '保存',
    type: '类型',
    Iname: '图标名称',
    menu: '菜单',
    Pdirectory: '父级目录',
    URL: 'URL地址',
    tips: '请输入菜单名称',
    catalogue: '目录',
    Ename: '请输入名称',
    button: '按钮',
    coding: '编码',
    modify: '修改成功',
    enable: '启用',
    name: '名称',
    eindex: '请输入排序索引',
    Enable: '是否启用',
    afail: '添加失败',
    Ami: '添加菜单'
  },
  loginMapApp: {
    cipher: '密码',
    cancel: '取消',
    tips4: '您的账号已经在另一台设备登录，请您重新登录',
    tips5: '越权访问：没有访问该接口的权限！',
    tips6: '是否退出菌草云平台？',
    an: '账号',
    tips1: '密码已保存无法查看',
    tips: '请输入账号',
    tips2: '请输入密码',
    tips3: '选择系统语言',
    exit: '退出',
    Si: '登录',
    Rp: '记住密码'
  },
  irrigateRecordApp: {
    Operator: '操作人',
    wconsumption: '用水量',
    Aphotos: '农事照片',
    remark: '备注',
    tips: '请选择作业方式',
    dollar: '元',
    ton: '吨',
    tips8: '请输入费用',
    tips9: '请填写备注',
    Lirrigation: '后期灌溉',
    define: '确定',
    area: '面积',
    cost: '费用',
    Rtime: '记录时间',
    tips4: '请输入面积',
    tips5: '请填写用水量',
    tips6: '请输入用水量',
    tips7: '请填写费用',
    tips1: '请选择灌溉方式',
    tips2: '请选择记录时间',
    tips3: '请填写面积',
    artificial: '人工',
    Firrigation: '首次灌溉',
    acre: '亩',
    tips10: '请选择时间',
    Omode: '作业方式',
    imethod: '灌溉方式',
    machinery: '机械'
  },
  Main: {
    break: '断开',
    tips1: '连接服务器成功！',
    platform: '智慧菌草云平台',
    tips: '重新连接服务器成功！',
    tips2: '与服务器断开连接！',
    Chistory: '改变聊天记录'
  },
  StatusConfig: {
    cancel: '取消',
    confirm: '确定',
    affirm: '确认',
    Not: '非工作',
    succeed: '成功',
    Service: '业务状态设置',
    working: '工作中',
    tips1: '请选择工作状态',
    tips: '请选择要设置的状态',
    tips2: '确定要变更状态吗?',
    tips3: '状态设置成功'
  }
}

